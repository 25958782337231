import React, { useEffect, useState } from "react";
import {
  Text,
  Box,
  Stat,
  StatLabel,
  Progress,
  Badge,
  StatGroup,
  StatNumber,
  StatHelpText,
} from "@chakra-ui/react";
import { EventSourcePolyfill } from "event-source-polyfill";
import { FaMemory } from "react-icons/fa6";
import { WiThermometer } from "react-icons/wi";

const urlz = process.env.REACT_APP_API_URL;

const GPUStats = ({ clusterId }) => {
  const [gpuStats, setGPUStats] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const gpuStatsUrl = `${urlz}/clusters/v1/${clusterId}/stats/gpu`;

    const eventSource = new EventSourcePolyfill(gpuStatsUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    eventSource.onmessage = (e) => {
      try {
        const receivedStats = JSON.parse(e.data);
        setGPUStats(receivedStats);
        setError(null);
      } catch (parseError) {
        setError("Error parsing GPU stats");
      }
    };

    eventSource.onerror = (err) => {
      const errorMessage = "Connection to the server failed.";
      setGPUStats(null);
      setError(errorMessage);
    };

    return () => {
      eventSource.close();
    };
  }, [clusterId]);

  return (
    <Box
      p={5}
      _hover={{
        boxShadow: "lg",
      }}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      {error ? (
        <Text fontWeight={600} color="red">
          {error}
        </Text>
      ) : gpuStats ? (
        <StatGroup>
          <Stat>
            <StatLabel>Name</StatLabel>
            <Text fontWeight="bold">{gpuStats[0].name}</Text>
            <Badge colorScheme="green">Online</Badge>
          </Stat>
          <Stat mr={4}>
            <StatLabel>Load</StatLabel>
            <Progress
              colorScheme="orange"
              size="sm"
              value={gpuStats[0].load * 100}
            />
            <StatNumber>{`${(gpuStats[0].load * 100).toFixed(2)}%`}</StatNumber>
          </Stat>
          <Stat mr={3}>
            <StatLabel>Memory Utilization</StatLabel>
            <Progress
              colorScheme="green"
              size="sm"
              value={gpuStats[0].memoryUtil * 100}
            />
            <StatHelpText>
              <FaMemory mr="2" />
              {`${(gpuStats[0].memoryUsed / 1024).toFixed(2)} GB / ${(
                gpuStats[0].memoryTotal / 1024
              ).toFixed(2)} GB`}
            </StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Temperature</StatLabel>
            <StatNumber>
              <WiThermometer mr="2" />
              {`${gpuStats[0].temperature}°C`}
            </StatNumber>
          </Stat>
        </StatGroup>
      ) : (
        <Text>No Stats Available. Please Resume Cluster to see.</Text>
      )}
    </Box>
  );
};

export default GPUStats;
