import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";

const UsageClusterDetails = ({ clusterDetails }) => {
  return (
    <Box>
      <SimpleGrid
        columns={{
          base: 1,
          lg: 2,
        }}
        spacing={10}
      >
        <Stack gap={5}>
          <Flex justifyContent="space-between">
            <Heading size="sm">Cluster Name</Heading>
            <Heading
              size="sm"
              color={useColorModeValue("gray.600", "gray.200")}
            >
              {clusterDetails?.name}
            </Heading>
          </Flex>
        </Stack>
        <Stack gap={5}>
          <Flex justifyContent="space-between">
            <Heading size="sm">Model Name</Heading>
            <Heading
              size="sm"
              color={useColorModeValue("gray.600", "gray.200")}
            >
              {clusterDetails?.model?.name}
            </Heading>
          </Flex>
        </Stack>
      </SimpleGrid>
    </Box>
  );
};

export default UsageClusterDetails;
