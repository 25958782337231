import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  SkeletonCircle,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Send } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";

const Playground = ({ endpoint, model, initialData }) => { 
  const isRunPod = initialData?.tier?.cloud === "RunPod";
  const port = initialData?.metadata?.port;

  const API_URL = `https://${endpoint}${
    initialData?.tier?.cloud === "RunPod" ? `:${port}` : ""
  }/v1${model?.completions === "chat" ? "" : "/chat"}/completions`;
  const [loading, setLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [error, setError] = useState(null);
  const [data, setData] = useState([
    {
      inputMessage: "",
      outputMessage:
        "Hello, I am your deployed model. Please enter the API Key above to begin our conversation!",
    },
  ]);
  const responseBgColor = useColorModeValue("gray.200", "gray.700");
  const inputBgColor = useColorModeValue("gray.100", "gray.800");
  const [token, setToken] = useState("");
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    setData([
      ...data,
      {
        inputMessage,
        outputMessage: [],
      },
    ]);
    const requestBody =
      model?.completions === "chat"
        ?{
            model: model?.name,
            stream: true,
            temperature: 0,
            max_tokens: 500, 
            prompt: inputMessage,
          }
        : {
            model: model?.name,
            stream: true,
            max_tokens: 500, 
            messages: [
              {
                role: "user",
                content: inputMessage,
              },
            ],
          };
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    console.log(response);
    if (response.status === 200) {
      const reader = response?.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      while (true) {
        const { value, done } = await reader.read();
        if (value) {
          const regexPattern =
            model.completions === "chat"
              ? /"text":\s*"(.*?)"/g
              : /"content":\s*"(.*?)"/g;
          const matches = Array?.from(
            value?.matchAll(regexPattern),
            (match) => match[1]
          );

          matches.forEach((element, index) => {
            const delay = (index + 1 / 1000) * 10;
            setTimeout(() => {
              setData((prevArray) => [
                ...prevArray.slice(0, prevArray.length - 1),
                {
                  ...prevArray[prevArray.length - 1],
                  outputMessage:
                    prevArray[prevArray.length - 1].outputMessage +
                    (element === "\\n" ? "<br/>" : element),
                },
              ]);
            }, delay);
          });
        }
        if (done) break;
      }
    } else if (response.status === 403) {
      setError("Invalid Token");
    } else {
      console.error(error);
      setError("Something went wrong");
    }

    setLoading(false);
    setInputMessage("");
  };

  return (
    <Box>
      <Flex mb={4} alignItems={"baseline"} gap={5}>
        <Box>Token</Box>
        <Input
          placeholder="Enter Token"
          mb={4}
          onChange={(e) => setToken(e.target.value)}
          isDisabled={loading}
        />
      </Flex>
      <Box>
        {data?.map((item, index) => (
          <Box key={index} mb={4}>
            {item.inputMessage && (
              <Flex gap={2} alignItems={"center"} mb={2}>
                <Avatar
                  size={"sm"}
                  src={
                    "https://cdn-icons-png.flaticon.com/512/6134/6134346.png"
                  }
                />
                <Text
                  bg={inputBgColor}
                  w={"auto"}
                  p={3}
                  borderRadius={"md"}
                  maxW={"50%"}
                >
                  {item.inputMessage}
                </Text>
              </Flex>
            )}

            <Flex gap={2} flexDirection={"row-reverse"}>
              <Avatar
                size={"sm"}
                src={"https://cdn-icons-png.flaticon.com/512/4829/4829581.png"}
              />
              {/* TODO: dompurify */}
              <Flex
                bg={responseBgColor}
                justifyContent={"flex-end"}
                w={"auto"}
                p={3}
                borderRadius={"md"}
                maxW={"50%"}
                alignItems={"right"}
                dangerouslySetInnerHTML={{
                  __html:
                    index === data.length - 1 && loading
                      ? item.outputMessage + "..."
                      : item.outputMessage,
                }}
              />
            </Flex>
          </Box>
        ))}
      </Box>

      <Flex gap={5} alignItems={"center"}>
        <Input
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type your message..."
          mb={4}
        />
        <Button
          onClick={fetchData}
          variant={"outline"}
          mb={4}
          isDisabled={
            loading || token.trim() === "" || inputMessage.trim() === ""
          }
          isLoading={loading}
          loadingText="Wait..."
        >
          <Send />
        </Button>
      </Flex>
      {error && <Text color={"red.500"}>{error}</Text>}
      <Box ref={messagesEndRef} />
    </Box>
  );
};

export default Playground;
