import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllPaymentMethodsAsync } from '../../features/payment/paymentAction';
import { Box, Text, Badge, SimpleGrid, Image , Card , Flex} from '@chakra-ui/react';

import VisaLogo from '../../assets/images/visa.png';
import MastercardLogo from '../../assets/images/mcard.png';

const PaymentMethodsComponent = () => {
    const dispatch = useDispatch();
    const { paymentMethods, loading, error } = useSelector(state => state.payment);

    useEffect(() => {
        dispatch(getAllPaymentMethodsAsync());
    }, [dispatch]);

    const getCardLogo = (brand) => {
        switch (brand) {
            case 'visa':
                return VisaLogo;
            case 'mastercard':
                return MastercardLogo;
            default:
                return ''; 
        }
    };

    if (loading) {
        return <Box>Loading payment methods...</Box>;
    }

    if (error) {
        return <Box>Error fetching payment methods: {error}</Box>;
    }

    if (!paymentMethods || paymentMethods.length === 0) {
        return <Box mt={2}>No payment methods found.</Box>;
    }

    return (
        <Box mt={5}>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>Your Payment Methods</Text>
            <SimpleGrid columns={{ sm: 1, md: 4 }} spacing={5}>
                {paymentMethods.map((method) => (
                    <Box p={5} borderWidth="1px" borderRadius="lg" overflow="hidden" key={method._id}>
                        <Flex alignItems="center" mb={2}>
                            <Image src={getCardLogo(method.metadata.brand)} alt={method.metadata.brand} boxSize="25px" mr={3} />
                            <Text fontWeight="bold">**** **** **** {method.metadata.last4}</Text>
                        </Flex>
                        <Text fontSize="sm">Expires {method.metadata.exp_month}/{method.metadata.exp_year}</Text>
                        <Badge colorScheme={method.isDefault ? "green" : "gray"} mt={2}>
                            {method.isDefault ? "Default" : "Additional"}
                        </Badge>
                    </Box>
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default PaymentMethodsComponent;
