import { useLocation } from "react-router-dom";
import Email from "../../components/ForgotPassword/Email";
import PasswordReset from "../../components/ForgotPassword/PasswordReset";

export default function ForgotPassword() {
  const location = useLocation();
  const tokenParam = new URLSearchParams(location.search).get("token");
  console.log(tokenParam);

  return (
    <div>
      {tokenParam ? (
        <PasswordReset tokenParam={tokenParam} />
      ) : (
        <Email />
      )}
    </div>
  );
}
