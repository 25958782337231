import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Checkbox,
  Text,
  Stack,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAsync,
  googleLoginAsync,
  loginAsync,
} from "../../features/auth/authActions";
import { Spinner } from "@chakra-ui/react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { Box } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import { logout, resetAuth } from "../../features/auth/authSlice";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const toast = useToast();
  const dispatch = useDispatch();
  const { error, success, message, loading, access_token, user } = useSelector(
    (state) => state.auth
  );

  const handleLogin = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    dispatch(loginAsync(values));

    resetForm();
    setSubmitting(false);
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (res) => {
      dispatch(googleLoginAsync(res.code));
    },
    flow: "auth-code",
  });

  useEffect(() => {
    if (success && user) {
      toast({
        title: "Login Successful",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
    if (error && message.statusCode !== 409) {
      toast({
        title: `Login Failed ${message.statusCode}`,
        description:
          message.statusCode === 403
            ? "User not verified. Please check your email for verification"
            : message.message,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      dispatch(logout());
    }
  }, [success, error, message, toast]);

  return (
    <Box
      w={{
        base: "90%",
        md: "75%",
        lg: "55%",
      }}
    >
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
      >
        <Form>
          <Stack spacing={4}>
            <Heading fontSize={"2xl"} mb={10}>
              Sign in to your account
            </Heading>
            <Field name="email">
              {({ field }) => (
                <FormControl
                  id="email"
                  isInvalid={field.touched && field.error}
                >
                  <FormLabel>Email address</FormLabel>
                  <Input {...field} type="email" />
                  <ErrorMessage name="email" component={Text} color="red.500" />
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({ field }) => (
                <FormControl
                  id="password"
                  isInvalid={field.touched && field.error}
                >
                  <FormLabel>Password</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      pr="4.5rem"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={togglePasswordVisibility}
                        aria-label={
                          showPassword ? "Hide password" : "Show password"
                        }
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <ErrorMessage
                    name="password"
                    component={Text}
                    color="red.500"
                  />
                </FormControl>
              )}
            </Field>
            <Stack spacing={6} mt={6}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <ChakraLink as={ReactRouterLink} to="/auth/forgot-password">
                  <Text color={"brand.secondary"}>Forgot password?</Text>
                </ChakraLink>
              </Stack>
              <Button
                type="submit"
                isLoading={loading}
                spinner={<Spinner color="white" />}
                colorScheme="blue"
                variant="outline"
              >
                Sign in
              </Button>
              <Button
                colorScheme="red"
                variant="outline"
                onClick={handleGoogleLogin}
                isLoading={loading}
                spinner={<Spinner color="white" />}
              >
                Sign in with Google
              </Button>
            </Stack>
          </Stack>
        </Form>
      </Formik>
      <Box mt={4}>
        <ChakraLink as={ReactRouterLink} to="/register">
          Don't have an account?{" "}
          <Text as="span" color={"brand.secondary"} fontWeight={"semibold"}>
            Register here
          </Text>
        </ChakraLink>
      </Box>
    </Box>
  );
};

export default LoginForm;
