import { createSlice } from "@reduxjs/toolkit";
import { uploadDatasetAsync, getAllDatasetsAsync, getDatasetAsync, deleteDatasetAsync } from "./datasetAction";

const datasetSlice = createSlice({
  name: "datasets",
  initialState: {
    datasets: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetDatasets: (state) => {
      state.datasets = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadDatasetAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadDatasetAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.datasets.push(action.payload);
      })
      .addCase(uploadDatasetAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllDatasetsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDatasetsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.datasets = action.payload;
      })
      .addCase(getAllDatasetsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getDatasetAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDatasetAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getDatasetAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteDatasetAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDatasetAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.datasets = state.datasets.filter(dataset => dataset._id !== action.payload._id);
      })
      .addCase(deleteDatasetAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetDatasets } = datasetSlice.actions;
export default datasetSlice.reducer;
