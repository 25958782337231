import React, { useState, useEffect } from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td, Box, Button, Input, useToast,
    FormControl, FormLabel, HStack, VStack, Text , useColorModeValue
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { createUserApiKeyAsync, getUserApiKeysAsync, revokeUserApiKeyAsync } from '../../features/userApi/userApiActions';
import ApiKeyModal from './Modal';

const ApiKeyManager = () => {
    const dispatch = useDispatch();
    const toast = useToast();
    const [newKeyName, setNewKeyName] = useState('');
    const { userApiKeys, loading, newApiKey } = useSelector((state) => state.userApi);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (newApiKey) {
            setModalOpen(true);
        }
    }, [newApiKey]);

    useEffect(() => {
        dispatch(getUserApiKeysAsync());
    }, [dispatch]);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleRevokeKey = (apiKeyUuid) => {
        dispatch(revokeUserApiKeyAsync(apiKeyUuid))
            .then(() => {
                toast({
                    title: 'API Key revoked successfully.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    title: 'Error revoking API Key.',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    const handleCreateKey = () => {
        dispatch(createUserApiKeyAsync(newKeyName))
            .then(() => {
                toast({
                    title: 'API Key created successfully.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                setNewKeyName('');
                dispatch(getUserApiKeysAsync());
            })
            .catch((error) => {
                toast({
                    title: 'Error creating API Key.',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    return (
        <Box mt={5} borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg={useColorModeValue("brand.textPrimaryDark", "gray.800")}>
        <VStack spacing={4}>
            <FormControl id="apiKeyName">
                <FormLabel>Create New API Key</FormLabel>
                <HStack>
                    <Input
                        variant='filled' 
                        placeholder="Enter key name" 
                        value={newKeyName} 
                        onChange={(e) => setNewKeyName(e.target.value)} 
                    />
                    <Button onClick={handleCreateKey} isLoading={loading} loadingText="Creating">
                        Create
                    </Button>
                </HStack>
            </FormControl>
            <Box width="full">
                <Table size={'sm'} variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Key</Th>
                            <Th>Status</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {userApiKeys.map((key) => (
                            <Tr key={key._id}>
                                <Td>{key.name}</Td>
                                <Td>{key.masked}</Td>
                                <Td>{key.status}</Td>
                                <Td>
                                    <Button
                                        colorScheme="red"
                                        size="xs"
                                        onClick={() => handleRevokeKey(key.uuid)}
                                        isLoading={loading && newKeyName === key.name}
                                        loadingText="Revoking"
                                    >
                                        Revoke
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
            <ApiKeyModal 
                isOpen={isModalOpen} 
                onClose={handleModalClose} 
                apiKey={newApiKey} 
            />
        </VStack>
    </Box>
);
};

export default ApiKeyManager;
