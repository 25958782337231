import { createSlice } from "@reduxjs/toolkit";
import {
  initiatePaymentRequestAsync,
  getPaymentStatusAsync,
  validatePaymentRequestAsync,
} from "./paymentv2Action";

const initialState = {
  transactionDetails: null,
  paymentStatus: null,
  validationStatus: null,
  loading: false,
  error: null,
};

const paymentV2Slice = createSlice({
  name: "paymentV2",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(initiatePaymentRequestAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(initiatePaymentRequestAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.transactionDetails = action.payload.response;
      })
      .addCase(initiatePaymentRequestAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getPaymentStatusAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentStatusAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentStatus = action.payload;
      })
      .addCase(getPaymentStatusAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(validatePaymentRequestAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(validatePaymentRequestAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.validationStatus = action.payload;
      })
      .addCase(validatePaymentRequestAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = paymentV2Slice.actions;
export default paymentV2Slice.reducer;
