import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllClusters,
  terminateClusterApi,
  pauseClusterApi,
  resumeClusterApi,
  fetchClusterLogsApi,
  archiveClusterApi,
  getSpecificClusterApi,
  restartServicesApi,
} from "../../api/clustersApi";

export const getAllClustersAsync = createAsyncThunk(
  "clusters/getClusters",
  async (data, thunkAPI) => {
    const response = await getAllClusters(thunkAPI);
    return response;
  }
);

export const terminateClusterAsync = createAsyncThunk(
  "clusters/terminateCluster",
  async (uuid, thunkAPI) => {
    const response = await terminateClusterApi(uuid, thunkAPI);
    return response;
  }
);

export const pauseClusterAsync = createAsyncThunk(
  "clusters/pauseCluster",
  async (uuid, thunkAPI) => {
    try {
      const response = await pauseClusterApi(uuid, thunkAPI);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const resumeClusterAsync = createAsyncThunk(
  "clusters/resumeCluster",
  async (uuid, thunkAPI) => {
    const response = await resumeClusterApi(uuid, thunkAPI);
    return response;
  }
);

export const restartServicesAsync = createAsyncThunk(
  "clusters/restartServices",
  async (uuid, thunkAPI) => {
    const response = await restartServicesApi(uuid, thunkAPI);
    return response;
  }
);

export const fetchClusterLogsAsync = createAsyncThunk(
  "clusters/fetchLogs",
  async ({ clusterId, containerId, fromTimestamp }, thunkAPI) => {
    const response = await fetchClusterLogsApi(
      clusterId,
      containerId,
      fromTimestamp,
      thunkAPI
    );
    return response;
  }
);

export const archiveClusterAsync = createAsyncThunk(
  "clusters/archiveCluster",
  async (uuid, thunkAPI) => {
    const response = await archiveClusterApi(uuid, thunkAPI);
    return response;
  }
);

export const getSpecificClusterAsync = createAsyncThunk(
  "clusters/getSpecificCluster",
  async (uuid, thunkAPI) => {
    const response = await getSpecificClusterApi(uuid, thunkAPI);
    return response;
  }
);
