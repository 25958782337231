import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import modelReducer from "./features/models/modelSlice";
import tierReducer from "./features/tiers/tierSlice";
import clusterReducer from "./features/clusters/clusterSlice";
import apiReducer from "./features/api/apiSlice";
import singleClusterReducer from "./features/clusters/SingleClusterSlice";
import userApiReducer from "./features/userApi/userApiSlice";
import serviceAccountReducer from "./features/services/serviceAccountSlice";
import playgroundReducer from "./features/playground/playgroundSlice";
import paymentReducer from "./features/payment/paymentSlice";
import paymentv2Slice from "./features/paymentv2/paymentv2Slice";
import datasetSlice from "./features/dataset/datasetSlice";
import finetuneSlice from "./features/finetune/finetuneSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    model: modelReducer,
    tier: tierReducer,
    cluster: clusterReducer,
    api: apiReducer,
    userApi: userApiReducer,
    serviceAccount: serviceAccountReducer,
    singleCluster: singleClusterReducer,
    playground: playgroundReducer,
    payment: paymentReducer,
    paymentv2: paymentv2Slice,
    datasets: datasetSlice,
    fineTuning: finetuneSlice,
  },
});

export default store;
