import { Container, Heading, Flex, HStack} from "@chakra-ui/react";
import React from "react";
import DeployModel from "../../components/model/DeplyoModel";
import Clusters from "../../components/Clusters/Clusters";

function Models() {
  return (
    <Container maxW="7xl">
      <Heading as="h1" size="xl" py={2}>
        Deployments
      </Heading>
      <Heading fontWeight={500} as="h2" size="md" mb={4} py={0}>
        GenAI Models deployed by you.
      </Heading>
      <Flex direction="column" align="start">
        <HStack>
        {/* <DeployModel /> */}
        <Clusters />
        </HStack>
      </Flex>
    </Container>
  );
}

export default Models;
