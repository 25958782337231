import React from "react";
import Card from "../card/Card";
import { Flex } from "@chakra-ui/react";
import { PiLightning } from "react-icons/pi";
import { BsPlug } from "react-icons/bs";
import { TbCube } from "react-icons/tb";

function CardContainer({ cardList }) {
  return (
    <Flex mt={6} gap={6} direction={{ base: "column", lg: "row" }} wrap="wrap">
      {cardList.map((card) => (
        <Card
          key={card.title}
          title={card.title}
          description={card.description}
          buttonText1={card.buttonText1}
          icon={card.icon}
          href1={card.href1}
          href2={card.href2}
          buttonText2={card.buttonText2}
          openHref1InNewTab={card.openHref1InNewTab}
          openHref2InNewTab={card.openHref2InNewTab}
        />
      ))}
    </Flex>
  );
}

export default CardContainer;
