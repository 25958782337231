import { createSlice } from "@reduxjs/toolkit";
import {
  getUserAsync,
  googleLoginAsync,
  loginAsync,
  registerAsync,
  updateUserEmailAsync,
  updateUserFirstNameAsync,
  updateUserLastNameAsync,
  updateUserPasswordAsync,
} from "./authActions";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    access_token: localStorage.getItem("access_token") || null,
    refresh_token: localStorage.getItem("refresh_token") || null,
    loading: false,
    error: null,
    success: null,
    message: "",
    user: null,
    updateUser: {
      loading: false,
      error: null,
      success: null,
      message: "",
    },
  },
  reducers: {
    resetAuth: (state) => {
      state.access_token = null;
      state.refresh_token = null;
      state.loading = false;
      state.error = null;
      state.success = null;
      state.message = "";
      state.user = null;
    },
    resetUpdateUser: (state) => {
      state.updateUser.loading = false;
      state.updateUser.error = null;
      state.updateUser.success = null;
      state.updateUser.message = "";
    },
    logout: (state) => {
      state.access_token = null;
      state.refresh_token = null;
      state.loading = false;
      state.error = null;
      state.success = null;
      state.message = "";
      state.user = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.message = "";
        state.access_token = null;
        state.refresh_token = null;
        state.user = null;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.access_token = action.payload.accessToken;
        state.refresh_token = action.payload.refreshToken;
        localStorage.setItem("access_token", action.payload.accessToken);
        localStorage.setItem("refresh_token", action.payload.refreshToken);
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = null;
        state.message = action.payload.data;
        state.access_token = null;
        state.refresh_token = null;
        state.user = null;
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
      });
    builder
      .addCase(getUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.message = "";
        state.user = null;
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.user = action.payload;
      })
      .addCase(getUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = null;
        state.message = action.payload.data;
        state.user = null;
        state.access_token = null;
        state.refresh_token = null;
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
      });
    builder
      .addCase(googleLoginAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.message = "";
        state.access_token = null;
        state.refresh_token = null;
        state.user = null;
      })
      .addCase(googleLoginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.access_token = action.payload.accessToken;
        state.refresh_token = action.payload.refreshToken;
        localStorage.setItem("access_token", action.payload.accessToken);
        localStorage.setItem("refresh_token", action.payload.refreshToken);
      })
      .addCase(googleLoginAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = null;
        state.message = action.payload.data;
        state.access_token = null;
        state.refresh_token = null;
        state.user = null;
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
      });
    builder
      .addCase(registerAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.message = "";
        state.access_token = null;
        state.refresh_token = null;
        state.user = null;
      })
      .addCase(registerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(registerAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = null;
        state.message = action.payload.data;
        state.access_token = null;
        state.refresh_token = null;
        state.user = null;
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
      });
    builder
      .addCase(updateUserEmailAsync.pending, (state) => {
        state.updateUser.loading = true;
        state.updateUser.error = null;
        state.updateUser.success = null;
      })
      .addCase(updateUserEmailAsync.fulfilled, (state, action) => {
        state.updateUser.loading = false;
        state.updateUser.error = null;
        state.updateUser.success = true;
        state.user = action.payload.user;
        state.updateUser.message = action.payload.message;
      })
      .addCase(updateUserEmailAsync.rejected, (state, action) => {
        state.updateUser.loading = false;
        state.updateUser.error = true;
        state.updateUser.success = null;
        state.updateUser.message = action.payload.message;
      });
    builder
      .addCase(updateUserFirstNameAsync.pending, (state) => {
        state.updateUser.loading = true;
        state.updateUser.error = null;
        state.updateUser.success = null;
      })
      .addCase(updateUserFirstNameAsync.fulfilled, (state, action) => {
        state.updateUser.loading = false;
        state.updateUser.error = null;
        state.updateUser.success = true;
        state.user = action.payload.user;
        state.updateUser.message = action.payload.message;
      })
      .addCase(updateUserFirstNameAsync.rejected, (state, action) => {
        state.updateUser.loading = false;
        state.updateUser.error = true;
        state.updateUser.success = null;
        state.updateUser.message = action.payload.message;
      });
    builder
      .addCase(updateUserLastNameAsync.pending, (state) => {
        state.updateUser.loading = true;
        state.updateUser.error = null;
        state.updateUser.success = null;
      })
      .addCase(updateUserLastNameAsync.fulfilled, (state, action) => {
        state.updateUser.loading = false;
        state.updateUser.error = null;
        state.updateUser.success = true;
        state.user = action.payload.user;
        state.updateUser.message = action.payload.message;
      })
      .addCase(updateUserLastNameAsync.rejected, (state, action) => {
        state.updateUser.loading = false;
        state.updateUser.error = true;
        state.updateUser.success = null;
        state.updateUser.message = action.payload.message;
      });
    builder
      .addCase(updateUserPasswordAsync.pending, (state) => {
        state.updateUser.loading = true;
        state.updateUser.error = null;
        state.updateUser.success = null;
      })
      .addCase(updateUserPasswordAsync.fulfilled, (state, action) => {
        state.updateUser.loading = false;
        state.updateUser.error = null;
        state.updateUser.success = true;
        state.updateUser.message = action.payload.message;
      })
      .addCase(updateUserPasswordAsync.rejected, (state, action) => {
        state.updateUser.loading = false;
        state.updateUser.error = true;
        state.updateUser.success = null;
        state.updateUser.message = action.payload.data.message;
      });
  },
});

export const { resetAuth, logout, resetUpdateUser } = authSlice.actions;
export default authSlice.reducer;
