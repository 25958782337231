import React from "react";
import { Box, Text, IconButton, useClipboard, VStack, HStack } from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";

export default function EndCard({ data }) {

  const isRunPod = data?.tier?.cloud === "RunPod";
  const port = data?.metadata?.port; 
  const host = data?.metadata?.host; 
  const baseEndpointUrl = `https://${data?.endpoint}`;

 
  const urlToCopy = isRunPod && port ? `${baseEndpointUrl}:${port}/v1` : `${baseEndpointUrl}/v1`;
  
  const { hasCopied, onCopy } = useClipboard(urlToCopy);

  return (
    <Box
      display="flex"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      overflow="hidden"
      width="900px"
      mt="2"
      p="3"
      ml={-52}
    >
      <Box
        px="0"
        py={0}
        textAlign="center"
        fontWeight="extrabold"
        letterSpacing="1px"
        fontSize={12}
        textTransform="uppercase"
        borderRight="1px solid"
        borderColor="gray.300"
        color="blue.500"
      >
        API ENDPOINT
      </Box>
      <VStack
        justifyContent="center"
        alignItems="center"
        width="100%"
        textAlign="center"
      >
        <HStack>
          <Text fontWeight="medium" color="green.500">
            {urlToCopy}
            <IconButton
              aria-label="Copy URL"
              icon={<CopyIcon />}
              onClick={onCopy}
              ml="2"
              size="sm"
              variant="ghost"
            />
          </Text>
          {hasCopied && <Text fontSize="xs">Copied to clipboard!</Text>}
        </HStack>
      </VStack>
    </Box>
  );
}
