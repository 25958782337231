import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  initiatePaymentRequestApi,
  getPaymentStatusApi,
  validatePaymentRequestApi,
} from "../../api/paymentv2"; 

export const initiatePaymentRequestAsync = createAsyncThunk(
  "paymentV2/initiatePaymentRequest",
  async (paymentData, thunkAPI) => {
    return await initiatePaymentRequestApi(paymentData, thunkAPI);
  }
);

export const getPaymentStatusAsync = createAsyncThunk(
  "paymentV2/getPaymentStatus",
  async (transactionId, thunkAPI) => {
    return await getPaymentStatusApi(transactionId, thunkAPI);
  }
);

export const validatePaymentRequestAsync = createAsyncThunk(
  "paymentV2/validatePaymentRequest",
  async (verificationData, thunkAPI) => {
    return await validatePaymentRequestApi(verificationData, thunkAPI);
  }
);
