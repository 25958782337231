import { Box, Button } from "@chakra-ui/react";
import useRazorpay from "react-razorpay";

export default function RazorpayTest () {
    const [Razorpay] = useRazorpay();

    const handlePayment = async (params) => {
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
          name: "NextAI",
          description: "Test Transaction",
          image: "https://assets-global.website-files.com/650e8bc46cf62f0b69fd76a1/650e8cf0ad757160aa514598_Screenshot_2023-09-06_at_1.20.16_PM-removebg-preview-p-500.png",
          order_id: "order_NnSEO5piX7Mnmz",
          handler: function (response) {
            // make verify payment request
          },
          prefill: {
            name: "Piyush Garg",
            email: "youremail@example.com",
            contact: "9999999999",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
      
        const rzp1 = new Razorpay(options);
      
        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });
      
        rzp1.open();
    };
    
    return (
        <Box>
            <Button onClick={handlePayment}>Start checkout</Button>
        </Box>
    )
}