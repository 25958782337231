import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Input,
  useColorModeValue,
  useToast,
  Text
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Email() {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/forgot-password/initiate`,
        { email }
      );
      setMessage(response.data.message);
      setSuccess(true);
      toast({
        title: "Success",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      setMessage(error.response.data.message);
      setError(true);
      toast({
        title: "Error",
        description: error.response.data.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      alignItems="center"
      h="100vh"
      flexDirection={["column", "column", "column", "row"]}
      justifyContent={[
        "space-evenly",
        "space-evenly",
        "space-evenly",
        "space-between",
      ]}
    >
      <Flex
        flex={1}
        flexDirection="column"
        gap={2}
        alignItems="center"
        justifyContent="center"
        w={"100%"}
        bg={useColorModeValue("brand.background", "brand.backgroundDark")}
        h={"100%"}
      >
        <Heading as="h1" size="2xl">
          Forgot Password?
        </Heading>
        <Heading as="h2" size="xl">
          No worries, we got you!
        </Heading>
      </Flex>
      <Flex
      flex={1}
      flexDirection="column"
      gap={5}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        p={8}
        maxW="lg"
        borderWidth={1}
        borderRadius={8}
        boxShadow="0 4px 8px rgba(0, 0, 255, 0.1)" 
        bg={useColorModeValue('white', 'gray.800')}
      >
        <Heading mb={4} size="lg" textAlign="center">
          Reset your password
        </Heading>
        <Text mb={8} textAlign="center">
          Enter the email address associated with your account, and we'll send you a link to reset your password.
        </Text>
        <Input
          placeholder="Email"
          type="email"
          variant="outline"
          onChange={handleEmail}
          mb={4}
        />
        <Button
          isLoading={loading}
          loadingText="Sending Link"
          onClick={handleSubmit}
          bg={useColorModeValue('brand.primary', 'brand.primaryDark')}
          color="white"
          _hover={{
            bg: useColorModeValue('brand.secondary', 'brand.secondaryDark'),
          }}
        >
          Get Reset Link
        </Button>
      </Box>
    </Flex>
    </Flex>
  );
}
