import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllServiceAccounts,
  getServiceAccount,
  createServiceAccountCluster,
  validateServiceAccount,
  deleteServiceAccount
} from "../../api/serviceAccountApi";

export const fetchAllServiceAccountsAsync = createAsyncThunk(
  "serviceAccount/fetchAll",
  async (_, thunkAPI) => {
    return await getAllServiceAccounts(thunkAPI);
  }
);

export const fetchServiceAccountAsync = createAsyncThunk(
  "serviceAccount/fetchOne",
  async (serviceAccountId, thunkAPI) => {
    return await getServiceAccount(serviceAccountId, thunkAPI);
  }
);

export const createServiceAccountClusterAsync = createAsyncThunk(
  "serviceAccount/createCluster",
  async (serviceAccountData, thunkAPI) => {
    return await createServiceAccountCluster(serviceAccountData, thunkAPI);
  }
);

export const validateServiceAccountAsync = createAsyncThunk(
  "serviceAccount/validate",
  async (serviceAccountId, thunkAPI) => {
    return await validateServiceAccount(serviceAccountId, thunkAPI);
  }
);

export const deleteServiceAccountAsync = createAsyncThunk(
  "serviceAccount/delete",
  async (serviceAccountId, thunkAPI) => {
    return await deleteServiceAccount(serviceAccountId, thunkAPI);
  }
);