// src/api/authApi.js
import axios from "axios";
const url = process.env.REACT_APP_API_URL;
export const login = async (credentials, thunkAPI) => {
  try {
    const basicAuthString = btoa(
      `${credentials.email}:${credentials.password}`
    );
    const response = await axios.post(`${url}/accounts/login`, null, {
      headers: {
        Authorization: `Basic ${basicAuthString}`,
      },
    });
    if (response.data.status === 403) {
      const verificationEmailResponse = await axios.post(
        `${url}/accounts/verification/initiate`,
        { email: credentials.email }
      );
      return verificationEmailResponse.data.message;
    } else {
      return response.data.response;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const getUser = async (thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(`${url}/users/me/`, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const googleLogin = async (data, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/accounts/login`, null, {
      headers: {
        "x-google-auth": data,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const register = async (data, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/accounts/signup`, data);
    if (response.data.status === 200) {
      //send a verification email if the user is successfully registered
      const verificationEmailResponse = await axios.post(
        `${url}/accounts/verification/initiate`,
        { email: data.email }
      );
      return verificationEmailResponse.data.message;
    } else {
      return response.data.response;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const updateUserEmail = async (data, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.put(`${url}/users/update/email`, data, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const updateUserFirstName = async (data, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.put(`${url}/users/update/firstName`, data, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const updateUserLastName = async (data, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.put(`${url}/users/update/lastName`, data, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const updateUserPassword = async (data, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.put(
      `${url}/users/update/password`,
      {
        data: data,
      },
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};
