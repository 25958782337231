import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAsync } from "./features/auth/authActions";
import Dashboard from "./pages/Dashboard/Dashboard";
import Settings from "./pages/Settings/Settings";
import Models from "./pages/Models/Models";
import Plugins from "./pages/Plugins/Plugins";
import UsageAndBillings from "./pages/UsageAndBillings/UsageAndBillings";
import Endpoints from "./pages/Endpoints/NewEndpoints";
import Layout from "./components/layout/Layout";
import Register from "./pages/Register/Register";
import Finetune from "./pages/Finetune/Finetune";
import Dataset from "./pages/Dataset/Dataset";
import Api from "./pages/GenerateAPI/api";
import Deploy from "./pages/Deploy/Deploy";
import FinetuneDashboard from "./pages/FinetuneDetails/details";
import Playground from "./pages/Playground/Playground";
import AddPayment from "./pages/AddPayment/AddPayment";
import AccountVerification from "./pages/AccountVerification/AccountVerification";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import PublicLayout from "./components/PublicLayout/PublicLayout";
import UsageMetrics from "./components/UsageDetails/UsageMetrics";
import Usage from "./pages/Usage/Usage";
import Cookbook from "./pages/Cookbook/cookbook";
import Services from "./pages/Services/services";
import HistoryActivity from "./pages/Transactions/TransactionHistory";
import { getUserApiKeysAsync } from "./features/userApi/userApiActions";
import ClarityTracking from "./ClarityTracking";
import RazorpayTest from "./features/razorpay-test";


function App() {
  const { access_token, user } = useSelector((state) => state.auth);
  const { userApiKeys } = useSelector((state) => state.userApi); 
  const dispatch = useDispatch();
  const location = useLocation();
  const initialPath = location.pathname;
  const navigate = useNavigate();
  const publicRoutes = useMemo(
    () => [
      { path: "/login", component: <Login /> },
      { path: "/register", component: <Register /> },
      { path: "/auth/verify", component: <AccountVerification /> },
      { path: "/auth/forgot-password", component: <ForgotPassword /> },
    ],
    []
  );

  const privateRoutes = useMemo(
    () => [
      { path: "/", component: <Dashboard /> },
      { path: "/settings", component: <Settings /> },
      { path: "/plugins", component: <Plugins /> },
      { path: "/deployments", component: <Models /> },
      { path: "/finetune", component: <Finetune /> },
      { path: "/dataset", component: <Dataset /> },
      { path: "/billing", component: <UsageAndBillings /> },
      { path: "/endpoints", component: <Endpoints /> },
      { path: "/api", component: <Api /> },
      { path: "/details/:jobId", component: <FinetuneDashboard /> },
      { path: "/deploy/:clusterUUID", component: <Deploy /> },
      { path: "/deploy/:clusterUUID/usage", component: <UsageMetrics /> },
      { path: "/playground", component: <Playground /> },
      { path: "/payment-methods", component: <AddPayment /> },
      { path: "/cookbook", component: <Cookbook /> },
      { path: "/services", component: <Services /> },
      { path: "/transactions", component: <HistoryActivity/> },
      { path: "/razorpay", component: <RazorpayTest/> },

      {
        path: '/usage', component: <Usage />
      }
    ],
    []
  );

  //if access_token is present in localstorage and user is not present in redux store then get user
  useEffect(() => {
    if (access_token && !user) {
      dispatch(getUserAsync());
      dispatch(getUserApiKeysAsync());
    }
  }, [access_token, dispatch, user]);

  const isNew = userApiKeys.length === 0;

  useEffect(() => {
    if (access_token && user) { 
      if (publicRoutes.find((route) => route.path === location.pathname)) {
        if (publicRoutes.find((route) => route.path === initialPath)) {
          if (isNew) {
            navigate("/playground");
          } else {
            navigate("/");
          }
        } else {
          navigate(initialPath);
        }
      }
    }
  }, [
    access_token,
    user,
    location.pathname,
    publicRoutes,
    navigate,
    initialPath,
    isNew,
  ]);

  return (
    <div className="App">
      <ClarityTracking /> 
      <Routes>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<PublicLayout>{route.component}</PublicLayout>}
          />
        ))}

        {privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<Layout>{route.component}</Layout>}
          />
        ))}
      </Routes>
    </div>
  );
}

export default App;