import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Select,
  VStack,
  Collapse,
  Box,
  useDisclosure,
  HStack,
  useToast,
  Radio,
  RadioGroup,
  Stack
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { getModelsAsync } from '../../features/models/modelActions';
import { getAllDatasetsAsync } from '../../features/dataset/datasetAction';
import { createFineTuningJobAsync } from '../../features/finetune/finetuneAction';

export default function BasicModal() {
  const { isOpen, onToggle } = useDisclosure();
  const [open, setOpen] = useState(false);
  const [datasetType, setDatasetType] = useState("huggingface");
  const dispatch = useDispatch();
  const toast = useToast();
  const { models } = useSelector(state => state.model);
  const { datasets } = useSelector(state => state.datasets);

  const [formData, setFormData] = useState({
    model: '',
    modelPath: '',
    modelName: '',
    data_path: '',
    learning_rate: 0.0002,
    num_epochs: 2,
    method: 'lora',
    warmup_steps: 10,
    evals_per_epoch: 4,
    data_type: 'alpaca',
    gradient_accumulation_steps: 8,
    micro_batch_size: 1,
    ds_type: 'json',
    file: ''
  });

  useEffect(() => {
    dispatch(getModelsAsync({ page: 1, size: 10 }));
    dispatch(getAllDatasetsAsync());
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requestData = { ...formData };

    if (datasetType === "huggingface") {
      delete requestData.file;
    } else if (datasetType === "file") {
      delete requestData.data_path;
    }

    try {
      const result = await dispatch(createFineTuningJobAsync(requestData)).unwrap();
      setOpen(false);

      if (result && result.uuid) {
        toast({
          title: "Initializing",
          description: "Job started successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom"
        });
      } else {
        toast({
          title: "Error",
          description: "There was an error starting the job.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom"
        });
      }
    } catch (error) {
      setOpen(false);
      toast({
        title: "Error",
        description: error.message || "There was an error processing your request.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom"
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleModelSelect = (event) => {
    const selectedModel = models.find(model => model.uuid === event.target.value);
    setFormData(prevState => ({
      ...prevState,
      model: selectedModel.uuid,
      modelPath: selectedModel.path,
      modelName: selectedModel.name
    }));
  };

  const handleDatasetSelect = (event) => {
    const selectedDataset = datasets.find(dataset => dataset.uuid === event.target.value);
    setFormData(prevState => ({
      ...prevState,
      file: selectedDataset.uuid
    }));
  };

  return (
    <>
      <Button
        leftIcon={<AddIcon />}
        bg="#4E4FEB"
        onClick={() => setOpen(true)}
        mt={4}
        mb={4}
        size="lg"
        color="white"
        _hover={{
          bg: "brand.secondary",
        }}
      >
        Create Finetune Job
      </Button>

      <Modal size={'2xl'} isOpen={open} onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Job</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box maxHeight="500px" overflowY="auto">
              <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                  <FormControl>
                    <FormLabel>Model</FormLabel>
                    <Select name="model" onChange={handleModelSelect} placeholder="Select Model">
                      {models.map(model => (
                        <option key={model.uuid} value={model.uuid}>{model.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Dataset Type</FormLabel>
                    <RadioGroup onChange={setDatasetType} value={datasetType}>
                      <Stack direction="row">
                        <Radio value="huggingface">HuggingFace</Radio>
                        <Radio value="file">File</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                  {datasetType === "file" && (
                    <FormControl>
                      <FormLabel>Dataset</FormLabel>
                      <Select name="file" onChange={handleDatasetSelect} placeholder="Select Dataset" required>
                        {datasets.map(dataset => (
                          <option key={dataset.uuid} value={dataset.uuid}>{dataset.name}</option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {datasetType === "huggingface" && (
                    <FormControl>
                      <FormLabel>Dataset Path</FormLabel>
                      <Input name="data_path" type="text" placeholder="Enter HuggingFace Dataset Path" value={formData.data_path} onChange={handleInputChange} required />
                    </FormControl>
                  )}
                  <Button onClick={onToggle} variant="ghost" alignSelf="start">
                    Advanced Options
                  </Button>
                  <Collapse in={isOpen} animateOpacity>
                    <VStack spacing={4}>
                      <HStack>
                        <FormControl>
                          <FormLabel>Learning Rate</FormLabel>
                          <Input name="learning_rate" type="number" placeholder="0.0002" value={formData.learning_rate} onChange={handleInputChange} />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Number of Epochs</FormLabel>
                          <Input name="num_epochs" type="number" placeholder="2" value={formData.num_epochs} onChange={handleInputChange} />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Warmup Steps</FormLabel>
                          <Input name="warmup_steps" type="number" placeholder="10" value={formData.warmup_steps} onChange={handleInputChange} />
                        </FormControl>
                      </HStack>
                      <HStack>
                        <FormControl>
                          <FormLabel>Evaluations Per Epoch</FormLabel>
                          <Input name="evals_per_epoch" type="number" placeholder="4" value={formData.evals_per_epoch} onChange={handleInputChange} />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Gradient Steps</FormLabel>
                          <Input name="gradient_accumulation_steps" type="number" placeholder="8" value={formData.gradient_accumulation_steps} onChange={handleInputChange} />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Micro Batch Size</FormLabel>
                          <Input name="micro_batch_size" type="number" placeholder="1" value={formData.micro_batch_size} onChange={handleInputChange} />
                        </FormControl>
                      </HStack>
                      <FormControl>
                        <FormLabel>Data Type</FormLabel>
                        <Select name="data_type" onChange={handleInputChange} defaultValue="alpaca">
                          <option value="alpaca">Alpaca</option>
                          <option value="llama">Llama</option>
                        </Select>
                      </FormControl>
                    </VStack>
                  </Collapse>
                  <Button type="submit" w={"100%"} variant="solid" bg={'brand.primary'} color='white'>
                    Create Finetune Job
                  </Button>
                </VStack>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}