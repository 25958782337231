import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllServiceAccountsAsync , deleteServiceAccountAsync } from "../../features/services/serviceAccountAction";
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Spinner, Flex, Alert, AlertIcon, Card , IconButton
} from "@chakra-ui/react";
import { CheckCircle2, Loader2, Trash2 } from "lucide-react";
import CloudProviderCell from "./cloudprovider";

const ServiceAccountsTable = () => {
  const dispatch = useDispatch();
  const { serviceAccounts, loading, error } = useSelector(state => state.serviceAccount);

  useEffect(() => {
    dispatch(fetchAllServiceAccountsAsync());
  }, [dispatch]);

  const handleDelete = (serviceAccountId) => {
    if (window.confirm("Are you sure you want to delete this service account?")) {
      dispatch(deleteServiceAccountAsync(serviceAccountId));
    }
  };

  if (loading) {
    return <Spinner size="lg" />;
  }

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        {error.message}
      </Alert>
    );
  }

  return (
    <Card mt={4} borderRadius="md" overflow="hidden" variant="outline" width={'full'}>
      <Table size={"sm"} variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Cloud</Th>
            <Th>Validity Status</Th>
            <Th>Created At</Th>
            <Th>Action</Th> 
          </Tr>
        </Thead>
        <Tbody>
          {serviceAccounts.length > 0 ? (
            serviceAccounts.map(payload => (
              <Tr key={payload.uuid}>
                <Td>{payload.name}</Td>
                <CloudProviderCell cloudProvider={payload.cloud} />
                <Td>
                  <Flex align="center">
                    {payload.validityStatus === "VALIDATED" ? (
                      <CheckCircle2 size={18} color="green" style={{ marginRight: "0.5rem" }} />
                    ) : (
                      <Loader2 size={18} color="orange" style={{ marginRight: "0.5rem" }} />
                    )}
                    {payload.validityStatus}
                  </Flex>
                </Td>
                <Td>{new Date(payload.createdAt).toLocaleString()}</Td>
                <Td>
                  <IconButton 
                    aria-label="Delete service account" 
                    icon={<Trash2 color="red" size={18} />} 
                    size="sm"
                    onClick={() => handleDelete(payload.uuid)}
                  />
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="4">No service accounts available</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Card>
  );
};

export default ServiceAccountsTable;
