import { createAsyncThunk } from "@reduxjs/toolkit";
import { createUserApiKeyApi, getUserApiKeysApi, revokeUserApiKeyApi } from "../../api/userApiKey";

export const createUserApiKeyAsync = createAsyncThunk(
  "userApi/createUserApiKey",
  async (apiKeyName, thunkAPI) => {
    try {
      const response = await createUserApiKeyApi(apiKeyName, thunkAPI);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response?.data?.message || error.message,
        statusCode: error.response?.status || 500
      });
    }
  }
);

export const getUserApiKeysAsync = createAsyncThunk(
  "userApi/getUserApiKeys",
  async (_, thunkAPI) => {
    return await getUserApiKeysApi(thunkAPI);
  }
);

export const revokeUserApiKeyAsync = createAsyncThunk(
  "userApi/revokeUserApiKey",
  async (apiKeyUuid, thunkAPI) => {
    return await revokeUserApiKeyApi(apiKeyUuid, thunkAPI);
  }
);
