import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  VStack,
  HStack,
  Radio,
  Text,
  RadioGroup,
  Collapse,
  Stack,
  Select,
  useDisclosure,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { getModelsAsync } from "../../features/models/modelActions";
import { getAllDatasetsAsync } from "../../features/dataset/datasetAction";
import { createFineTuningJobAsync } from "../../features/finetune/finetuneAction";

const Neptune = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [datasetType, setDatasetType] = useState("huggingface");
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const { models } = useSelector((state) => state.model);
  const { datasets } = useSelector((state) => state.datasets);
  const { colorMode } = useColorMode();

  const [formData, setFormData] = useState({
    model: "a409b9ac-2f19-4851-9235-dbd0ca069e3e65",
    modelPath: "nextai-team/neptune-inst-v1",
    modelName: "nextai-team/neptune-inst-v1",
    data_path: "",
    learning_rate: 0.0002,
    num_epochs: 2,
    method: "lora",
    warmup_steps: 10,
    evals_per_epoch: 4,
    data_type: "alpaca",
    gradient_accumulation_steps: 8,
    micro_batch_size: 1,
    ds_type: "json",
    file: "",
  });

  useEffect(() => {
    dispatch(getModelsAsync({ page: 1, size: 10 }));
    dispatch(getAllDatasetsAsync());
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requestData = { ...formData };

    if (datasetType === "huggingface") {
      delete requestData.file;
    } else if (datasetType === "file") {
      delete requestData.data_path;
    }

    try {
      const result = await dispatch(createFineTuningJobAsync(requestData)).unwrap();

      if (result && result.uuid) {
        toast({
          title: "Initializing",
          description: "Job started successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        navigate("/finetune");
      } else {
        toast({
          title: "Error",
          description: "There was an error starting the job.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "There was an error processing your request.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleModelSelect = (event) => {
    const selectedModel = models.find((model) => model.uuid === event.target.value);
    setFormData((prevState) => ({
      ...prevState,
      model: selectedModel.uuid,
      modelPath: selectedModel.path,
      modelName: selectedModel.name,
    }));
  };

  const handleDatasetSelect = (event) => {
    const selectedDataset = datasets.find((dataset) => dataset.uuid === event.target.value);
    setFormData((prevState) => ({
      ...prevState,
      file: selectedDataset.uuid,
    }));
  };

  return (
    <Box p={8} bg={colorMode === "light" ? "white" : "gray.800"} border={"solid 1px"} borderColor={'gray.300'} rounded="lg">
        <Text mb={4}>Finetune Neptune Model</Text>
      <form onSubmit={handleSubmit}>
        <VStack spacing={6}>
          <FormControl>
            <FormLabel>Dataset Type</FormLabel>
            <RadioGroup onChange={setDatasetType} value={datasetType}>
              <Stack direction="row" spacing={5}>
                <Radio value="huggingface">HuggingFace</Radio>
                <Radio value="file">File</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          {datasets.length === 0 ? (
            <Button onClick={() => navigate("/dataset")} colorScheme="blue">
              Add Dataset
            </Button>
          ) : datasetType === "file" ? (
            <FormControl>
              <FormLabel>Dataset</FormLabel>
              <Select
                name="file"
                onChange={handleDatasetSelect}
                placeholder="Select Dataset"
                required
              >
                {datasets.map((dataset) => (
                  <option key={dataset.uuid} value={dataset.uuid}>
                    {dataset.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          ) : (
            <FormControl>
              <FormLabel>Dataset Path</FormLabel>
              <Input
                name="data_path"
                type="text"
                placeholder="Enter HuggingFace Dataset Path"
                value={formData.data_path}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          )}
            <VStack spacing={4} w="full">
              <HStack spacing={4} w="full">
                <FormControl>
                  <FormLabel>Learning Rate</FormLabel>
                  <Input
                    name="learning_rate"
                    type="number"
                    placeholder="0.0002"
                    value={formData.learning_rate}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Number of Epochs</FormLabel>
                  <Input
                    name="num_epochs"
                    type="number"
                    placeholder="2"
                    value={formData.num_epochs}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </HStack>
              <HStack spacing={4} w="full">
                <FormControl>
                  <FormLabel>Warmup Steps</FormLabel>
                  <Input
                    name="warmup_steps"
                    type="number"
                    placeholder="10"
                    value={formData.warmup_steps}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Evaluations Per Epoch</FormLabel>
                  <Input
                    name="evals_per_epoch"
                    type="number"
                    placeholder="4"
                    value={formData.evals_per_epoch}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </HStack>
              <HStack spacing={4} w="full">
                <FormControl>
                  <FormLabel>Gradient Steps</FormLabel>
                  <Input
                    name="gradient_accumulation_steps"
                    type="number"
                    placeholder="8"
                    value={formData.gradient_accumulation_steps}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Micro Batch Size</FormLabel>
                  <Input
                    name="micro_batch_size"
                    type="number"
                    placeholder="1"
                    value={formData.micro_batch_size}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </HStack>
              <FormControl>
                <FormLabel>Data Type</FormLabel>
                <Select
                  name="data_type"
                  onChange={handleInputChange}
                  defaultValue="alpaca"
                >
                  <option value="alpaca">Alpaca</option>
                  <option value="llama">Llama</option>
                </Select>
              </FormControl>
            </VStack>
          <Button
            type="submit"
            w="full"
            variant="solid" bg={'brand.primary'} color='white'
            _hover={{ bg: "brand.secondary" }}
          >
            Create Finetune Job
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default Neptune;