import { Container, useColorMode } from "@chakra-ui/react";
import React from "react";
import HeroSection from "../../components/heroSection/HeroSection";

function Dashboard() {
  return <HeroSection />;
}

export default Dashboard;

