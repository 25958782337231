import { Box, Flex, Heading, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ApiUsageChart from "./ApiUsageChart";
import { TokensUsageChart } from "./TokensUsageChart";
import axios from "axios";
import { format, subMonths, startOfMonth } from "date-fns";

export default function UsageDetails({ usageData }) {
  const [apiUsage, setApiUsage] = useState([]);
  const [tokenUsage, setTokenUsage] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!usageData || usageData.length === 0) {
        const startDate = startOfMonth(subMonths(new Date(), 1)); 
        const endDate = new Date(); 

        try {
          const response = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL}/users/metrics?from=${format(startDate, "yyyy-MM-dd")}&to=${format(endDate, "yyyy-MM-dd")}`,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "application/json",
            },
          });

          usageData = response.data.response; 
        } catch (error) {
          console.log(error);
        }
      }

      const apiData = usageData[0]?.api || [];
      const tokenData = usageData[0]?.tokens || [];

      setApiUsage(
        apiData
          .map((item) => ({ date: item._id.date, count: item.count }))
          .sort((a, b) => new Date(a.date) - new Date(b.date))
      );
      setTokenUsage(
        tokenData.map((item) => ({
          date: item._id.date,
          total_tokens: item.total_tokens,
          prompt_tokens: item.prompt_tokens,
          completion_tokens: item.completion_tokens,
        }))
      );
    };

    fetchData(); 

  }, [usageData]);

  return (
    <Box mt={10}>
      <SimpleGrid
        columns={{
          base: 1,
          md: 1,
          lg: 1,
          xl: 2,
        }}
        spacing={10}
      >
        <Flex flexDirection="column" flex={1}>
          <Heading size="sm" mb={5}>
            API Usage
          </Heading>
          <ApiUsageChart chartData={apiUsage} title="API Usage" />
        </Flex>
        <Flex flexDirection="column" flex={1}>
          <Heading size="sm" mb={5}>
            Tokens Usage
          </Heading>
          <TokensUsageChart chartData={tokenUsage} title="Tokens Usage" />
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
