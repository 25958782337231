import React, { useState } from 'react';
import {
  Box,
  useColorModeValue,
  Text,
  VStack,
  Button,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Select,
  Icon,
} from '@chakra-ui/react';
import { FaRegEdit, FaTrashAlt, FaRegFileAlt } from 'react-icons/fa';

function DatasetCard({ id, title, format, shape, lastUpdated, onEdit, onDelete }) {
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const [newTitle, setNewTitle] = useState(title);
  const [newFormat, setNewFormat] = useState(format);

  const handleEdit = () => {
    onEdit(id, newTitle, newFormat);
    onEditClose();
  };

  const handleConfirmDelete = () => {
    onDelete(id);
    onDeleteClose();
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      p={{ base: 3, md: 4 }}
      rounded="lg"
      borderWidth="1px"
      border={"solid 1px"}
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      display="flex"
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems={{ base: 'flex-start', sm: 'center' }}
      justifyContent="space-between"
      textAlign="left"
      maxW="full"
      mb={4}
    >
      <HStack spacing={3} alignItems="center" w={{ base: '100%', sm: 'auto' }}>
        <Icon as={FaRegFileAlt} boxSize={8} color={useColorModeValue('blue.500', 'blue.300')} />
        <VStack align="start" spacing={0}>
          <Text
            color={useColorModeValue('gray.800', 'white')}
            fontSize="sm"
            fontWeight="bold"
          >
            {title}
          </Text>
          <Text fontSize="sm" color={useColorModeValue('gray.500', 'gray.400')}>
            {formatDate(lastUpdated)}
          </Text>
        </VStack>
      </HStack>

      <VStack
        alignItems={{ base: 'flex-start', sm: 'flex-end' }}
        spacing={1}
        mt={{ base: 3, sm: 0 }}
        flexShrink={0}
      >
        <Text fontSize="sm">
          <strong>Shape:</strong> {shape}
        </Text>
        <Text fontSize="sm">
          <strong>Type:</strong> {format}
        </Text>
        <HStack spacing={2}>
          <Button
            variant="outline"
            colorScheme="red"
            size="xs"
            onClick={onDeleteOpen}
            leftIcon={<Icon as={FaTrashAlt} />}
          >
            Delete
          </Button>
        </HStack>
      </VStack>

      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Dataset</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <Input value={newTitle} onChange={(e) => setNewTitle(e.target.value)} placeholder="File Name" />
              <Select value={newFormat} onChange={(e) => setNewFormat(e.target.value)}>
                <option value="alpaca">Alpaca</option>
                <option value="sharegpt">ShareGPT</option>
                <option value="completion">Completion</option>
              </Select>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onEditClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleEdit}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalBody>Do you really want to delete this item? This action cannot be undone.</ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onDeleteClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleConfirmDelete}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default DatasetCard;