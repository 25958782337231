import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Stat, StatLabel, StatNumber, StatGroup } from "@chakra-ui/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function ApiUsageChart({ chartData ,  }) {
  const calculateStepSize = (chartData) => {
    if (!chartData || chartData.length === 0) {
      return 1;
    }

    const maxCount = Math.max(...chartData.map((item) => item.count));
    const minCount = Math.min(...chartData.map((item) => item.count));
    const range = maxCount - minCount;
    const desiredNumberOfTicks = 5;
    const stepSize = Math.ceil(range / desiredNumberOfTicks);

    return stepSize;
  };

  const totalApiUsage = chartData.reduce((acc, curr) => acc + curr.count, 0);

  const getGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, 'rgba(243, 204, 243, 0.5)'); 
    gradient.addColorStop(1, 'rgba(185, 49, 252, 0.5)'); 

    return gradient;
  };

  const data = {
    labels: chartData.map((item) => item.date),
    datasets: [
      {
        fill: true,
        label: "API Calls",
        data: chartData.map((item) => item.count),
        borderColor: "#B931FC", 
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea);
        },
        cubicInterpolationMode: "monotone",
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: calculateStepSize(chartData),
        },
      },
    },
  };

  return (
    <>
      <StatGroup mb={4}>
        <Stat>
          <StatLabel>Total API Calls</StatLabel>
          <StatNumber>{totalApiUsage}</StatNumber>
        </Stat>
      </StatGroup>
      <Line options={options} data={data} />
    </>
  );
}
