import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllPaymentMethodsApi,
  getSpecificPaymentMethodApi,
  createPaymentSetupTokenApi,
  createPaymentMethodApi,
} from "../../api/paymentApi";

export const completePaymentSetupAsync = createAsyncThunk(
    'payment/completePaymentSetup',
    async ({ stripe, cardElement, billingDetails }, thunkAPI) => {
        try {
            const setupTokenResponse = await createPaymentSetupTokenApi(thunkAPI);
            const clientSecret = setupTokenResponse.clientSecret;

            const cardSetupResult = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: billingDetails,
                },
            });

            if (cardSetupResult.error) {
                throw new Error(cardSetupResult.error.message);
            }

            if (cardSetupResult.setupIntent.status === 'succeeded') {
                const paymentMethodId = cardSetupResult.setupIntent.payment_method;
                const paymentMethodData = {
                    isDefault: true,
                    paymentMethod: paymentMethodId,
                };
                return await createPaymentMethodApi(paymentMethodData, thunkAPI);
            } else {
                throw new Error('Setup Intent did not succeed');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


  

export const getAllPaymentMethodsAsync = createAsyncThunk(
  "payment/getAllPaymentMethods",
  async (_, thunkAPI) => {
    return await getAllPaymentMethodsApi(thunkAPI);
  }
);

export const getSpecificPaymentMethodAsync = createAsyncThunk(
  "payment/getSpecificPaymentMethod",
  async (methodId, thunkAPI) => {
    return await getSpecificPaymentMethodApi(methodId, thunkAPI);
  }
);

export const createPaymentSetupTokenAsync = createAsyncThunk(
  "payment/createPaymentSetupToken",
  async (_, thunkAPI) => {
    return await createPaymentSetupTokenApi(thunkAPI);
  }
);

export const createPaymentMethodAsync = createAsyncThunk(
  "payment/createPaymentMethod",
  async (paymentData, thunkAPI) => {
    return await createPaymentMethodApi(paymentData, thunkAPI);
  }
);
