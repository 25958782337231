import axios from "axios";
import { getSpecificClusterAsync } from "../features/clusters/clusterAction";
const url = process.env.REACT_APP_API_URL;

export const getAllClusters = async (thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(`${url}/clusters/v1/all`, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const terminateClusterApi = async (uuid, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.delete(`${url}/clusters/v1/${uuid}`, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    setTimeout(() => {
      thunkAPI.dispatch(getSpecificClusterAsync(uuid));
    } , 1000);
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const pauseClusterApi = async (uuid, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.patch(
      `${url}/clusters/v1/${uuid}/stop`,
      {},
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    setTimeout(() => {
      thunkAPI.dispatch(getSpecificClusterAsync(uuid));
    } , 1000);
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const resumeClusterApi = async (uuid, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.patch(
      `${url}/clusters/v1/${uuid}/start`,
      {},
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    setTimeout(() => {
      thunkAPI.dispatch(getSpecificClusterAsync(uuid));
    } , 1000);
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const fetchClusterLogsApi = async (
  clusterId,
  containerId,
  fromTimestamp,
  thunkAPI
) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(
      `${url}/clusters/v1/${clusterId}/logs/${containerId}?from=${fromTimestamp}`,
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const archiveClusterApi = async (clusterId, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.patch(
      `${url}/clusters/v1/${clusterId}/archive`,
      {},
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const getSpecificClusterApi = async (clusterUUID, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(`${url}/clusters/v1/${clusterUUID}`, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const restartServicesApi = async (uuid, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.patch(`${url}/clusters/v1/${uuid}/restart-containers/`, {}, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    setTimeout(() => {
      thunkAPI.dispatch(getSpecificClusterAsync(uuid));
    } , 1000);
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};
