import axios from "axios";
const url = process.env.REACT_APP_API_URL;

export const createUserApiKeyApi = async (apiKeyName, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.post(
      `${url}/users/access-keys/`,
      { name: apiKeyName },
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const getUserApiKeysApi = async (thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(
      `${url}/users/access-keys/`,
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const revokeUserApiKeyApi = async (apiKeyUuid, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const response = await axios.delete(
        `${url}/users/access-keys/${apiKeyUuid}`,
        {
          headers: {
            authorization: `Bearer ${state.auth.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response?.data?.message || error.message,
        statusCode: error.response?.status || 500
      });
    }
  };
  
  
