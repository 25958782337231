import React, { useEffect, useState } from "react";
import axios from "axios";
import { format, subMonths, startOfMonth } from "date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  ChakraProvider,
  Stat,
  StatLabel,
  StatNumber,
  SimpleGrid,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { CalendarIcon } from "lucide-react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TransactionsMetricsChart = () => {
  const [startDate, setStartDate] = useState(
    format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd")
  );
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [totals, setTotals] = useState({
    inference: 0,
    fastInference: 0,
    subscription: 0,
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  useEffect(() => {
    fetchData(startDate);
  }, [startDate]);

  const fetchData = async (start) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/transactions/metrics?from=${start}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      const metrics = response.data.metrics[0].metadata.concat(
        response.data.metrics[0].metrics
      );
      const dates = [...new Set(metrics.map((item) => item._id.date))];
      const dataMap = {};
      let totalInference = 0,
        totalFastInference = 0,
        totalSubscription = 0;

      metrics.forEach(({ _id, count }) => {
        if (!dataMap[_id.date]) {
          dataMap[_id.date] = {
            inference: 0,
            fastInference: 0,
            subscription: 0,
          };
        }

        const type = _id.type ? _id.type.replace("-", "") : "inference";
        const absCount = Math.abs(count);
        if (type === "fastinference") {
          dataMap[_id.date]["fastInference"] += absCount;
        } else {
          dataMap[_id.date][type] += absCount;
        }
        if (type === "inference") totalInference += absCount;
        if (type === "fastinference") totalFastInference += absCount;
        if (type === "subscription") totalSubscription += absCount;
      });

      setTotals({
        inference: totalInference.toFixed(2),
        fastInference: totalFastInference.toFixed(2),
        subscription: totalSubscription.toFixed(2),
      });

      const totalSum = totalInference + totalFastInference + totalSubscription;

      setTotals((prevTotals) => ({
        ...prevTotals,
        totalSum: totalSum.toFixed(2),
      }));

      const datasets = [
        {
          label: "Inference",
          data: dates.map((date) => dataMap[date].inference.toFixed(2)),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Model Usage",
          data: dates.map((date) => dataMap[date].fastInference.toFixed(2)),
          backgroundColor: "rgba(54, 162, 235, 0.5)",
        },
        {
          label: "Subscription",
          data: dates.map((date) => dataMap[date].subscription.toFixed(2)),
          backgroundColor: "rgba(255, 206, 86, 0.5)",
        },
      ];

      setChartData({
        labels: dates,
        datasets,
      });
    } catch (error) {
      console.error("Error fetching transactions metrics:", error);
    }
  };

  return (
      <Box p={5}>
      <FormControl>
      <FormLabel htmlFor="start-date" fontSize="lg" fontWeight="bold">Start Date:</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <CalendarIcon color="gray.500" />
            </InputLeftElement>
            <Input
              id="start-date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              size="md"
              focusBorderColor="teal.500"
              borderColor="gray.300"
              _hover={{
                borderColor: 'gray.400',
              }}
              _placeholder={{
                color: 'gray.500',
              }}
            />
          </InputGroup>
        </FormControl>
        <SimpleGrid columns={4} spacing={10} mt={5}>
          <Stat>
            <StatLabel>Inference</StatLabel>
            <StatNumber>${totals.inference}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Fast-Inference</StatLabel>
            <StatNumber>${totals.fastInference}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Subscription</StatLabel>
            <StatNumber>${totals.subscription}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Total</StatLabel>
            <StatNumber>${totals.totalSum}</StatNumber>
          </Stat>
        </SimpleGrid>
        <Box mt={5}>
          <Bar options={options} data={chartData} />
        </Box>
      </Box>
  );
};

export default TransactionsMetricsChart;
