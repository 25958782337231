import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  Button,
  Badge,
  Grid,
  GridItem,
  useColorModeValue,
  ButtonGroup,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Spinner,
  Spacer,
  Input,
  Alert,
  AlertDescription,
  AlertTitle,
  Tag,
  Image,
} from "@chakra-ui/react";
import { DeleteIcon, CopyIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  terminateClusterAsync,
  pauseClusterAsync,
  resumeClusterAsync,
  restartServicesAsync,
} from "../../features/clusters/clusterAction";
import { createApiKeyAsync } from "../../features/api/apiAction";
import ClusterLogs from "./ClusterLog";
import ContainerStreamLogs from "./ContainerStreamLogs";
import ContainerSpecificLogs from "./ContainerSpecificLogs";
import RunPodLogs from "./RunpodLog";
import { useNavigate } from "react-router-dom";
import HealthStatus from "./healthcheck";
import { ScanSearch, Eye, KeyRound, LineChart } from "lucide-react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import gcp from "../../assets/images/gcp2.png";
import aws from "../../assets/images/aws22.png";

export default function DeployPage({ data }) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRestart, setIsModalOpenRestart] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const [selectedContainerId, setSelectedContainerId] = useState("");
  const [isModalApiOpen, setIsModalApiOpen] = useState(false);
  const [keyName, setKeyName] = useState("");
  const [generatedApiKey, setGeneratedApiKey] = useState("");
  const { loading } = useSelector((state) => state.cluster);

  const hasServiceAccount = data && data?.serviceAccount;

  const isRunPod = data?.tier?.cloud === "RunPod";
  const buttonBgColor = useColorModeValue("brand.primary", "brand.primary");
  const buttonTextColor = useColorModeValue("white", "white");

  const handleContainerSelect = (containerId) => {
    setSelectedContainerId(containerId);
  };

  const handleOpenApiModal = () => {
    setIsModalApiOpen(true);
  };

  const handleCloseApiModal = () => {
    setIsModalApiOpen(false);
    setKeyName("");
    setGeneratedApiKey("");
  };

  const handleGenerateApiKey = async () => {
    try {
      const response = await dispatch(
        createApiKeyAsync({ clusterId: data?.uuid, apiKeyName: keyName })
      ).unwrap();

      const apiKey = response.key;
      setGeneratedApiKey(apiKey);

      toast({
        title: "API Key Generated",
        description: "Successfully generated the API key.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error generating API key:", error);

      const errorMessage =
        error.response?.data?.message || "Failed to generate the API key.";

      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTerminateClick = () => {
    setIsModalOpen(true);
  };

  const handleRestartClick = () => {
    setIsModalOpenRestart(true);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast({
          title: "Copied to Clipboard",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);

        toast({
          title: "Error",
          description: "Failed to copy to clipboard.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleOpenLogsModal = () => {
    setIsLogsModalOpen(true);
  };

  const handleRestartServices = async () => {
    try {
      await dispatch(restartServicesAsync(data?.uuid)).unwrap();

      toast({
        title: "Cluster Started",
        description: "Restarting the Services.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error restarting services:", error);

      toast({
        title: "Error",
        description: "Failed to Restart.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsModalOpenRestart(false);
  };

  const handlePauseResume = () => {
    if (data && (data?.status === "RUNNING" || data?.status === "SUCCESS")) {
      dispatch(pauseClusterAsync(data?.uuid))
        .unwrap()
        .then(() => {
          toast({
            title: "Cluster Paused",
            description: "Successfully paused the cluster.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch(() => {
          toast({
            title: "Error",
            description: "Failed to pause the cluster.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    } else if (data) {
      dispatch(resumeClusterAsync(data?.uuid))
        .unwrap()
        .then(() => {
          toast({
            title: "Cluster Started",
            description: "Successfully started the cluster.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch(() => {
          toast({
            title: "Error",
            description: "Failed to start the cluster.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  let displayStatus = data?.status;

  if (data?.status === "SUCCESS") {
    displayStatus = "RUNNING";
  }

  const handleConfirmTerminate = async () => {
    if (data && data?.uuid) {
      try {
        await dispatch(terminateClusterAsync(data?.uuid)).unwrap();
        toast({
          title: "Successfully Terminated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/deployments");
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to terminate the cluster.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      console.error("Cluster UUID is undefined");
    }
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsModalOpenRestart(false);
  };

  let statusColor;
  switch (data?.status) {
    case "INITIATED":
      statusColor = "blue.500";
      break;
    case "IN PROGRESS":
      statusColor = "yellow.500";
      break;
    case "SUCCESS":
      statusColor = "green.500";
      break;
    case "ERRORED":
      statusColor = "red.500";
      break;
    case "TERMINATED":
      statusColor = "red.500";
      break;
    case "RUNNING":
      statusColor = "green.500";
      break;
    case "PAUSED":
      statusColor = "orange.500";
      break;
    case "STOPPED":
      statusColor = "orange.500";
      break;
    case "FAILED_T0_START":
      statusColor = "gray.500";
      break;
    case "FAILED_T0_TERMINATE":
      statusColor = "gray.500";
      break;
    case "STARTING":
      statusColor = "orange.300";
      break;
    case "FAILED_T0_STOP":
      statusColor = "orange.300";
      break;
    default:
      statusColor = "blue.500";
      break;
  }

  const isQuantized = data?.model?.name.includes("GPTQ");

  const validStatuses = [
    "RUNNING",
    "SUCCESS",
    "STOPPED",
    "TERMINATED",
    "FAILED_T0_START",
    "FAILED_T0_TERMINATE",
    "ERRORED",
    "FAILED_T0_STOP",
  ];

  return (
    <>
      <Flex justifyContent="flex-end" mb="4">
        <ButtonGroup spacing="0.8rem" aria-label="Cluster Control Buttons">
          {data?.status === "RUNNING" && (
            <Button
              _hover={{ bg: "brand.secondary", color: "white" }}
              as="a"
              href="/finetune"
            >
              Finetune
            </Button>
          )}

          {/* Pause/Resume Endpoint Button */}
          {!isRunPod &&
            ["RUNNING", "SUCCESS", "STOPPED"].includes(data?.status) && (
              <Button
                _hover={{ bg: "brand.secondary", color: "white" }}
                onClick={handlePauseResume}
              >
                {data?.status === "STOPPED"
                  ? "Resume Endpoint"
                  : "Pause Endpoint"}
              </Button>
            )}

          {!isRunPod &&
            [
              "RUNNING",
              "SUCCESS",
              "STOPPED",
              "FAILED_T0_TERMINATE",
              "ERRORED",
              "FAILED_T0_START",
            ].includes(data?.status) && (
              <Button
                bg={buttonBgColor}
                color={buttonTextColor}
                _hover={{ bg: "brand.secondary", color: "white" }}
                onClick={handleRestartClick}
              >
                Restart Endpoint
              </Button>
            )}

          {/* Usage and Delete Buttons - These appear regardless of RunPod status */}
          {[
            "RUNNING",
            "SUCCESS",
            "STOPPED",
            "FAILED_T0_TERMINATE",
            "ERRORED",
          ].includes(data?.status) && (
            <>
              <IconButton
                aria-label="Usage"
                icon={<LineChart />}
                _hover={{ color: "green" }}
                _active={{ color: "green" }}
                onClick={() => navigate(`/usage`, { state: { cluster: data } })}
              />
              <IconButton
                aria-label="Delete"
                icon={<DeleteIcon />}
                _hover={{ color: "red" }}
                _active={{ color: "red" }}
                onClick={handleTerminateClick}
              />
            </>
          )}
        </ButtonGroup>
      </Flex>

      <Flex justifyContent="center" alignItems="center" w="full">
        {data?.status === "DEPLOYING" && (
          <Box
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
            overflow="hidden"
            width="full"
            p="2"
            display="flex"
            justifyContent="space-between"
            mr={30}
            ml={-40}
            mt={2}
          >
            <Box
              px="2"
              py={1}
              textAlign="center"
              fontWeight="extrabold"
              letterSpacing="1px"
              textTransform="uppercase"
              borderRight="1px solid"
              borderColor="gray.300"
              color="blue.500"
            >
              Info!
            </Box>
            <VStack justifyContent="center" alignItems="center">
              <Text pr={20} fontWeight="medium" color="green.500">
                Your first inference will undergo several minutes of cold start.
              </Text>
            </VStack>
          </Box>
        )}
      </Flex>
      <Flex justifyContent="start">
        <Box
          maxH={340}
          minW={920}
          maxW={920}
          ml={-52}
          bg={useColorModeValue("brand.background", "brand.backgroundDark")}
          boxShadow="md"
          borderRadius="md"
          borderColor="gray.200"
          p={6}
          mb={4}
          mt={6}
          border="1px solid"
        >
          <HStack display="flex" justifyContent="flex-end">
            <Spacer />

            {["RUNNING", "SUCCESS"].includes(data?.status) ? (
              <IconButton
                aria-label="API KEY"
                icon={<KeyRound strokeWidth={2} />}
                _hover={{ color: "blue" }}
                _active={{ color: "blue" }}
                size={"sm"}
                onClick={handleOpenApiModal}
              />
            ) : (
              <IconButton
                aria-label="API KEY"
                icon={<KeyRound strokeWidth={2} />}
                _hover={{ color: "blue" }}
                _active={{ color: "blue" }}
                size={"sm"}
                onClick={handleOpenApiModal}
                isDisabled={true}
              />
            )}

            {/*
            {hasServiceAccount && (
              <Tag size="sm" colorScheme="blue" borderRadius="full">
                <TagLabel>Private</TagLabel>
                {isGoogleCloud && (
                  <Avatar src={gcp} size={"2xs"} name="gcp" mr={-1} ml={2} />
                )}
                {isAwsCloud && (
                <Avatar src={aws} size={"2xs"} name="aws" mr={-1} ml={2} />
                )}
              </Tag>
            )} 
            */}
          </HStack>
          <Flex mt={-10} alignItems="center" justifyContent="space-between">
            <VStack align="start" spacing={4}>
              <HStack>
                <Text fontSize="lg">Endpoint Name:</Text>
                <Text fontSize="lg" fontWeight="bold">
                  {data?.name}
                </Text>
              </HStack>
              <HStack mt={-3}>
                <Badge
                  bg={statusColor}
                  borderRadius={"md"}
                  px={2}
                  py={1}
                  color={"brand.textPrimaryDark"}
                  mt={0}
                >
                  {/* {loading &&
                  data?.status !== "RUNNING" &&
                  data?.status !== "SUCCESS" &&
                  data?.status !== "STOPPED" &&
                  data?.status !== "TERMINATED" &&
                  data?.status !== "FAILED_T0_TERMINATE" &&
                  data?.status !== "ERRORED" ? (
                    <Spinner size="xs" color="brand.textPrimaryDark" />
                  ) : ( */}
                  {displayStatus}
                  {/* )} */}
                </Badge>
                {isQuantized ? (
                  <Tag size={"sm"}>INT4</Tag>
                ) : (
                  <Tag size={"sm"}>FP16</Tag>
                )}
                {!validStatuses.includes(displayStatus) && (
                  <Spinner speed="0.65s" size="xs" />
                )}
              </HStack>
              <Grid templateColumns="repeat(5, 1fr)" gap={6} width="full">
                <GridItem>
                  <Text> Model Name </Text>
                  <Text fontWeight="semibold">{data?.model?.name}</Text>
                </GridItem>
                <GridItem>
                  <Text>No. Nodes</Text>
                  <Text fontWeight="semibold">{data?.nodes}</Text>
                </GridItem>
                <GridItem>
                  <Text>Health Status</Text>
                  {data?.status === "RUNNING" || data?.status === "SUCCESS" ? (
                    <HealthStatus clusterId={data?.uuid} />
                  ) : (
                    <HStack spacing={1}>
                      <Text color="blue.500">
                        <ScanSearch />
                      </Text>
                      <Text color="blue.500">Uphold..</Text>
                    </HStack>
                  )}
                </GridItem>

                <GridItem>
                  <Text>Hardware tier</Text>
                  <HStack spacing={2}>
                    <Text fontWeight="semibold">{data?.tier?.name}</Text>

                    {data?.tier?.cloud === "GCP" && (
                      <Image
                        src={gcp}
                        boxSize="23px"
                        objectFit="cover"
                        alt="GCP"
                      />
                    )}
                    {data?.tier?.cloud === "AWS" && (
                      <Image
                        src={aws}
                        boxSize="35px"
                        objectFit="cover"
                        alt="AWS"
                      />
                    )}
                  </HStack>
                </GridItem>

                {/* <GridItem>
                  <Text>Container secrets</Text>
                  <Text fontWeight="semibold">0</Text>
                </GridItem> */}
                <GridItem>
                  <Text>Region</Text>
                  <Text fontWeight="semibold">{data?.region}</Text>
                </GridItem>
                <GridItem>
                  <Text>Registry credential</Text>
                  <Button
                    size={"xs"}
                    as="a"
                    onClick={() => {
                      navigate(`/api`, { state: { cluster: data } });
                    }}
                  >
                    <HStack spacing={1}>
                      <Text color="blue.500">
                        <Eye />
                      </Text>
                      <Text color="blue.500">View Keys</Text>
                    </HStack>
                  </Button>
                </GridItem>
                <GridItem>
                  <Text>Idle Timeout</Text>
                  <Text fontWeight="semibold">3600</Text>
                </GridItem>
                <GridItem>
                  <Text>OpenAI Compatible</Text>
                  <Text fontWeight="semibold">Yes</Text>
                </GridItem>
              </Grid>
              <HStack mt={0} spacing={4}>
                {["RUNNING", "SUCCESS"].includes(data?.status) && (
                  <Button
                    variant="outline"
                    border={"1px"}
                    borderColor={"brand.primary"}
                    size="sm"
                    onClick={handleOpenLogsModal}
                  >
                    Event History
                  </Button>
                )}
                {!isRunPod && ["RUNNING", "SUCCESS"].includes(data?.status) && (
                  <Button
                    variant="soft"
                    border={"1px"}
                    borderColor={"brand.primary"}
                    size="sm"
                    _hover={{ bg: "brand.secondary", color: "white" }}
                    onClick={handleRestartClick}
                  >
                    Restart Services
                  </Button>
                )}
              </HStack>
            </VStack>
          </Flex>
        </Box>
        <Modal isOpen={isModalOpenRestart} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Restart</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to Restart this cluster?
            </ModalBody>
            <ModalFooter>
              <Button
                _hover={{ bg: "brand.secondary" }}
                bg={"brand.primary"}
                color={"white"}
                mr={3}
                onClick={handleRestartServices}
              >
                Restart
              </Button>
              <Button variant="ghost" onClick={handleCloseModal}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Termination</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to terminate this cluster?
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={handleConfirmTerminate}>
                Terminate
              </Button>
              <Button variant="ghost" onClick={handleCloseModal}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={isLogsModalOpen}
          onClose={() => setIsLogsModalOpen(false)}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent maxW="70%" minH="70%">
            <ModalHeader>Cluster Logs</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Tabs variant="enclosed">
                <TabList>
                  {data?.tier?.cloud === "RunPod" ? (
                    <>
                      <Tab>General Logs</Tab>
                    </>
                  ) : (
                    <>
                      <Tab>Logs of Cluster</Tab>
                      <Tab>Logs of Container</Tab>
                      <Tab>Specific Logs of Container</Tab>
                    </>
                  )}
                </TabList>
                <TabPanels>
                  {data?.tier?.cloud === "RunPod" ? (
                    <>
                      <TabPanel>
                        <RunPodLogs
                          clusterId={data?.uuid}
                          onContainerSelect={handleContainerSelect}
                          status={data?.status}
                        />
                      </TabPanel>
                    </>
                  ) : (
                    <>
                      <TabPanel>
                        <ClusterLogs
                          clusterId={data?.uuid}
                          onContainerSelect={handleContainerSelect}
                          status={data?.status}
                        />
                      </TabPanel>
                      <TabPanel>
                        {selectedContainerId && (
                          <ContainerStreamLogs
                            clusterId={data?.uuid}
                            containerId={selectedContainerId}
                            status={data?.status}
                          />
                        )}
                      </TabPanel>
                      <TabPanel>
                        {selectedContainerId !== "" && (
                          <ContainerSpecificLogs
                            clusterId={data?.uuid}
                            containerId={selectedContainerId}
                            clusterCreatedDate={data?.createdAt}
                          />
                        )}
                      </TabPanel>
                    </>
                  )}
                </TabPanels>
              </Tabs>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setIsLogsModalOpen(false)}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          size="3xl"
          minH="70%"
          isOpen={isModalApiOpen}
          onClose={handleCloseApiModal}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create new secret key</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mt={-6} mb={3} fontSize="sm">
                The API key generated will be associated exclusively with this
                deployed model.
              </Text>
              <Input
                variant="filled"
                placeholder="Enter API Key Name"
                value={keyName}
                onChange={(e) => setKeyName(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                mr={3}
                onClick={handleGenerateApiKey}
                isLoading={loading}
                color={"white"}
                bg={"brand.primary"}
                _hover={{ bg: "brand.secondary" }}
              >
                Generate
              </Button>
              <Button variant="ghost" onClick={handleCloseApiModal}>
                Cancel
              </Button>
            </ModalFooter>
            {generatedApiKey && (
              <Alert status="success" mt={1}>
                <VStack>
                  <AlertTitle mr={2}>API Key Generated</AlertTitle>
                  <AlertDescription>
                    Please save this secret key somewhere safe and accessible.
                    For security reasons, you won't be able to view it again
                    through your NextAI account. If you lose this secret key,
                    you'll need to generate a new one. This key will only be
                    used for this cluster.
                  </AlertDescription>
                  <HStack mt={2}>
                    <Text fontWeight="bold" flex="1">
                      {generatedApiKey}
                    </Text>
                    <IconButton
                      icon={<CopyIcon />}
                      aria-label="Copy API Key"
                      onClick={() => {
                        copyToClipboard(generatedApiKey);
                      }}
                      cursor="pointer"
                      _hover={{ color: "brand.secondary" }}
                    />
                  </HStack>
                </VStack>
              </Alert>
            )}
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
}
