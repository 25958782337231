import { createSlice } from "@reduxjs/toolkit";
import { getSpecificClusterAsync } from "./clusterAction";

const singleClusterSlice = createSlice({
  name: "singleCluster",
  initialState: {
    cluster: null,
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    resetCluster: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
      state.cluster = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSpecificClusterAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSpecificClusterAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.cluster = action.payload;
        state.success = true;
      })
      .addCase(getSpecificClusterAsync.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.cluster = null;
        state.error = action.payload;
      });
  },
});

export const { resetCluster } = singleClusterSlice.actions;

export default singleClusterSlice.reducer;
