import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Stat, StatLabel, StatNumber, StatGroup } from "@chakra-ui/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function TokensUsageChart({ chartData }) {
  const sortedChartData = chartData.sort((a, b) => new Date(a.date) - new Date(b.date));
  const totalTokenUsage = sortedChartData.reduce((acc, curr) => acc + curr.total_tokens, 0);
  const totalPromptTokens = sortedChartData.reduce((acc, curr) => acc + curr.prompt_tokens, 0);
  const totalCompletionTokens = sortedChartData.reduce((acc, curr) => acc + curr.completion_tokens, 0);

  const options = {
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: sortedChartData.map((item) => item.date),
    datasets: [
      {
        label: "Total Tokens",
        data: sortedChartData.map((item) => item.total_tokens),
        backgroundColor: "#33b1ff",
      },
      {
        label: "Prompt Tokens",
        data: sortedChartData.map((item) => item.prompt_tokens),
        backgroundColor: "#0f62fe",
      },
      {
        label: "Completion Tokens",
        data: sortedChartData.map((item) => item.completion_tokens),
        backgroundColor: "#d4bbff",
      },
    ],
  };

  return (
    <>
      <StatGroup mb={4}>
        <Stat>
          <StatLabel>Total Tokens</StatLabel>
          <StatNumber>{totalTokenUsage}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Prompt Tokens</StatLabel>
          <StatNumber>{totalPromptTokens}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Completion Tokens</StatLabel>
          <StatNumber>{totalCompletionTokens}</StatNumber>
        </Stat>
      </StatGroup>
      <Bar options={options} data={data} />
    </>
  );
}
