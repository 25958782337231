import React from "react";
import Sidebar from "../sidebar/Sidebar";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loading from "../../pages/Loading/Loading";

function Layout({ children }) {
  const user = useSelector((state) => state.auth.user);
  return user ? (
    <Sidebar>{children}</Sidebar>
  ) : localStorage.getItem("access_token") ? (
    <Loading />
  ) : (
    <Navigate to="/login" />
  );
}

export default Layout;
