import axios from "axios";
const url = process.env.REACT_APP_API_URL;

export const getAllPaymentMethodsApi = async (thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v1/payments/payment-methods`, {
      headers: {
        authorization: `Bearer ${thunkAPI.getState().auth.access_token}`,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const getSpecificPaymentMethodApi = async (methodId, thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v1/payments/payments-methods/${methodId}`, {
      headers: {
        authorization: `Bearer ${thunkAPI.getState().auth.access_token}`,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const createPaymentSetupTokenApi = async (thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v1/payments/payment-methods/setup`, {
      headers: {
        authorization: `Bearer ${thunkAPI.getState().auth.access_token}`,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const createPaymentMethodApi = async (paymentData, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/v1/payments/payment-methods`, paymentData, {
      headers: {
        authorization: `Bearer ${thunkAPI.getState().auth.access_token}`,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};