import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const theme = extendTheme({
  colors: {
    brand: {
      ...colors,
    },
  },
  fonts: {
    body: "Montserrat",
    heading: "Montserrat",
  },
  config
});

export default theme;
