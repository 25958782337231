import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
  Card as ChakraCard,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
function Card({
  icon,
  title,
  description,
  buttonText1,
  buttonText2,
  href1,
  href2,
  openHref1InNewTab,
  openHref2InNewTab,
}) {
  return (
    <ChakraCard
      bg={useColorModeValue("brand.textPrimaryDark", "gray.800")}
      p={6}
      rounded={"2xl"}
      variant='outline'
      w={{ base: "full", lg: "calc(50% - 3rem)" }}
      _hover={{
        boxShadow: "lg",
      }}
    >
      <CardHeader>
        <Heading size="md" display={"flex"} alignItems={"start"}>
          <Icon
            mr={2}
            color={useColorModeValue("brand.primary", "brand.primaryDark")}
            fontSize={"2xl"}
          >
            {icon}
          </Icon>
          {title}
        </Heading>
      </CardHeader>
      <CardBody>
        <Text>{description}</Text>
      </CardBody>
      <CardFooter display={"flex"} justifyContent={"start"} gap={2}>
        {buttonText1 &&
          (openHref1InNewTab ? (
            <ChakraLink
              as={ReactRouterLink}
              to={href1}
              textDecoration={"none"}
              _hover={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                bg={"brand.primary"}
                color={"white"}
                rounded={"xl"}
                _hover={{
                  bg: "brand.secondary",
                }}
              >
                {buttonText1}
              </Button>
            </ChakraLink>
          ) : (
            <ChakraLink
              as={ReactRouterLink}
              to={href1}
              textDecoration={"none"}
              _hover={{ textDecoration: "none" }}
            >
              <Button
                bg={"brand.primary"}
                color={"white"}
                rounded={"xl"}
                _hover={{
                  bg: "brand.secondary",
                }}
              >
                {buttonText1}
              </Button>
            </ChakraLink>
          ))}
        {buttonText2 && (
          <ChakraLink
            as={ReactRouterLink}
            to={href2}
            textDecoration={"none"}
            _hover={{ textDecoration: "none" }}
          >
            <Button
              bg={"brand.primary"}
              color={"white"}
              rounded={"xl"}
              _hover={{
                bg: "brand.secondary",
              }}
            >
              {buttonText2}
            </Button>
          </ChakraLink>
        )}
      </CardFooter>
    </ChakraCard>
  );
}

export default Card;
