import { createSlice } from '@reduxjs/toolkit';
import { createFineTuningJobAsync, fetchAllFineTuningJobsAsync, fetchFineTuningJobAsync } from './finetuneAction';

const fineTuningSlice = createSlice({
    name: 'fineTuning',
    initialState: {
        jobs: [],
        loading: false,
        error: null,
    },
    reducers: {
        resetFineTuning: (state) => {
            state.jobs = [];
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createFineTuningJobAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(createFineTuningJobAsync.fulfilled, (state, action) => {
                state.jobs.push(action.payload);
                state.loading = false;
            })
            .addCase(createFineTuningJobAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchAllFineTuningJobsAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllFineTuningJobsAsync.fulfilled, (state, action) => {
                state.jobs = action.payload;
                state.loading = false;
            })
            .addCase(fetchAllFineTuningJobsAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchFineTuningJobAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFineTuningJobAsync.fulfilled, (state, action) => {
                state.loading = false;
                const jobIndex = state.jobs.findIndex(job => job.uuid === action.meta.arg);
                if (jobIndex !== -1) {
                    state.jobs[jobIndex] = action.payload;
                } else {
                    state.jobs.push(action.payload);
                }
            })
            .addCase(fetchFineTuningJobAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { resetFineTuning } = fineTuningSlice.actions;
export default fineTuningSlice.reducer;
