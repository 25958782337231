import {
  Box,
  Button,
  Container,
  Flex,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import WhiteLogo from "../../assets/images/WhiteLogo.png";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";

const PasswordReset = ({ tokenParam }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/forgot-password/validate`,
        {
          token: tokenParam,
          password,
        }
      );

      toast({
        title: "Success",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.response.data.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      navigate("/login");
    }
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      h={"100vh"}
    >
      <Flex flex={1} w={"100%"} justifyContent={"center"} alignItems={"center"}>
        <ChakraLink as={ReactRouterLink} to="/">
          <Image w={52} src={WhiteLogo} />
        </ChakraLink>
      </Flex>
      <Flex alignItems="center" justifyContent="center" flex={1} w={"100%"}>
        <Box p={8} borderRadius={8} bg={useColorModeValue("white", "gray.800")}>
          <Heading mb={4} textAlign="center">
            Reset Password
          </Heading>

          <FormLabel>Password</FormLabel>
          <InputGroup size="md">
            <Input
              mb={4}
              type={showPassword ? "text" : "password"}
              placeholder="New Password"
              value={password}
              onChange={handlePasswordChange}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
            />
            <InputRightElement width="4.5rem">
              <Button
                h="1.75rem"
                size="sm"
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                {showPassword ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormLabel>Confirm Password</FormLabel>
          <Input
            mb={6}
            type="text"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          <Button
            isLoading={loading}
            loadingText="Resetting Password"
            onClick={handleResetPassword}
            isDisabled={password !== confirmPassword || password === ""}
          >
            Reset Password
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default PasswordReset;
