import { createAsyncThunk } from "@reduxjs/toolkit";
import { getModels, searchModels } from "../../api/modelsApi";

export const getModelsAsync = createAsyncThunk(
  "models/getModels",
  async ({ page, size },  thunkAPI) => {
    const response = await getModels(page, size, thunkAPI);
    return response;
  }
);

export const getSearchAsync = createAsyncThunk(
  "models/getSearch",
  async ( keyword ,  thunkAPI) => {
    const response = await searchModels(keyword, thunkAPI);
    return response;
  }
);