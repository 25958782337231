import React, { useState, useEffect } from "react";
import { MoreVertical, PlusCircle, Trash2 } from "lucide-react";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
  Card,
  useColorModeValue,
  Box,
  HStack,
  Tooltip,
  Flex,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../loadingModal/LoadingModal";
import { FaPersonRunning } from "react-icons/fa6";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import { archiveClusterAsync } from "../../features/clusters/clusterAction";

function ModelsCard({ data }) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { loading } = useSelector((state) => state.cluster);
  const dispatch = useDispatch();

  const handleArchive = (clusterId) => {
    dispatch(archiveClusterAsync(clusterId));
  };

  let displayStatus = data?.status;

  if (data?.status === "SUCCESS") {
    displayStatus = "RUNNING";
  }

  let statusColor;
  switch (data?.status) {
    case "INITIATED":
      statusColor = "blue.500";
      break;
    case "IN PROGRESS":
      statusColor = "yellow.500";
      break;
    case "SUCCESS":
      statusColor = "green.500";
      break;
    case "RUNNING":
      statusColor = "green.500";
      break;
    case "STOPPED":
      statusColor = "orange.500";
      break;
    case "ERRORED":
      statusColor = "red.500";
      break;
    case "TERMINATED":
      statusColor = "red.500";
      break;
    case "FAILED_T0_START":
      statusColor = "gray.500";
      break;
    case "FAILED_T0_TERMINATE":
      statusColor = "gray.500";
      break;
    default:
      statusColor = "blue.500";
      break;
  }

  const validStatuses = [
    "RUNNING",
    "SUCCESS",
    "STOPPED",
    "TERMINATED",
    "FAILED_T0_START",
    "FAILED_T0_TERMINATE",
    "ERRORED",
  ];

  return (
    <>
      <HStack>
        <Card
          bg={useColorModeValue("brand.modalCard", "brand.modalCardDark")}
          boxShadow={"xs"}
          p={2}
          mb={2}
          rounded={"2xl"}
          w={"full"}
          _hover={{
            boxShadow: "xl",
          }}
          minH={"300px"}
          minW={"350px"}
          maxW={"350px"}
        >
          <CardHeader mb={-10} pb={0}>
            <Heading
              size="md"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={1}
              pb={0}
              mb={0}
            >
              <Box>{data?.name}</Box>
              <Flex alignItems="center" mt={2}>
                <HStack>
                  <Box
                    bg={statusColor}
                    rounded="full"
                    px={4}
                    color="brand.textPrimaryDark"
                    opacity={0.8}
                    py={1}
                    textAlign="center"
                    fontSize={'xs'}
                  >
                    {displayStatus}
                  </Box>
                  {!validStatuses.includes(data?.status) && (
                    <Spinner size="xs" />
                  )}
                </HStack>
                {/* <Box width="25px" marginLeft="2px">
                  <HStack>
                    {loading && !validStatuses.includes(data?.status) && (
                      <Tooltip label="Running" fontSize="md">
                        <FaPersonRunning color="brand.primary" />
                      </Tooltip>
                    )}
                    {!validStatuses.includes(data?.status) && (
                      <Spinner />
                    )}
                  </HStack>
                </Box> */}
              </Flex>
            </Heading>
            {[
              "TERMINATED",
              "ERRORED",
              "FAILED_TO_TERMINATE",
              "FAILED_TO_START",
            ].includes(data?.status) && (
              <Menu>
                <MenuButton
                  aria-label="Options"
                  position="absolute"
                  top={7}
                  right={2}
                >
                  <MoreVertical size={19} />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    icon={<PlusCircle size={18} />}
                    onClick={() => handleArchive(data?.uuid)}
                  >
                    Archive
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </CardHeader>
          <CardBody
            display={"flex"}
            justifyContent={"center"}
            gap={2}
            flexDirection={"column"}
            alignItems={"center"}
            mt={10}
            mb={0}
            textAlign={'center'}
          >
            <Text fontSize={"sm"}>Model: {data?.model?.name}</Text>
            <Text fontSize={"sm"}>
              Tier: {data?.tier?.configuration.cpu.description}
            </Text>
            <Text fontSize={"sm"}>Nodes: {data?.nodes}</Text>
          </CardBody>

          <CardFooter display={"flex"} justifyContent={"start"} gap={2} mt={-2}>
            <ChakraLink
              as={ReactRouterLink}
              to={`/deploy/${data?.uuid}`}
              w={"full"}
            >
              <Button
                variant="outline"
                w={"full"}
                border={"1px"}
                borderColor={"brand.primary"}
              >
                <Text fontSize={"md"}>View Details</Text>
              </Button>
            </ChakraLink>
          </CardFooter>
        </Card>

        {isModalOpen && (
          <LoadingModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            data={data}
          />
        )}
      </HStack>
    </>
  );
}

export default ModelsCard;
