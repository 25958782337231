// parseJsonl.js
export const parseJsonl = (file, onSuccess, onError) => {
    const reader = new FileReader();
    
    reader.onload = (e) => {
      const text = e.target.result;
      const lines = text.trim().split('\n');
      const jsonObjects = lines.map(line => JSON.parse(line));
      
      // Here you can extract any specific information you need
      // For demonstration, let's count the objects
      const numObjects = jsonObjects.length;
  
      // Call the onSuccess callback with the parsed data
      onSuccess({ count: numObjects });
    };
  
    reader.onerror = () => {
      // Call the onError callback if reading the file fails
      onError(reader.error);
    };
  
    reader.readAsText(file);
  };
  