import React from 'react';
import { Box, IconButton, Flex, useClipboard } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';
import { CopyBlock, dracula } from 'react-code-blocks';

const CodeWithCopyButton = ({ code }) => {
  const { hasCopied, onCopy } = useClipboard(code);

  return (
    <Flex direction="column" position="relative" alignItems="left">
      <CopyBlock
        text={code}
        language="bash"
        showLineNumbers={false}
        theme={dracula}
        wrapLines
        codeBlock
      />
      
    </Flex>
  );
};

const App = ({ data }) => {
  const codeSnippet = `curl https://${data?.endpoint}/v1/embeddings
  -H "Content-Type: application/json" \
  -d '{
    "model": "${data?.model?.name}",
    "input": "Hello world!"
  }'
`;

  return (
    <Box p={4}>
      <CodeWithCopyButton code={codeSnippet} />
    </Box>
  );
};

export default App;
