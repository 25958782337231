import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Spinner,
  IconButton,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { CircleDollarSign, Loader2 } from 'lucide-react';

function HistoryActivity() {
  const [selectedDate, setSelectedDate] = useState(null); 
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const theadBgColor = useColorModeValue('blue.50', 'blue.900');

  useEffect(() => {
    fetchTransactions(); 
  }, [selectedDate]);

  const fetchTransactions = async () => {
    setIsLoading(true);
    try {
      const authToken = localStorage.getItem('access_token');
      let url = `${process.env.REACT_APP_API_URL}/users/transactions/`;

      if (selectedDate) {
        const selectedDateISO = selectedDate.toISOString().split('T')[0];
        url += `?from=${selectedDateISO}`;
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const filteredTransactions = response.data.response.filter(
        (transaction) => transaction.amount > 0
      );

      setTransactions(filteredTransactions.reverse());
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    fetchTransactions(); 
  };

  return (
    <Box mt={10} mb={10} p={5} borderWidth="1px" borderRadius="lg" bg={bgColor} borderColor={borderColor}>
      <Flex justifyContent="space-between" alignItems="center" mb={5}>
        <Heading size="lg">Billing History</Heading>
        <Box>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            maxDate={new Date()}
            dateFormat="MMMM d, yyyy"
            wrapperClassName="date-picker"
            customInput={
              <IconButton
                aria-label="Select date"
                icon={<ChevronLeftIcon />}
                mr={2}
              />
            }
          />
          <Text display="inline-block" fontSize="lg" fontWeight="bold">
            {selectedDate ? selectedDate.toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' }) : 'Select a Date'}
          </Text>
          <IconButton
            aria-label="Next date"
            icon={<ChevronRightIcon />}
            ml={2}
            onClick={() => setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)))}
            isDisabled={!selectedDate || selectedDate >= new Date()}
          />
        </Box>
      </Flex>

      {isLoading ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : (
        <Table variant='simple' colorScheme="blue">
          <Thead bg={theadBgColor}>
            <Tr>
              <Th>Description</Th>
              <Th>Type</Th>
              <Th isNumeric>Amount</Th>
              <Th>Status</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions.length === 0 ? (
              <Tr>
                <Td colSpan={5} textAlign="center">No transactions found</Td>
              </Tr>
            ) : (
              transactions.map((transaction) => (
                <Tr key={transaction._id}>
                  <Td>{transaction.description}</Td>
                  <Td>{transaction.type}</Td>
                  <Td isNumeric>
                    <Tag colorScheme="green">
                      ${transaction.amount}
                    </Tag>
                  </Td>
                  <Td>
                    {transaction.status === 'processing' ? (
                      <Flex align="center">
                        <Loader2 size={16} color="orange" />
                        <Box ml={2}>Processing</Box>
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <CircleDollarSign size={16} color="green" />
                        <Box ml={2}>Success</Box>
                      </Flex>
                    )}
                  </Td>
                  <Td>{new Date(transaction.createdAt).toLocaleDateString()}</Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

export default HistoryActivity;
