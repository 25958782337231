import { Modal, ModalOverlay, ModalContent, useColorModeValue, ModalHeader, ModalBody, ModalFooter, Button, Text, useClipboard, Box, IconButton, HStack, Spacer } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { bg } from 'date-fns/locale';

const ApiKeyModal = ({ isOpen, onClose, apiKey }) => {
  const { hasCopied, onCopy } = useClipboard(apiKey);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold">New User Specific API Key</ModalHeader>
        <ModalBody>
          <Text mb={1} fontSize="md">Your new API key:</Text>
          <Text mb={4} fontSize="sm">
            Please save this secret key somewhere safe and accessible. For security reasons, 
            you won't be able to view it again through your NextAI account. If you lose this 
            secret key, you'll need to generate a new one.
          </Text>
          <Box d="flex" alignItems="center" justifyContent="space-between" p={3} borderWidth="1px" 
               borderRadius="md" borderColor="gray.300" bg={useColorModeValue("brand.textPrimaryDark", "gray.800")}>
            <HStack>
            <Text maxWidth="85%" fontSize="md">{apiKey}</Text>
            <Spacer/>
            <IconButton 
              aria-label="Copy API key" 
              icon={<CopyIcon />} 
              onClick={onCopy} 
              ml={2} 
              size="sm" 
              bg={'brand.primary'}
              colorScheme={hasCopied ? "green" : "blue"}
              title={hasCopied ? "Copied!" : "Copy API key"}
            />
            </HStack>
          </Box>
          {hasCopied ? <Text color="green.500" mt={2}>Copied to clipboard!</Text> : null}
        </ModalBody>
        <ModalFooter>
          <Button color={'white'} bg={'brand.primary'} _hover={{ bg: "brand.secondary" }} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ApiKeyModal;
