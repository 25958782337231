import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setModel,
  setMaxTokens,
  setTemperature,
  setPresencePenalty,
  setTopP,
} from "../../features/playground/playgroundSlice";
import {
  Box,
  VStack,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Select
} from "@chakra-ui/react";
import LabeledSlider from "./Slider";

const PlaygroundSidebar = () => {
  const dispatch = useDispatch();
  const { model, max_tokens, temperature, presence_penalty, top_p } =
    useSelector((state) => state.playground);

  React.useEffect(() => {
    dispatch(setModel("nextai-team/neptune-inst-v1"));
  }, [dispatch]);

  const modelOptions = {
    "nextai-team/neptune-inst-v1": {
      cost: {
        input: 0.0003,
        output: 0.0007
      }
    }
  };

  const handleInputChange = (key, value) => {
    switch (key) {
      case "max_tokens":
        dispatch(setMaxTokens(value));
        break;
      case "temperature":
        dispatch(setTemperature(value));
        break;
      case "presence_penalty":
        dispatch(setPresencePenalty(value));
        break;
      case "top_p":
        dispatch(setTopP(value));
        break;
      default:
        break;
    }
  };

  return (
    <Box ml={-10} w="300px" borderRight="1px" borderColor="gray.200" p={4}>
      <VStack align="stretch" spacing={4}>
        <Card>
          <CardHeader mb={0}>
            <Heading mb={0} size="md">
              Model settings
            </Heading>
          </CardHeader>
          <CardBody>
            <Select
              variant="filled"
              mt={-8}
              mb={2}
              value={model}
              onChange={(e) => handleInputChange("model", e.target.value)}
            >
              <option value="nextai-team/neptune-inst-v1">NextAI Neptune</option>
            </Select>
            <Stat>
              <StatLabel>Temperature</StatLabel>
              <StatNumber>{temperature.toFixed(1)}</StatNumber>
              <Slider
                aria-label="temperature-slider"
                value={temperature}
                min={0}
                max={1}
                step={0.1}
                onChange={(val) => handleInputChange("temperature", val)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Stat>

            <Stat>
              <StatLabel>Max tokens</StatLabel>
              <StatNumber>{max_tokens}</StatNumber>
              <Slider
                aria-label="max-tokens-slider"
                value={max_tokens}
                min={1}
                max={4000}
                step={1}
                onChange={(val) => handleInputChange("max_tokens", val)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Stat>

            <Stat>
              <StatLabel>Top P</StatLabel>
              <StatNumber>{top_p}</StatNumber>
              <Slider
                aria-label="max-tokens-slider"
                value={top_p}
                min={0}
                max={1}
                step={0.1}
                onChange={(val) => handleInputChange("top_p", val)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Stat>
          </CardBody>
        </Card>
        <Card>
          <CardHeader mb={0}>
            <Heading mb={-20} size="md">
              Cost
            </Heading>
          </CardHeader>
          <CardBody>
            <Stat mb={2}>
              <StatLabel>Price per 1000 input tokens</StatLabel>
              <StatNumber>${modelOptions[model]?.cost?.input}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Price per 1000 output tokens</StatLabel>
              <StatNumber>${modelOptions[model]?.cost?.output}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Max token length</StatLabel>
              <StatNumber>4,096</StatNumber>
            </Stat>
          </CardBody>
        </Card>
      </VStack>
    </Box>
  );
};

export default PlaygroundSidebar;
