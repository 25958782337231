import { Box, Container } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSpecificClusterAsync } from "../../features/clusters/clusterAction";
import DeployPage from "../../components/deploy/Deploy";
import EndCard from "../../components/deploy/EndCard";
import Tab from "../../components/deploy/Tabs";

const Deploy = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.singleCluster.cluster);
  const loading = useSelector((state) => state.singleCluster.loading);
  
  useEffect(() => {
    dispatch(getSpecificClusterAsync(params.clusterUUID));
  }, [dispatch, params.clusterUUID]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!loading) {
        dispatch(getSpecificClusterAsync(params.clusterUUID));
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [dispatch, loading, params.clusterUUID]);

  return (
    <Box>
      <Container>
        <Box display="flex">
          <Box display="flex" flexDirection="column">
            <DeployPage data={data} />
            <EndCard data={data} />
            <Tab data={data} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Deploy;
