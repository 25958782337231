import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Text,
  TableContainer,
  useColorModeValue,
  HStack,
  Spinner,
  Spacer,
} from "@chakra-ui/react";
import { fetchClusterLogsAsync } from "../../features/clusters/clusterAction";

function ContainerSpecificLogs({ clusterId, containerId, clusterCreatedDate }) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const logsString = useSelector((state) => state.cluster.logs);
  const [parsedLogs, setParsedLogs] = useState([]);

  const fetchLogs = async () => {
    setLoading(true);
    const fromTimestamp = selectedDate.getTime();
    await dispatch(
      fetchClusterLogsAsync({ clusterId, containerId, fromTimestamp })
    );
    setLoading(false);
  };

  const clusterCreationDate = new Date(clusterCreatedDate);

  React.useEffect(() => {
    if (typeof logsString === "string") {
      try {
        const logsArray = logsString
          .trim()
          .split("\n")
          .map((log) => {
            try {
              return JSON.parse(log);
            } catch (error) {
              return null;
            }
          })
          .filter((log) => log !== null);

        setParsedLogs(logsArray);
      } catch (error) {
        // console.error("Error processing logsString:", error);
      }
    } else {
      // console.error("Invalid logsString:", logsString);
    }
  }, [logsString]);

  const colors = {
    primary: "#4E4FEB",
    secondary: "#068FFF",
    background: "#F7FAFC",
    border: "#EDF2F7",
    textPrimary: "#171923",
    textSecondary: "#2D3748",
    modalCard: "#FFFFFF",
    primaryDark: "#4E4FEB",
    secondaryDark: "#0063CC",
    backgroundDark: "#171923",
    borderDark: "#1A202C",
    textPrimaryDark: "#F7FAFC",
    textSecondaryDark: "#E2E8F0",
    modalCardDark: "#0F121F",
  };

  const bg = useColorModeValue(colors.background, colors.backgroundDark);
  const borderColor = useColorModeValue(colors.border, colors.borderDark);
  const textColor = useColorModeValue(
    colors.textPrimary,
    colors.textPrimaryDark
  );
  const cardBg = useColorModeValue(colors.modalCard, colors.modalCardDark);

  const renderLogBadge = (level) => {
    const colorScheme =
      level === "info" ? "blue" : level === "error" ? "red" : "gray";
    return <Badge colorScheme={colorScheme}>{level.toUpperCase()}</Badge>;
  };

  return (
    <Box
      p={5}
      bg={bg}
      boxShadow="sm"
      rounded="lg"
      borderColor={borderColor}
      borderWidth="1px"
    >
      <VStack spacing={4}>
        <HStack width="100%">
          <FormControl flex="1">
            <FormLabel htmlFor="date-picker" color={textColor}>
              Select From Date:
            </FormLabel>
            <DatePicker
              id="date-picker"
              selected={selectedDate}
              onChange={setSelectedDate}
              maxDate={new Date()}
              minDate={clusterCreationDate}
              className="date-picker"
            />
          </FormControl>
          <Spacer />
          <Button
            _hover={{
              bg: "brand.secondary",
            }}
            color={"white"}
            bg={"brand.primary"}
            onClick={fetchLogs}
          >
            {loading ? <Spinner color="white" size="sm" /> : "Fetch Logs"}
          </Button>
          <Spacer />
          <StatGroup flex="1" justifyContent="flex-end">
            <Stat>
              <StatLabel color={textColor}>Total Logs</StatLabel>
              <StatNumber color={textColor}>{parsedLogs.length}</StatNumber>
            </Stat>
          </StatGroup>
        </HStack>
        <TableContainer maxHeight="230px" overflowY="auto">
          <Table variant="striped" size="sm">
            <Thead bg={cardBg}>
              <Tr>
                <Th color={textColor}>Message</Th>
                {/* <Th color={textColor} isNumeric>
                  Timestamp
                </Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {parsedLogs.map((log, index) => (
                <Tr key={index}>
                  <Td>{renderLogBadge(log.l)}</Td>
                  <Td color={textColor}>{log.m}</Td>
                  {/* <Td color={textColor} isNumeric>
                    {new Date(log.ts).toLocaleString()}
                  </Td> */}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        {parsedLogs.length === 0 && (
          <Text color={textColor}>No logs to display.</Text>
        )}
      </VStack>
    </Box>
  );
}

export default ContainerSpecificLogs;
