import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL;

export const getAllServiceAccounts = async (thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(
      `${baseUrl}/users/service-accounts/`,
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const getServiceAccount = async (serviceAccountId, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(
      `${baseUrl}/users/service-accounts/${serviceAccountId}`,
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const createServiceAccountCluster = async (serviceAccountData, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.post(
      `${baseUrl}/users/service-accounts/`,
      serviceAccountData,
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const validateServiceAccount = async (serviceAccountId, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.post(
      `${baseUrl}/users/service-accounts/${serviceAccountId}/validate`,
      null,
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const deleteServiceAccount = async (serviceAccountId, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    await axios.delete(
      `${baseUrl}/users/service-accounts/${serviceAccountId}`,
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};