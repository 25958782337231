export const colors = {
  primary: "#4E4FEB",
  secondary: "#068FFF",
  background: "#F7FAFC",
  border: "#EDF2F7",
  textPrimary: "#171923",
  textSecondary: "#2D3748",
  modalCard: "#FFFFFF",
  primaryDark: "#4E4FEB",
  secondaryDark: "#0063CC",
  backgroundDark: "#171923",
  borderDark: "#1A202C",
  textPrimaryDark: "#F7FAFC",
  textSecondaryDark: "#E2E8F0",
  modalCardDark: "#0F121F",

};
