import { Flex, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

function Loading() {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  
  return (
    <Flex minH={"100vh"} minW={"100vw"} align={"center"} justify={"center"}>
      <Spinner size={"xl"} />
    </Flex>
  );
}

export default Loading;
