import React, { useState } from "react";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
  Card,
  useColorModeValue,
  Box,
  Tooltip,
  HStack,
} from "@chakra-ui/react";
import DeployModal from "../deployModal/NewDeployModel";
import { FaBolt } from "react-icons/fa6";

function ModelCard({ model }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const tag_bg_color = useColorModeValue("brand.background", "green.600");
  const tag_text_color = useColorModeValue(
    "brand.textPrimary",
    "brand.textPrimaryDark"
  );

  const handleDeployButtonClick = () => {
    setModalOpen(true);
    console.log("Deploying model to backend:", model);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Card
      bg={useColorModeValue("brand.modalCard", "brand.modalCardDark")}
      p={2}
      mb={2}
      rounded={"2xl"}
      w={"full"}
      _hover={{
        boxShadow: "lg",
      }}
      minH={"350px"}
    >
      <DeployModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        model={model}
      />
      <CardHeader mb={-10} pb={0}>
        <Heading
          size="md"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          gap={1}
          pb={0}
          mb={0}
        >
          {model?.isOpenAICompatible ? (
            <Box
              rounded={"full"}
              bg={"brand.primary"}
              py={1}
              px={3}
              fontSize={"xs"}
              textAlign={"center"}
              color={"brand.textPrimaryDark"}
              mb={2}
              fontWeight={600}
            >
              OpenAI Compatible
            </Box>
          ) : null}
          <HStack>
            <Box mb={0}>{model.displayName}</Box>
            {model?.isOpenAICompatible ? (
              <Tooltip
                hasArrow
                fontSize={"10px"}
                label="Model is optimised for fastest inference"
                bg="gray.300"
                color="black"
              >
                <Box fontSize={15} marginBottom={0} color="brand.primary">
                  <FaBolt />
                </Box>
              </Tooltip>
            ) : null}
          </HStack>
        </Heading>
      </CardHeader>
      <CardBody
        display={"flex"}
        justifyContent={"center"}
        gap={2}
        flexDirection={"column"}
        alignItems={"center"}
        mt={10}
        mb={0}
      >
        <Box display={"flex"} justifyContent={"center"} gap={2}>
          {model?.tags.map((tag, index) =>
            tag.split(",").map((t, idx) => (
              <Text
                as={"span"}
                key={`${index}-${idx}`}
                fontSize={"xs"}
                rounded={"full"}
                bg={tag_bg_color}
                color={tag_text_color}
                py={1}
                px={3}
                textAlign={"center"}
                mb={1}
                mt={0}
                fontWeight={600}
              >
                {`${t} `}
              </Text>
            ))
          )}
        </Box>
        <Box>
          <Text py={2} textAlign={"center"}>
            {model?.description}
          </Text>
        </Box>
      </CardBody>
      <CardFooter display={"flex"} justifyContent={"start"} gap={2} mt={-2}>
        {model?.deployable ? (
          <Button
            flex="1"
            variant="outline"
            border={"1px"}
            borderColor={"brand.primary"}
            onClick={handleDeployButtonClick}
          >
            Deploy
          </Button>
        ) : null}
        {model?.runnable ? (
          <Button
            flex="1"
            variant="outline"
            border={"1px"}
            borderColor={"brand.primary"}
          >
            Playground
          </Button>
        ) : null}
      </CardFooter>
    </Card>
  );
}

export default ModelCard;
