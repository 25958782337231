import { createSlice } from "@reduxjs/toolkit";
import { createUserApiKeyAsync, getUserApiKeysAsync, revokeUserApiKeyAsync } from "./userApiActions";

const userApiKeySlice = createSlice({
  name: "userApi",
  initialState: {
    userApiKeys: [],
    loading: false,
    error: null,
    newApiKey: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUserApiKeyAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUserApiKeyAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.userApiKeys.push(action.payload);
        state.newApiKey = action.payload.key;
      })
      .addCase(createUserApiKeyAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUserApiKeysAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserApiKeysAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.userApiKeys = action.payload;
      })
      .addCase(getUserApiKeysAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(revokeUserApiKeyAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(revokeUserApiKeyAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.userApiKeys = state.userApiKeys.filter(key => key.uuid !== action.meta.arg);
      })
      .addCase(revokeUserApiKeyAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userApiKeySlice.reducer;
