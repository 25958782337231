import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Checkbox,
  Text,
  Stack,
  Heading,
  useToast,
  Grid,
} from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAsync, registerAsync } from "../../features/auth/authActions";
import { logout } from "../../features/auth/authSlice";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  agreeToTerms: Yup.boolean()
    .oneOf([true], "Please agree to the terms and conditions")
    .required("You must agree to the terms and conditions"),
  company: Yup.string().required("Company is required"),
});

const RegisterForm = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const { error, success, message, loading, access_token, user } = useSelector(
    (state) => state.auth
  );
  const handleSignUp = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    dispatch(registerAsync(values));
    resetForm();

    setSubmitting(false);
  };

  useEffect(() => {
    if (success) {
      toast({
        title: "Sign-up Successful",
        description: "Please check your email for verification",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      dispatch(logout());
    }
    if (error) {
      toast({
        title: `Sign up Failed ${message.statusCode}`,
        description: message.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [success, error, message, toast]);

  return (
    <Box>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          agreeToTerms: false,
          company: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSignUp}
      >
        <Form>
          <Stack spacing={4}>
            <Heading fontSize={"2xl"} mb={10}>
              Sign up for an account
            </Heading>
            <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }} gap={4}>
              <Field name="firstName">
                {({ field }) => (
                  <FormControl
                    id="firstName"
                    isInvalid={field.touched && field.error}
                  >
                    <FormLabel>First Name</FormLabel>
                    <Input {...field} />
                    <ErrorMessage
                      name="firstName"
                      component={Text}
                      color="red.500"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="lastName">
                {({ field }) => (
                  <FormControl
                    id="lastName"
                    isInvalid={field.touched && field.error}
                  >
                    <FormLabel>Last Name</FormLabel>
                    <Input {...field} />
                    <ErrorMessage
                      name="lastName"
                      component={Text}
                      color="red.500"
                    />
                  </FormControl>
                )}
              </Field>
            </Grid>
            <Field name="email">
              {({ field }) => (
                <FormControl
                  id="email"
                  isInvalid={field.touched && field.error}
                >
                  <FormLabel>Email address</FormLabel>
                  <Input {...field} type="email" />
                  <ErrorMessage name="email" component={Text} color="red.500" />
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({ field }) => (
                <FormControl
                  id="password"
                  isInvalid={field.touched && field.error}
                >
                  <FormLabel>Password</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      pr="4.5rem"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={togglePasswordVisibility}
                        aria-label={
                          showPassword ? "Hide password" : "Show password"
                        }
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <ErrorMessage
                    name="password"
                    component={Text}
                    color="red.500"
                  />
                </FormControl>
              )}
            </Field>
            <Field name="company">
              {({ field }) => (
                <FormControl
                  id="company"
                  isInvalid={field.touched && field.error}
                >
                  <FormLabel>Company</FormLabel>
                  <Input {...field} />
                  <ErrorMessage
                    name="company"
                    component={Text}
                    color="red.500"
                  />
                </FormControl>
              )}
            </Field>
            <Field name="agreeToTerms">
              {({ field }) => (
                <FormControl isInvalid={field.touched && field.error}>
                  <Checkbox {...field}>
                    Agree to{" "}
                    <a
                      href="https://arrow-gambler-092.notion.site/Terms-of-Service-2a1b0c4329174520bc81341f440591f0?pvs=4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms and conditions
                    </a>
                  </Checkbox>
                  <ErrorMessage
                    name="agreeToTerms"
                    component={Text}
                    color="red.500"
                  />
                </FormControl>
              )}
            </Field>

            <Button
              type="submit"
              colorScheme="blue"
              variant="outline"
              isLoading={loading}
            >
              Sign up
            </Button>
          </Stack>
        </Form>
      </Formik>
      <Box mt={4}>
        <ChakraLink as={ReactRouterLink} to="/login">
          Already have an account?{" "}
          <Text as="span" color={"brand.secondary"} fontWeight={"semibold"}>
            Sign in
          </Text>
        </ChakraLink>
      </Box>
    </Box>
  );
};

export default RegisterForm;
