import React, { useEffect, useState } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { GoVerified } from "react-icons/go";
import {XCircle, CheckCircle , Loader} from 'lucide-react';

const urlz = process.env.REACT_APP_API_URL;

const HealthStatus = ({ clusterId }) => {
  const [healthMessage, setHealthMessage] = useState(''); 

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const healthUrl = `${urlz}/clusters/v1/${clusterId}/health/model/`;

    const eventSource = new EventSourcePolyfill(healthUrl, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    eventSource.onmessage = (e) => {
      const receivedMessage = e.data.trim();
      setHealthMessage(receivedMessage);
    };

    eventSource.onerror = (err) => {
      const errorMessage = 'Error: Connection to the server failed.';
      setHealthMessage(errorMessage);
    };

    return () => {
      eventSource.close();
    };
  }, [clusterId]);

  return (
    <div>
      {healthMessage === 'OK' ? (
        <HStack spacing={1}>
        <Text fontWeight={600} color="green">
          <CheckCircle size={22}/>
        </Text>
        <Text fontSize={14} fontWeight={700} color="green">
          Running
        </Text>
        </HStack>
      ) : (
        <HStack spacing={1}>
        <Text fontWeight={600} color="blue.400">
          <Loader size={22} />
        </Text>
        <Text fontWeight={700} color="blue.400">
          Loading 
        </Text>
        </HStack>
      )}
    </div>
  );
};

export default HealthStatus;
