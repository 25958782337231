
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Text, Input, Button, useToast, Divider } from "@chakra-ui/react";
import {
  updateUserEmailAsync,
  updateUserFirstNameAsync,
  updateUserLastNameAsync,
  updateUserPasswordAsync,
} from "../../features/auth/authActions";
import { resetUpdateUser } from "../../features/auth/authSlice";

const SettingsForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const toast = useToast();
  const { success, error, message, loading } = useSelector(
    (state) => state.auth.updateUser
  );
  useEffect(() => {
    if (success && user) {
      toast({
        title: message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      dispatch(resetUpdateUser());
    }
    if (error) {
      toast({
        title: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(resetUpdateUser());
    }
  }, [message, toast, success, error]);
  const [editing, setEditing] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  });
  const [editedUser, setEditedUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    currentPassword: "", // Add currentPassword field
    newPassword: "", // Add newPassword field
  });

  useEffect(() => {
    setEditedUser(user);
  }, [user]);

  const handleEdit = (field) => {
    setEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleSave = (field) => {
    switch (field) {
      case "firstName":
        dispatch(updateUserFirstNameAsync(editedUser));
        break;
      case "lastName":
        dispatch(updateUserLastNameAsync(editedUser));
        break;
      case "email":
        dispatch(updateUserEmailAsync(editedUser));
        break;
      case "password":
        console.log(editedUser);
        dispatch(updateUserPasswordAsync(editedUser));
        break;
      default:
        break;
    }

    setEditing({
      firstName: false,
      lastName: false,
      email: false,
      password: false,
    });
  };

  const handleCancel = () => {
    setEditing({
      firstName: false,
      lastName: false,
      email: false,
      password: false,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setEditedUser((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Box>
      <Box>
        <Text mb={2}>First Name:</Text>
        {editing.firstName ? (
          <Input
            name="firstName"
            value={editedUser.firstName}
            onChange={handleChange}
            mb={2}
          />
        ) : (
          <Input value={user.firstName} isDisabled mb={2} />
        )}
        {editing.firstName ? (
          <>
            <Button
              variant={"outline"}
              colorScheme="blue"
              onClick={() => handleSave("firstName")}
            >
              Save
            </Button>
            <Button
              variant={"outline"}
              colorScheme="red"
              onClick={handleCancel}
              ml={2}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button variant={"outline"} onClick={() => handleEdit("firstName")}>
            Edit
          </Button>
        )}
      </Box>

      <Box mt={4}>
        <Text mb={2}>Last Name:</Text>
        {editing.lastName ? (
          <Input
            name="lastName"
            value={editedUser.lastName}
            onChange={handleChange}
            mb={2}
          />
        ) : (
          <Input value={user.lastName} isDisabled mb={2} />
        )}
        {editing.lastName ? (
          <>
            <Button
              variant={"outline"}
              colorScheme="blue"
              onClick={() => handleSave("lastName")}
            >
              Save
            </Button>
            <Button
              variant={"outline"}
              colorScheme="red"
              onClick={handleCancel}
              ml={2}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button variant={"outline"} onClick={() => handleEdit("lastName")}>
            Edit
          </Button>
        )}
      </Box>

      {user?.password && (
        <Box mt={4}>
          <Text mb={2}>Email:</Text>
          {editing.email ? (
            <Input
              name="email"
              value={editedUser.email}
              onChange={handleChange}
              mb={2}
            />
          ) : (
            <Input value={user.email} isDisabled mb={2} />
          )}
          {editing.email ? (
            <>
              <Button
                variant={"outline"}
                colorScheme="blue"
                onClick={() => handleSave("email")}
              >
                Save
              </Button>
              <Button
                variant={"outline"}
                colorScheme="red"
                onClick={handleCancel}
                ml={2}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button variant={"outline"} onClick={() => handleEdit("email")}>
              Edit
            </Button>
          )}
        </Box>
      )}
        {/* TODO: change this user?.password to user?.accounts.local to check if user is using local auth */}
      {user?.password && (
        <Box mt={4}>
          <Text mb={2}>Current Password:</Text>
          {editing.password ? (
            <Input
              name="currentPassword"
              type="password"
              value={editedUser.currentPassword}
              onChange={handleChange}
              mb={2}
            />
          ) : (
            <Input value="********" isDisabled mb={2} />
          )}

          <Text mt={2} mb={2}>
            New Password:
          </Text>
          {editing.password ? (
            <Input
              name="newPassword"
              type="password"
              value={editedUser.newPassword}
              onChange={handleChange}
              mb={2}
            />
          ) : (
            <Input value="********" isDisabled mb={2} />
          )}

          {editing.password ? (
            <>
              <Button
                variant={"outline"}
                colorScheme="blue"
                onClick={() => handleSave("password")}
              >
                Save
              </Button>
              <Button
                variant={"outline"}
                colorScheme="red"
                onClick={handleCancel}
                ml={2}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button variant={"outline"} onClick={() => handleEdit("password")}>
              Edit
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SettingsForm;
