import { createAsyncThunk } from '@reduxjs/toolkit';
import { createFineTuningJobApi, fetchAllFineTuningJobsApi, fetchFineTuningJobApi } from '../../api/FinetuneApi';

export const createFineTuningJobAsync = createAsyncThunk(
    'fineTuning/create',
    async (jobData, thunkAPI) => {
        return await createFineTuningJobApi(jobData, thunkAPI);
    }
);

export const fetchAllFineTuningJobsAsync = createAsyncThunk(
    'fineTuning/fetchAll',
    async (_, thunkAPI) => {
        return await fetchAllFineTuningJobsApi(thunkAPI);
    }
);

export const fetchFineTuningJobAsync = createAsyncThunk(
    'fineTuning/fetchOne',
    async (jobId, thunkAPI) => {
        return await fetchFineTuningJobApi(jobId, thunkAPI);
    }
);
