import React, { useEffect, useState, useRef } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import {
  Box,
  VStack,
  Text,
  useColorMode,
  Badge,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Progress,
  Center,
  HStack,
} from '@chakra-ui/react';

const urlz = process.env.REACT_APP_API_URL;

const ClusterLogs = ({ clusterId, onContainerSelect, status }) => {
  const [containerStat, setContainerStat] = useState(null);
  const [containerDetails, setContainerDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { colorMode } = useColorMode();

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const logsUrl = `${urlz}/clusters/v1/${clusterId}/logs/stream`;
    const statsUrl = `${urlz}/clusters/v1/${clusterId}/stats`;

    const logsEventSource = new EventSourcePolyfill(logsUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const statsEventSource = new EventSourcePolyfill(statsUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    logsEventSource.addEventListener('container-stat', (e) => {
      const stat = JSON.parse(e.data);
      setContainerStat(stat);
    });

    logsEventSource.addEventListener('containers-details', (e) => {
      const details = JSON.parse(e.data);
      setContainerDetails(details);
      if (details.length > 0) {
        onContainerSelect(details[3].id);
      }
      setIsLoading(false);
    });

    logsEventSource.onerror = (err) => {
      console.error('Logs EventSource error:', err);
      setIsLoading(false);
    };

    statsEventSource.onerror = (err) => {
      console.error('Stats EventSource error:', err);
      setIsLoading(false);
    };

    return () => {
      logsEventSource.close();
      statsEventSource.close();
    };
  }, [clusterId, onContainerSelect]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  const bytesToMB = (bytes) => {
    return (bytes / 1024 / 1024).toFixed(2);
  };

  const formatDetail = (detail) => {
    return `State: ${detail.state}, Host: ${detail.host}, Status: ${detail.status}`;
  };

  const renderNoLogsMessage = () => (
    <Center height="100%" width="100%">
      <Box
        p={4}
        mt={20}
        minW="400px"
        borderWidth="1px"
        borderRadius="sm"
        bg={colorMode === 'dark' ? 'gray.700' : 'gray.100'}
        textAlign="center"
      >
        <Text fontSize="sm" color={colorMode === 'dark' ? 'green.300' : 'green.500'}>
          Model Status
        </Text>
        <Badge size={'xs'}>{status}</Badge>
        <Text fontSize="sm" color={colorMode === 'dark' ? 'red.300' : 'red.500'}>
          {status === 'SUCCESS' || status === 'RUNNING' ? 'Model - Health Status Error' : 'No Logs Found'}
        </Text>
      </Box>
    </Center>
  );
  return (
    <VStack spacing={4}>
      {isLoading ? (
        <Center w="100%" p={4}>
          <Spinner size="lg" />
        </Center>
      ) : containerStat || containerDetails.length > 0 ? (
        <>
          <Box
            w="100%"
            maxH="200px"
            overflow="hidden"
            bg={colorMode === 'dark' ? 'gray.700' : 'gray.100'}
            p={4}
            borderRadius="md"
          >
            <Text fontSize="lg" fontWeight="bold" mb={2} color={colorMode === 'dark' ? 'green.300' : 'black'}>
              Container Stats:
            </Text>
            <Box overflowY="auto" maxH="150px">
              {containerStat && (
                <Stat>
                  <StatLabel>CPU</StatLabel>
                  <Progress colorScheme="green" size="sm" value={containerStat.cpu} />
                  <StatNumber>{`${containerStat.cpu}%`}</StatNumber>
                  <StatHelpText>
                    <Badge colorScheme="green">Memory</Badge>: {bytesToMB(containerStat.memory)} MB<br />
                    <Badge colorScheme="green">Memory Usage</Badge>: {bytesToMB(containerStat.memoryUsage)} MB
                  </StatHelpText>
                </Stat>
              )}
            </Box>
          </Box>

          <Box
            w="100%"
            maxH="150px"
            overflowY="auto"
            bg={colorMode === 'dark' ? 'gray.700' : 'gray.100'}
            p={4}
            borderRadius="md"
          >
            <Text fontSize="lg" fontWeight="bold" mb={2} color={colorMode === 'dark' ? 'green.300' : 'black'}>
              Container Details:
            </Text>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Timestamp</Th>
                  <Th>Detail</Th>
                </Tr>
              </Thead>
              <Tbody>
                {containerDetails.map((detail, index) => (
                  <Tr key={index}>
                    <Td>{formatTimestamp(detail.created)}</Td>
                    <Td>{formatDetail(detail)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </>
      ) : (
        renderNoLogsMessage()
      )}
    </VStack>
  );
};

export default ClusterLogs;
