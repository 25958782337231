// rootReducer.js
import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialState = {
  model: "Llama-2-7b-chat-hf",
  max_tokens: 1000,
  temperature: 0.0,
  presence_penalty: 0,
  top_p: 0.2,
};

const playgroundSlice = createSlice({
  name: "playground",
  initialState,
  reducers: {
    setModel: (state, action) => {
      state.model = action.payload;
    },
    setMaxTokens: (state, action) => {
      state.max_tokens = action.payload;
    },
    setTemperature: (state, action) => {
      state.temperature = action.payload;
    },
    setPresencePenalty: (state, action) => {
      state.presence_penalty = action.payload;
    },
    setTopP: (state, action) => {
      state.top_p = action.payload;
    },
  },
});

export const {
  setModel,
  setMaxTokens,
  setTemperature,
  setPresencePenalty,
  setTopP,
} = playgroundSlice.actions;

export default playgroundSlice.reducer;
