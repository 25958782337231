import React, { useEffect } from 'react';
import { Container, Heading, Flex, Spinner, Text, VStack, Box, SimpleGrid } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import BasicModal from '../../components/finetune/button';
import FinetuneCard from '../../components/finetune/card';
import { fetchAllFineTuningJobsAsync } from '../../features/finetune/finetuneAction';

function Finetune() {
  const dispatch = useDispatch();
  const { jobs, loading, error } = useSelector((state) => state.fineTuning);

  useEffect(() => {
    dispatch(fetchAllFineTuningJobsAsync());
  }, [dispatch]);

  return (
    <Container maxW="7xl">
      <VStack spacing={4} align="stretch">
        <Heading as="h1" size="xl">
          Finetune Management
        </Heading>
        <Text size="md" mt={-5}>
          Add and manage your model finetuning here.
        </Text>
        <BasicModal/>
         {loading ? (
          <Spinner />
        ) : error ? (
          <Text color="red.500">{error}</Text>
        ) : jobs.length > 0 ? (
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
            {jobs.map((job) => (
              <Box key={job.uuid} flex="1">
                <FinetuneCard data={job} />
              </Box>
            ))}
          </SimpleGrid>
        ) : (
          <Text>No fine-tuned models present.</Text>
        )}
      </VStack>
    </Container>
  );
}

export default Finetune;
