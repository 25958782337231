import {
  Box,
  Flex,
  Heading,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { format, subMonths, startOfMonth } from "date-fns";
import { useEffect, useState } from "react";
import UsageDetails from "../UsageDetails/UsageDetails";

export default function UserUsageMetrics() {
  const [selectedDates, setSelectedDates] = useState([
    startOfMonth(subMonths(new Date(), 1)), 
    new Date(), 
  ]);
  const [usageData, setUsageData] = useState(null);
  const [usageDataLoading, setUsageDataLoading] = useState(false);

  const getUserMetrics = async (startDate, endDate) => {
    try {
      setUsageDataLoading(true);

      const nextDay = new Date(endDate);
      nextDay.setDate(nextDay.getDate() + 1);

      const startDateFormatted = format(startDate, "yyyy-MM-dd");
      const endDateFormatted = format(nextDay, "yyyy-MM-dd");

      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/users/metrics?from=${startDateFormatted}&to=${endDateFormatted}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      });

      setUsageData(response.data.response);
    } catch (error) {
      console.log(error);
    } finally {
      setUsageDataLoading(false);
    }
  };

  useEffect(() => {
    getUserMetrics(selectedDates[0], selectedDates[1]);
  }, [selectedDates]); 

  return (
    <Box
      mt={10}
      mb={10}
      p={5}
      borderWidth="1px"
      borderRadius="lg"
      bg={useColorModeValue("white", "gray.800")}
    >
      <Heading size="lg" mb={5}>
        User Usage Metrics
      </Heading>
      <Flex gap={5} justifyContent="center">
        <Text>Date Range</Text>
        <RangeDatepicker
          selectedDates={selectedDates}
          onDateChange={setSelectedDates}
          maxDate={new Date()}
        />
      </Flex>

      {usageData && !usageDataLoading ? (
        <Box mt={5}>
          <UsageDetails usageData={usageData} />
        </Box>
      ) : (
        <Box mt={5} textAlign="center">
          <Spinner />
        </Box>
      )}
    </Box>
  );
}
