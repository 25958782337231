// src/features/auth/authActions.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as authApi from "../../api/authApi";

export const loginAsync = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    const response = await authApi.login(credentials, thunkAPI);
    return response;
  }
);

export const getUserAsync = createAsyncThunk(
  "auth/getUser",
  async (data, thunkAPI) => {
    const response = await authApi.getUser(thunkAPI);
    return response;
  }
);

export const googleLoginAsync = createAsyncThunk(
  "auth/googleLogin",
  async (data, thunkAPI) => {
    const response = await authApi.googleLogin(data, thunkAPI);
    return response;
  }
);

export const registerAsync = createAsyncThunk(
  "auth/register",
  async (data, thunkAPI) => {
    const response = await authApi.register(data, thunkAPI);
    return response;
  }
);

export const updateUserFirstNameAsync = createAsyncThunk(
  "auth/updateUserFirstName",
  async (data, thunkAPI) => {
    const response = await authApi.updateUserFirstName(data, thunkAPI);
    return response;
  }
);

export const updateUserLastNameAsync = createAsyncThunk(
  "auth/updateUserLastName",
  async (data, thunkAPI) => {
    const response = await authApi.updateUserLastName(data, thunkAPI);
    return response;
  }
);

export const updateUserEmailAsync = createAsyncThunk(
  "auth/updateUserEmail",
  async (data, thunkAPI) => {
    const response = await authApi.updateUserEmail(data, thunkAPI);
    return response;
  }
);

export const updateUserPasswordAsync = createAsyncThunk(
  "auth/updateUserPassword",
  async (data, thunkAPI) => {
    const response = await authApi.updateUserPassword(data, thunkAPI);
    return response;
  }
);
