import React from "react";
import {
  Box,
  VStack,
  Text,
  Divider,
  Card,
  Image,
  Stack,
  CardBody,
  Heading,
  CardFooter,
  Button,
  HStack,
  LinkOverlay,
} from "@chakra-ui/react";
import CodeSnippet from "./codes/interact";
import ChatCompletion from "./codes/chatcompletions";
import TextCompletions from "./codes/textcompletions";
import CurlC from "./codes/curl";
import Embedding from "./codes/Embeddings";
import PromptTemp from "./codes/PromptTemplate";
import { ExternalLink } from "lucide-react";

const TextImageComponent = ({ data }) => {
  const promptTemplate = data?.model?.prompt_template;

  return (
    <Box ml={-4} paddingX="1%" paddingY="1%">
      <VStack spacing={1} align="stretch">
        <Text fontSize="xl" fontWeight="bold">
          Interact with your model:
        </Text>
        <CodeSnippet data={data} />
        <Divider />

        <Text fontSize="xl" fontWeight="bold">
          cURL:
        </Text>
        <Text fontSize="lg">
          cURL is another good tool for observing the output of the API.
        </Text>
        <CurlC data={data} />
        <Divider />

        <Text fontSize="xl" fontWeight="bold">
          Chat Completions:
        </Text>
        <ChatCompletion data={data} />
        <Divider />

        <Text fontSize="xl" fontWeight="bold">
          Text Completions:
        </Text>
        <TextCompletions data={data} />
        <Divider />

        <Text fontSize="xl" fontWeight="bold">
          Embeddings:
        </Text>
        <Embedding data={data} />
        <Divider />

        {promptTemplate && (
          <>
            <Text fontSize="xl" fontWeight="bold">
              Prompt Template:
            </Text>
            <PromptTemp data={data} />
          </>
        )}

        <Box p={4}>
          <Card
            data-type="Card"
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            bg={'brand.background'}
          >
            <Stack data-type="Stack">
              <CardBody data-type="CardBody" bg={'brand.background'}>
                <Heading data-type="Heading" size="sm">
                  🚀 Ready to use NextAI?
                </Heading>
                <HStack>
                  <Button
                    mt={2}
                    data-type="Button"
                    variant="outline"
                    colorScheme="black"
                    as="a"
                    href="/cookbook"
                    size={"sm"}
                  >
                    Explore Examples Apps
                  </Button>

                  <Text data-type="Text" mt={3} fontSize={12}>
                    or
                  </Text>
                  <a
                    href="https://www.youtube.com/watch?v=FG9_0AiGt0E"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Text
                      data-type="Text"
                      ml={-1}
                      mt={3}
                      fontWeight={600}
                      fontSize={14}
                    >
                      Learn how to deploy a custom model
                    </Text>
                  </a>
                    <Text
                      data-type="Text"
                      ml={-1}
                      mt={3}
                      fontWeight={600}
                      fontSize={8}
                    >
                      <ExternalLink size={16} />
                    </Text>
                </HStack>
              </CardBody>
            </Stack>
          </Card>
        </Box>
      </VStack>
    </Box>
  );
};

export default TextImageComponent;
