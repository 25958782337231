import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Divider,
  Box,
  useToast,
  Progress,
  HStack,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { getAllPaymentMethodsAsync } from "../../features/payment/paymentAction";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { AddIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import countries from "i18n-iso-countries";
import { useDispatch } from "react-redux";

export default function PaymentMethodModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_API_KEY
  );
  const dispatch = useDispatch();

  countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

  function Card({ handleCardAdded }) {
    const stripe = useStripe();
    const [error, setError] = useState("");
    const elements = useElements();
    const authToken = useSelector((state) => state.auth.access_token);
    const [loading, setLoading] = useState(false);
    const [billingDetails, setBillingDetails] = useState({
      name: "",
      email: "",
      address: {
        city: "",
        postal_code: "",
        state: "",
        country: "",
        line1: "",
      },
    });

    const countryOptions = Object.keys(countries.getAlpha2Codes()).map(
      (code) => ({
        value: code,
        label: countries.getName(code, "en"),
      })
    );

    const handleCountryChange = (e) => {
      setBillingDetails({
        ...billingDetails,
        address: {
          ...billingDetails.address,
          country: e.target.value,
        },
      });
    };

    const cardz = {
      style: {
        base: {
          color: "black",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          colorBackground: "#ffffff",
          spacingUnit: "2px",
          borderRadius: "4px",
          fontSize: "18px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
      hidePostalCode: true,
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name in billingDetails.address) {
        setBillingDetails({
          ...billingDetails,
          address: { ...billingDetails.address, [name]: value },
        });
      } else {
        setBillingDetails({ ...billingDetails, [name]: value });
      }
    };

    const toast = useToast();

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!stripe || !elements) {
        setError(
          "Stripe has not loaded. Please refresh the page and try again."
        );
        console.log("Stripe has not loaded");
        return;
      }

      setLoading(true);
      setError("");

      try {
        const response = await axios.get(
          "https://api.nextai.co.in/v1/payments/payment-methods/setup",
          {
            headers: { authorization: `Bearer ${authToken}` },
          }
        );

        const cardSetupResult = await stripe.confirmCardSetup(
          response.data.response.clientSecret,
          {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: billingDetails,
            },
          }
        );

        if (cardSetupResult.setupIntent.status === "succeeded") {
          const paymentMethodResponse = await axios.post(
            "https://api.nextai.co.in/v1/payments/payment-methods/",
            {
              isDefault: true,
              paymentMethod: cardSetupResult.setupIntent.payment_method,
            },
            {
              headers: { authorization: `Bearer ${authToken}` },
            }
          );

          if (paymentMethodResponse.status === 200) {
            setLoading(false);
            handleCardAdded();
            dispatch(getAllPaymentMethodsAsync());
            toast({
              title: "Card Added Successfully",
              status: "success",
              duration: 4000,
              isClosable: true,
            });
            onClose();
          }
        } else {
          console.log("Setup Intent was not successful");
          setError(error.message);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        const errorMessage =
          error?.response?.data?.error?.message ??
          "An error occurred. Please try again later.";
        setError(errorMessage);
      }
    };

    return (
      <>
        <form onSubmit={handleSubmit}>
          {loading && <Progress size="xs" isIndeterminate />}
          <Box mt={1}>
            <CardElement options={cardz} />
          </Box>
          <Divider mt={2} mb={2} />
          <HStack>
            <FormControl id="name">
              <FormLabel fontSize={12}>Name</FormLabel>
              <Input
                size={"sm"}
                variant="filled"
                name="name"
                onChange={handleInputChange}
                value={billingDetails.name}
                mb={1}
              />
            </FormControl>
            <FormControl id="email">
              <FormLabel fontSize={12}>Email</FormLabel>
              <Input
                mb={1}
                size={"sm"}
                name="email"
                variant="filled"
                onChange={handleInputChange}
                value={billingDetails.email}
              />
            </FormControl>
          </HStack>
          <HStack>
            <FormControl id="line1">
              <FormLabel fontSize={12}>Address Line 1</FormLabel>
              <Input
                size={"sm"}
                variant="filled"
                name="line1"
                onChange={handleInputChange}
                value={billingDetails.address.line1}
                mb={1}
              />
            </FormControl>
            <FormControl id="city">
              <FormLabel fontSize={12}>City</FormLabel>
              <Input
                size={"sm"}
                variant="filled"
                name="city"
                onChange={handleInputChange}
                value={billingDetails.address.city}
                mb={1}
              />
            </FormControl>
          </HStack>
          <HStack>
            <FormControl id="state">
              <FormLabel fontSize={12}>State</FormLabel>
              <Input
                size={"sm"}
                name="state"
                variant="filled"
                onChange={handleInputChange}
                value={billingDetails.address.state}
              />
            </FormControl>
            <FormControl id="postal_code">
              <FormLabel fontSize={12}>Postal Code</FormLabel>
              <Input
                mb={1}
                size={"sm"}
                name="postal_code"
                variant="filled"
                onChange={handleInputChange}
                value={billingDetails.address.postal_code}
              />
            </FormControl>
          </HStack>
          <FormControl id="country">
            <FormLabel fontSize={12}>Country</FormLabel>
            <Select
              mt={-1}
              size={"sm"}
              variant="filled"
              onChange={handleCountryChange}
              value={billingDetails.address.country}
            >
              {countryOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <HStack mt={3}>
            <Button size={"sm"} variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              mt={0}
              size={"sm"}
              color={"white"}
              bg={"brand.primary"}
              _hover={{ bg: "brand.secondary" }}
              type="submit"
              isLoading={loading}
              loadingText="Processing"
            >
              Add Card
            </Button>
          </HStack>
          {error && (
            <Alert status="error" mt={3}>
              <AlertIcon />
              <AlertDescription fontSize={12}>{error}</AlertDescription>
            </Alert>
          )}
        </form>
      </>
    );
  }

  return (
    <>
      <Button
        bg={"brand.primary"}
        _hover={{
          bg: "brand.secondary",
        }}
        color={"white"}
        leftIcon={<AddIcon />}
        onClick={onOpen}
      >
        Add Payment Method
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={500}>
          <ModalHeader>Add new card</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Elements stripe={stripePromise}>
              <Card handleCardAdded={() => onClose()} />{" "}
            </Elements>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
