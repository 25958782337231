import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
  Image,
} from "@chakra-ui/react";
import {
  FiSettings,
  FiMenu,
  FiChevronDown,
  FiMoon,
  FiSun,
  FiLogOut,
} from "react-icons/fi";
import Logo from "../../assets/images/Logo.png";
import { IoLogOut } from "react-icons/io5";
import { FaChartSimple } from "react-icons/fa6";
import {
  Link as ReactRouterLink,
  useLocation,
  NavLink as ReactRouterNavLink,
} from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { logout } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  FaFolder,
  FaDatabase,
  FaWrench,
  FaBolt,
  FaPlug,
  FaHouse,
  FaMoneyCheckDollar,
  FaGear,
  FaTicket,
  FaBook,
  FaCubes,
} from "react-icons/fa6";
import LogoLight from "../../assets/images/Logo.png";
import LogoDark from "../../assets/images/white.png";
import WalletUsageBar from "./WalletUsage";

const LinkItems = [
  { name: "Home", icon: FaHouse, href: "/" },
  // { name: "Plugins", icon: FaPlug, href: "/plugins" },
  { name: "API Endpoints", icon: FaCubes, href: "/playground" },
  { name: "Flashpoints", icon: FaBolt, href: "/endpoints" },
  { name: "Deployments", icon: FaDatabase, href: "/deployments" },
  { name: "Finetune", icon: FaWrench, href: "/finetune" },
  { name: "Dataset", icon: FaFolder, href: "/dataset" },
  // { name: "Usage & Billings", icon: FaMoneyCheckDollar, href: "/billing" },
  { name: "Usage", icon: FaChartSimple, href: "/usage" },
];

const BottomLinkItems = [
  { name: "Documentation", icon: FaBook, href: "https://docs.nextai.co.in/Introduction/Welcome-to-NextAI" },
  { name: "Support", icon: FaTicket, href: "https://discord.gg/tSepEYcspp" },
];

const SidebarContent = ({ onClose, ...rest }) => {
  const dispatch = useDispatch();
  const logo = useColorModeValue(LogoLight, LogoDark);
  return (
    <Box
      bg={useColorModeValue("brand.background", "brand.backgroundDark")}
      borderRight="1px"
      borderRightColor={useColorModeValue("brand.border", "brand.borderDark")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex direction="column" h="full" justifyContent="space-between" pb="2">
        <Box>
          <Flex
            h="20"
            alignItems="center"
            mx="8"
            justifyContent="space-between"
          >
            <Image src={logo} boxSize="full" h={50} alt="Logo" />
            <CloseButton
              display={{ base: "flex", md: "none" }}
              onClick={onClose}
            />
          </Flex>
          {LinkItems.map((link) => (
            <NavItem key={link.name} icon={link.icon} href={link?.href}>
              {link.name}
            </NavItem>
          ))}
        </Box>

        <Box>
          {BottomLinkItems.map((link) => (
            <NavItem
              key={link.name}
              icon={link.icon}
              href={link?.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.name}
            </NavItem>
          ))}
          <NavItem icon={IoLogOut} onClick={() => dispatch(logout())}>
            Logout
          </NavItem>
        </Box>
      </Flex>
    </Box>
  );
};

const NavItem = ({ icon, children, href, target, rel, ...rest }) => {
  const colorIsActive = useColorModeValue("#4E4FEB", "#4E4FEB");
  const colorIsNotActive = useColorModeValue("#171923", "#F7FAFC");
  return (
    <ChakraLink
      as={ReactRouterNavLink}
      to={href}
      style={({ isActive }) => ({
        color:
          isActive && children !== "Logout" && children !== "Support" && children !== "Documentation"
            ? colorIsActive
            : colorIsNotActive,
        textDecoration: "none",
      })}
      _focus={{ boxShadow: "none" }}
      target={target}
      rel={rel}
    >
      <Flex
        align="center"
        p="3"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color="inherit"
        _hover={{
          bg: `${useColorModeValue("brand.primary", "brand.primaryDark")}`,
          color: `${useColorModeValue(
            "brand.textPrimaryDark",
            "brand.textPrimary"
          )}`,
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "inherit",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </ChakraLink>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("brand.background", "brand.backgroundDark")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("brand.border", "brand.borderDark")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
        color={useColorModeValue("brand.primary", "brand.primaryDark")}
      >
        Next AI
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        <WalletUsageBar/>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          onClick={toggleColorMode}
          icon={colorMode === "light" ? <FiMoon /> : <FiSun />}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar
                  size={"sm"}
                  src={
                    "https://media.discordapp.net/attachments/1148930956158320730/1206586820540899368/Screenshot_2024-02-12_at_6.34.09_PM.png?ex=65dc8c8b&is=65ca178b&hm=1fc1cfce2db54fba2fcdce40271937231ac58d8b2da41547eac8967338c46e71&=&format=webp&quality=lossless&width=260&height=264"
                  }
                />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text
                    fontSize="sm"
                    color={useColorModeValue(
                      "brand.primary",
                      "brand.primaryDark"
                    )}
                  >
                    {user?.firstName} {user?.lastName}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("brand.background", "brand.backgroundDark")}
              borderColor={useColorModeValue(
                "brand.border",
                "brand.borderDark"
              )}
            >
              <ChakraLink
                as={ReactRouterLink}
                to="/api"
                textDecoration="none"
                _hover={{ textDecoration: "none" }}
              >
                <MenuItem
                  bg={useColorModeValue(
                    "brand.background",
                    "brand.backgroundDark"
                  )}
                  _hover={{
                    cursor: "pointer",
                    color: useColorModeValue(
                      "brand.primary",
                      "brand.primaryDark"
                    ),
                  }}
                >
                  API
                </MenuItem>
              </ChakraLink>

              <ChakraLink
                as={ReactRouterLink}
                to="/settings"
                textDecoration="none"
                _hover={{ textDecoration: "none" }}
              >
                <MenuItem
                  bg={useColorModeValue(
                    "brand.background",
                    "brand.backgroundDark"
                  )}
                  _hover={{
                    cursor: "pointer",
                    color: useColorModeValue(
                      "brand.primary",
                      "brand.primaryDark"
                    ),
                  }}
                >
                  Settings
                </MenuItem>
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/services"
                textDecoration="none"
                _hover={{ textDecoration: "none" }}
              >
                <MenuItem
                  bg={useColorModeValue(
                    "brand.background",
                    "brand.backgroundDark"
                  )}
                  _hover={{
                    cursor: "pointer",
                    color: useColorModeValue(
                      "brand.primary",
                      "brand.primaryDark"
                    ),
                  }}
                >
                  Add Service Account
                </MenuItem>
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/billing"
                textDecoration="none"
                _hover={{ textDecoration: "none" }}
              >
                <MenuItem
                  bg={useColorModeValue(
                    "brand.background",
                    "brand.backgroundDark"
                  )}
                  _hover={{
                    cursor: "pointer",
                    color: useColorModeValue(
                      "brand.primary",
                      "brand.primaryDark"
                    ),
                  }}
                >
                  Usage & Billings
                </MenuItem>
              </ChakraLink>
              <MenuDivider />
              <MenuItem
                bg={useColorModeValue(
                  "brand.background",
                  "brand.backgroundDark"
                )}
                _hover={{
                  cursor: "pointer",
                  color: useColorModeValue(
                    "brand.primary",
                    "brand.primaryDark"
                  ),
                }}
                onClick={() => dispatch(logout())}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const Sidebar = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      minH="100vh"
      bg={useColorModeValue("brand.background", "brand.backgroundDark")}
    >
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
};

export default Sidebar;
