import {
  Flex,
  Heading,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import RegisterForm from "../../components/register/RegisterForm";
import Lottie from 'lottie-react'; 
import animationImg from '../../assets/animations/Signup.json'
import WhiteLogo from '../../assets/images/WhiteLogo.png'

const Register = () => {
  return (
    <Flex
      minH={"100vh"}
      direction={{ base: "column", lg: "row" }}
      bg={useColorModeValue("brand.background", "brand.backgroundDark")}
    >
      <Flex
        flex={1}
        bg={useColorModeValue("gray.900", "gray.900")}
        justify={"center"}
        align={"center"}
        direction={"column"}
        pb={{ base: 0, lg: 10 }}
      >
        <Heading as="h2" size="2xl" fontFamily={"body"} my={0} color="white">
          <Image w={52} src={WhiteLogo}></Image>
        </Heading>
        <Lottie animationData={animationImg} style={{ width: '75%' }} />
      </Flex>
      <Flex
        p={8}
        flex={1}
        align={"center"}
        justify={"space-evenly"}
        direction={"column"}
        pb={{ base: 10, lg: 0 }}
      >
        <RegisterForm />
      </Flex>
    </Flex>
  );
};

export default Register;
