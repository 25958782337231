import React from 'react';
import { Tabs, Text, TabList, TabPanels, TabPanel, useTab, useMultiStyleConfig, Box, Button } from '@chakra-ui/react';
import ShellComponent from './RowCard';
import TextImageComponent from './Docs';
import GPUStats from './GpuStats';
import Playground from './Playground';

function CustomTabs({ data }) {
  const CustomTab = React.forwardRef((props, ref) => {
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps['aria-selected'];
    const styles = useMultiStyleConfig('Tabs', tabProps);
    return (
      <Button __css={styles.tab} {...tabProps}>
        <Text fontWeight={500}>
          {tabProps.children}
        </Text>
      </Button>
    );
  });

  return (
    <Tabs mt={10} ml={-52} >
      <TabList>
        <CustomTab>Metrics</CustomTab>
        <CustomTab>Playground</CustomTab>
        <CustomTab>Documentation</CustomTab>
      </TabList>
      <TabPanels>
      <TabPanel>
          {data && data.uuid && <GPUStats clusterId={data.uuid} />}
        </TabPanel>
        <TabPanel>
          <Playground endpoint={data?.endpoint} model={data?.model} initialData={data} />
          {/* <ShellComponent data={data}/> */}
        </TabPanel>
        <TabPanel justifyContent={'center'} display={'flex'}>
          <TextImageComponent data={data}  />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default CustomTabs;
