import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllTiers } from "../../api/tierApi";

export const getAllTiersAsync = createAsyncThunk(
  "tiers/getTiers",
  async ({ cloud, size, quantized }, thunkAPI) => {
    const response = await getAllTiers(cloud, size, quantized, thunkAPI);
    return response;
  }
);
