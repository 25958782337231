import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Text,
  Flex,
  Textarea,
  Image,
  Box,
  VStack,
  HStack,
  Spacer,
  IconButton,
} from "@chakra-ui/react";
import gcpLogo from "../../assets/images/gcp.png";
import awsLogo from "../../assets/images/AWS.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  createServiceAccountClusterAsync,
  fetchAllServiceAccountsAsync,
  validateServiceAccountAsync,
} from "../../features/services/serviceAccountAction";
import ServiceAccountsTable from "./table";

const ServiceAccount = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.access_token);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [serviceAccountData, setServiceAccountData] = useState({
    name: "",
    credentials: "",
    cloud: "",
  });

  useEffect(() => {
    dispatch(fetchAllServiceAccountsAsync());
  }, [dispatch]);

  const validateForm = () => {
    const errors = {};
    if (!serviceAccountData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!serviceAccountData.cloud) {
      errors.cloud = "Cloud provider is required";
    }
    if (!serviceAccountData.credentials) {
      errors.credentials = "Credentials are required";
    }
    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "credentials") {
      try {
        if (value.trim() === "") {
          throw new Error("Credentials are empty");
        }
        const parsedCredentials = JSON.parse(value);
        setServiceAccountData({
          ...serviceAccountData,
          [name]: parsedCredentials,
        });
        setErrorMessage("");
      } catch (err) {
        setErrorMessage(err.message);
      }
    } else {
      setServiceAccountData({
        ...serviceAccountData,
        [name]: value,
      });
    }
  };

  const isCloudSelected = (cloud) => serviceAccountData.cloud === cloud;

  const selectCloudProvider = (cloud) => {
    setServiceAccountData({ ...serviceAccountData, cloud });
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors({});
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/service-accounts/`,
        serviceAccountData,
        {
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      const uuid = response.data.response.uuid;
      if (uuid) {
        await dispatch(validateServiceAccountAsync(uuid)).unwrap();
        dispatch(fetchAllServiceAccountsAsync());
      }
    } catch (error) {
      console.error("Error in handleSubmit", error);
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Flex>
      <Box>
        <HStack>
          <Button
            color={"white"}
            bg={"brand.primary"}
            _hover={{ bg: "brand.secondary" }}
            onClick={onOpen}
          >
            Add Service Account
          </Button>
        </HStack>
      </Box>
    </Flex>
    <ServiceAccountsTable />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth="xl">
          <ModalHeader>Create Service Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4} isInvalid={!!validationErrors.name}>
              <FormLabel>Name</FormLabel>
              <Input name="name" onChange={handleInputChange} />
              {validationErrors.name && (
                <Text fontSize={12} color="red.500">
                  {validationErrors.name}
                </Text>
              )}
            </FormControl>
            <Flex mb={4} justify="space-around">
              <FormControl>
                <FormLabel>Select Cloud</FormLabel>
                <VStack>
                  <HStack>
                    <Box
                      p={4}
                      border="2px"
                      minW={40}
                      borderColor={
                        isCloudSelected("google") ? "brand.primary" : "gray.200"
                      }
                      borderRadius="md"
                      cursor="pointer"
                      onClick={() => selectCloudProvider("google")}
                      _hover={{ shadow: "md" }}
                      textAlign={"center"}
                    >
                      <Image
                        ml={8}
                        src={gcpLogo}
                        alt="Google Cloud"
                        boxSize="60px"
                      />
                      <Text mt={2}>GCP</Text>
                    </Box>
                    <Box
                      p={4}
                      ml={0}
                      minW={40}
                      border="2px"
                      borderColor={
                        isCloudSelected("aws") ? "brand.primary" : "gray.200"
                      }
                      borderRadius="md"
                      cursor="pointer"
                      onClick={() => selectCloudProvider("aws")}
                      _hover={{ shadow: "md" }}
                      textAlign={"center"}
                    >
                      <Image
                        ml={8}
                        src={awsLogo}
                        alt="Amazon Web Services"
                        boxSize="60px"
                      />
                      <Text mt={2}>AWS</Text>
                    </Box>
                  </HStack>
                </VStack>
              </FormControl>
            </Flex>
            {validationErrors.cloud && (
              <Text fontSize={12} color="red.500" mt={-2} mb={2}>
                {validationErrors.cloud}
              </Text>
            )}
            <FormControl mb={4} isInvalid={!!validationErrors.credentials}>
              <FormLabel>Credentials</FormLabel>
              <Textarea
                name="credentials"
                placeholder="Enter credentials as JSON"
                onChange={handleInputChange}
              />
              {validationErrors.credentials && (
                <Text fontSize={12} color="red.500" mt={2}>
                  {validationErrors.credentials}
                </Text>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              color={"white"}
              bg={"brand.primary"}
              _hover={{ bg: "brand.secondary" }}
              mr={3}
              onClick={handleSubmit}
            >
              Create
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ServiceAccount;
