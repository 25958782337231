import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Spinner,
  FormControl,
  FormLabel,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getSpecificClusterAsync } from "../../features/clusters/clusterAction";
import UsageDetails from "./UsageDetails";
import UsageClusterDetails from "./UsageClusterDetails";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { format } from "date-fns";

export default function UsageMetrics(params) {
  const { cluster: clusterDetails } = params;
  const clusterUUID = clusterDetails?.uuid;

  const dispatch = useDispatch();
  const [usageData, setUsageData] = useState(null);
  const [usageDataLoading, setUsageDataLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState([
    new Date(clusterDetails.createdAt),
    new Date(),
  ]);

  useEffect(() => {
    if (clusterUUID) {
      dispatch(getSpecificClusterAsync(clusterUUID));
    }
  }, [clusterUUID, dispatch]);

  useEffect(() => {
    setSelectedDates([new Date(clusterDetails.createdAt), new Date()]);
  }, [clusterDetails]);

  useEffect(() => {
    if (clusterDetails?.createdAt) {
      fetchUsageData(selectedDates[0], selectedDates[1]);
    }
  }, [selectedDates]);

  const fetchUsageData = async (startDate, endDate) => {
    try {
      setUsageDataLoading(true);
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

      const startDateFormatted = format(startDate, "yyyy-MM-dd");
      const endDateFormatted = format(adjustedEndDate, "yyyy-MM-dd");

      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/clusters/v1/${clusterUUID}/usage-records?from=${startDateFormatted}&to=${endDateFormatted}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      });
      setUsageData(response.data.response);
    } catch (error) {
      console.error("Error fetching usage data:", error);
    } finally {
      setUsageDataLoading(false);
    }
  };

  if (!clusterDetails) {
    return (
      <Box mt={10} textAlign="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Box
          w={"100%"}
          h={"100%"}
          border={"1px solid #E2E8F0"}
          p={5}
          borderRadius={5}
        >
          <Box mb={5}>
            <UsageClusterDetails clusterDetails={clusterDetails} />
          </Box>
          <Box>
            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
              <FormControl>
                <FormLabel>
                  <Heading size="sm">Date Range</Heading>
                </FormLabel>
              </FormControl>
            </SimpleGrid>
          </Box>
          <Flex>
            <RangeDatepicker
              selectedDates={selectedDates}
              onDateChange={setSelectedDates}
              maxDate={new Date()}
              minDate={new Date(clusterDetails?.createdAt)}
            />
          </Flex>
          {usageData && !usageDataLoading ? (
            <Box mt={5}>
              <UsageDetails usageData={usageData} />
            </Box>
          ) : (
            <Box mt={5} textAlign="center">
              <Spinner />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
