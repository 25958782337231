import { createSlice } from "@reduxjs/toolkit";
import { getAllTiersAsync } from "./tierActions";

const tierSlice = createSlice({
  name: "tier",
  initialState: {
    tiers: [],
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    resetTier: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
      state.tiers = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllTiersAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTiersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.tiers = action.payload;
    });
    builder.addCase(getAllTiersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetTier } = tierSlice.actions;

export default tierSlice.reducer;
