import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Badge,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Select,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  HStack,
  Image,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { debounce, set, size } from "lodash";
import { Check, Info, X, Cloud } from "lucide-react";
import { deployApi } from "../../api/deployApi";
import { getAllClustersAsync } from "../../features/clusters/clusterAction";
import { useNavigate } from "react-router-dom";
import { fetchAllServiceAccountsAsync } from "../../features/services/serviceAccountAction";
import { getAllPaymentMethodsAsync } from "../../features/payment/paymentAction";

export default function DeployModal({ isOpen, onClose, model }) {
  const [endpointName, setEndpointName] = useState("");
  const [tiers, setTiers] = useState([]);
  const [availabilityStatus, setAvailabilityStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const access_token = useSelector((state) => state.auth.access_token);
  const color = useColorModeValue("brand.primary", "brand.primaryDark");
  const [selectedTier, setSelectedTier] = useState(null);
  const dispatch = useDispatch();
  const paymentMethods = useSelector((state) => state.payment.paymentMethods);
  const isLoadingPaymentMethods = useSelector((state) => state.payment.loading);
  const toast = useToast();
  const navigate = useNavigate();
  const [quantization, setQuantization] = useState(false);
  const [selectedCloud, setSelectedCloud] = useState("RunPod");
  const [selectedServiceAccount, setSelectedServiceAccount] = useState("");
  const serviceAccounts = useSelector(
    (state) => state.serviceAccount.serviceAccounts
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchAllServiceAccountsAsync());
      dispatch(getAllPaymentMethodsAsync());
    }
  }, [isOpen, dispatch]);

  const validatedAccounts = serviceAccounts.filter(
    (account) => account.validityStatus === "VALIDATED"
  );

  useEffect(() => {
    if (isOpen) {
      fetchTiers(selectedCloud, quantization);
    }
  }, [isOpen, selectedCloud, quantization]);

  const fetchTiers = async (cloud, quantize) => {
    try {
      const size = model?.parameters;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tiers/?cloud=${cloud}&quantize=${quantize}&size=${size}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      if (response.data.status === 200) {
        setTiers(response.data.response);
      } else {
        console.error("Failed to fetch tiers:", response);
        setTiers([]);
      }
    } catch (error) {
      console.error("Error fetching tiers:", error);
      setTiers([]);
    }
  };

  const navigateToAddCard = () => {
    navigate("/billing");
  };

  const handleServiceAccountChange = (event) => {
    const accountUuid = event.target.value;
    const account = serviceAccounts.find(
      (account) => account.uuid === accountUuid
    );
    if (account) {
      const cloudValue =
        account.cloud === "google" ? "GCP" : account.cloud.toUpperCase();
      setSelectedCloud(cloudValue);
    } else {
      setSelectedCloud("RunPod");
    }
  };

  const handleQuantizationToggle = () => {
    setQuantization(!quantization);
  };

  const sendQuery = (query) => {
    if (query.trim().length === 0) {
      setAvailabilityStatus("Endpoint is not available!");
      return;
    }

    setLoading(true);
    setAvailabilityStatus(null);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/clusters/v1/check-availability?endpoint=${query}.nextai.co.in`,
        {
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setAvailabilityStatus("Endpoint is available!");
        } else {
          setAvailabilityStatus("Endpoint is not available!");
        }
      })
      .catch((error) => {
        console.error("Error checking availability:", error);
        setAvailabilityStatus("Endpoint is not available!");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const delayedSearch = useCallback(
    debounce((q) => sendQuery(q), 600),
    []
  );

  const handleChange = (event) => {
    const value = event.target.value;
    const isValid = /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(value);

    if (isValid || value === "") {
      setEndpointName(value);
      delayedSearch(value);
    } else {
      toast({
        title: "Invalid Endpoint Name",
        description:
          "Endpoint name must be lowercase and cannot contain special characters.",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    return () => {
      setAvailabilityStatus(null);
      setLoading(false);
      setSelectedTier(null);
      setEndpointName("");
    };
  }, [onClose]);

  const handleDeploy = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/clusters/v1/create`,
        !quantization
          ? {
              region: "europe-west4",
              zone: "europe-west4-b",
              model: model?.uuid,
              nodes: 1,
              tier: selectedTier,
              name: endpointName,
              endpoint: `${endpointName}.nextai.co.in`,
              ...(selectedServiceAccount && {
                credential: selectedServiceAccount,
              }),
            }
          : {
              region: "europe-west4",
              zone: "europe-west4-b",
              model: model?.uuid,
              nodes: 1,
              tier: selectedTier,
              endpoint: `${endpointName}.nextai.co.in`,
              name: endpointName,
              modelName: model?.otherModels[0].name,
              type: model?.otherModels[0].type,
              modelPath: model?.otherModels[0].path,
              ...(selectedServiceAccount && {
                credential: selectedServiceAccount,
              }),
            },
        {
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      quantization
        ? await deployApi(res.data.response.uuid, access_token, {
            name: model?.otherModels[0].name,
            path: model?.otherModels[0].path,
            quantizationType: model?.otherModels[0].type,
          })
        : await deployApi(res.data.response.uuid, access_token);
      dispatch(getAllClustersAsync());
      toast({
        title: "Model initiated!",
        description: "The Model deployment has been initiated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setTimeout(() => {
        navigate("/deployments");
      }, 1000);
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while initiating the Model.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth={600}>
        <ModalHeader color={color}>
          <Text>
            <b>Deploy</b> {model?.name}
          </Text>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody mt={-4}>
          <FormControl>
            <FormLabel gap={2} display={"flex"} alignItems={"center"}>
              <b>Endpoint Name</b>
              <Tooltip
                label="Specify a unique endpoint name for deployment."
                placement="auto"
                hasArrow
              >
                <Info size={14} />
              </Tooltip>
            </FormLabel>
            <Flex
              gap={5}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Input
                type="text"
                placeholder="Check endpoint availability"
                value={endpointName}
                onChange={handleChange}
              />
              {endpointName.trim().length > 0 && (
                <Box>
                  {loading ? (
                    <Spinner color={color} />
                  ) : availabilityStatus === "Endpoint is available!" ? (
                    <Text color="green.500">
                      <Check />
                    </Text>
                  ) : (
                    <Text color="red.500">
                      <X />
                    </Text>
                  )}
                </Box>
              )}
            </Flex>
          </FormControl>
          {model?.isQuantizeable && (
            <Box mt={3} mb={3}>
              <FormLabel
                gap={2}
                display={"flex"}
                alignItems={"center"}
                htmlFor="quantization"
                mb="2"
              >
                <b>Quantization</b>
                <Tooltip
                  label="Convert precision: FP16 / INT4 "
                  placement="auto"
                  hasArrow
                >
                  <Info size={14} />
                </Tooltip>
              </FormLabel>
              <HStack spacing={4}>
                <Button
                  onClick={handleQuantizationToggle}
                  bg={quantization ? "brand.primary" : "green"}
                  _hover={{ bg: "brand.secondary" }}
                  color={"white"}
                >
                  {quantization ? "INT4" : "FP16"}
                </Button>
                <VStack align="start">
                  <Text ml={-2} fontSize={10} fontWeight={500}>
                    {quantization ? "smaller hardware" : "balancing efficiency"}
                  </Text>
                  <Text ml={-2} fontSize={10} fontWeight={500}>
                    {quantization ? "compromised accuracy" : "precision"}
                  </Text>
                </VStack>
              </HStack>
            </Box>
          )}

          {validatedAccounts.length > 0 && (
            <FormControl mb={2}>
              <FormLabel
                display={"flex"}
                alignItems={"center"}
                fontSize="lg"
                mb={2}
              >
                <b>Service Account</b>
                <Tooltip
                  label="Choose your service account for deployment"
                  hasArrow
                >
                  <InfoOutlineIcon ml={2} />
                </Tooltip>
              </FormLabel>
              <Select
                placeholder="Default NextAI"
                onChange={handleServiceAccountChange}
                size="sm"
                variant="filled"
              >
                {validatedAccounts.map((account) => (
                  <option key={account.uuid} value={account.uuid}>
                    Cloud: {account.cloud} | Name: {account.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          <Box mt={2}>
            <FormLabel fontSize="lg" mb={2}>
              <b>Select Tier</b>
            </FormLabel>
            <SimpleGrid columns={2} spacing={4}>
              {tiers.map((tier) => (
                <Box
                  key={tier.uuid}
                  p={5}
                  shadow="sm"
                  width={'full'}
                  borderRadius="lg"
                  cursor="pointer"
                  borderColor={
                    selectedTier === tier.uuid ? "brand.primary" : "gray.500"
                  }
                  borderWidth={selectedTier === tier.uuid ? "2px" : "1px"}
                  onClick={() => setSelectedTier(tier.uuid)}
                >
                  <Heading color={selectedTier === tier.uuid ? "brand.primary" : "gray.500"} size="sm" mb={2}>
                    {tier.name}
                  </Heading>
                  <Text color={selectedTier === tier.uuid ? "brand.primary" : "gray.500"} fontSize={12} mb={2}>{tier.configuration.cpu.description}</Text>
                  <Badge colorScheme="green">$ {tier.pricePerHour} / hr</Badge>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </ModalBody>
        <ModalFooter>
          {isLoadingPaymentMethods ? (
            <Spinner color={color} />
          ) : paymentMethods.length > 0 ? (
            <Button
              w={"100%"}
              isDisabled={
                availabilityStatus !== "Endpoint is available!" || !selectedTier
              }
              color={color}
              onClick={handleDeploy}
              variant="outline"
              borderColor={color}
            >
              Deploy
            </Button>
          ) : (
            <Button
              w={"100%"}
              color={color}
              variant="outline"
              borderColor={color}
              onClick={navigateToAddCard}
            >
              Please Recharge to Deploy
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
