import {
  Flex,
  Heading,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import LoginForm from "../../components/login/LoginForm";
import Lottie from 'lottie-react'; 
import animationData from '../../assets/animations/Login.json'
import WhiteLogo from '../../assets/images/WhiteLogo.png'

import { Image } from "@chakra-ui/react";
const Login = () => {
  return (
    <Flex
      minH={"100vh"}
      direction={{ base: "column", lg: "row" }}
      bg={useColorModeValue("brand.background", "brand.backgroundDark")}
    >
      <Flex
        flex={1}
        bg={useColorModeValue("gray.900", "gray.900")}
        justify={"center"}
        align={"center"}
        direction={"column"}
        pb={{ base: 0, lg: 10 }}
      >
        <Heading as="h2" size="2xl" fontFamily={"body"} my={10} color="white">
        <Image w={52} src={WhiteLogo}></Image>
        </Heading>
        <Lottie animationData={animationData} style={{ width: '85%' }} />
      </Flex>
      <Flex
        p={8}
        flex={1}
        align={"center"}
        justify={"space-evenly"}
        direction={"column"}
        pb={{ base: 10, lg: 0 }}
      >
        <LoginForm />
      </Flex>
    </Flex>
  );
};

export default Login;
