import axios from "axios";
const url = process.env.REACT_APP_API_URL;

export const createApiKeyApi = async (clusterId, apiKeyName, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.post(
      `${url}/clusters/v1/${clusterId}/access-keys`,
      { name: apiKeyName },
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const getApiKeysApi = async (clusterId, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(
      `${url}/clusters/v1/${clusterId}/access-keys`,
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const revokeApiKeyApi = async (clusterId, apiKeyId, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.delete(
      `${url}/clusters/v1/${clusterId}/access-keys/${apiKeyId}`,
      {
        headers: {
          authorization: `Bearer ${state.auth.access_token}`,
        },
      }
    );
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};