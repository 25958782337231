import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const gradientBlue = (canvas) => {
  const ctx = canvas.getContext("2d");
  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
  gradient.addColorStop(0, "rgba(6, 143, 255, 1)");
  gradient.addColorStop(1, "rgba(6, 143, 255, 0.5)");
  return gradient;
};

const gradientPurple = (canvas) => {
  const ctx = canvas.getContext("2d");
  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
  gradient.addColorStop(0, "rgba(78, 79, 235, 1)");
  gradient.addColorStop(1, "rgba(78, 79, 235, 0.5)");
  return gradient;
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Your Usage",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        borderDash: [5, 5],
      },
    },
  },
  animation: {
    tension: {
      duration: 1000,
      easing: 'linear',
      from: 1,
      to: 0,
      loop: true
    }
  },
  elements: {
    bar: {
      borderRadius: 20,
      borderSkipped: 'bottom',
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export function Chart() {
  const canvasRef = React.useRef(null);

  const data = {
    labels,
    datasets: [
      {
        label: "Model Usage",
        data: labels.map(() => Math.random() * 0), 
        backgroundColor: (context) => gradientBlue(context.chart.canvas),
      },
      {
        label: "Hardware",
        data: labels.map(() => Math.random() * 0), 
        backgroundColor: (context) => gradientPurple(context.chart.canvas),
      },
    ],
  };

  return <Bar ref={canvasRef} options={options} data={data} />;
}
