import React from 'react';
import { Box, Text, List, ListItem, Divider, useColorModeValue, Heading } from '@chakra-ui/react';

const Component = () => {
  return (
    <Box><Heading mb={2} fontSize={29}>Model Parameters</Heading>
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg={useColorModeValue("brand.textPrimaryDark", "gray.800")}>
      <Text fontSize="lg" fontWeight="bold">model</Text>
      <Text color={useColorModeValue("blue.500", "green.300")} fontSize="sm">string</Text>
      <Text fontSize="sm">The model to be used for chat completion.</Text>
      <Divider my={4} />
      
      <Text fontSize="lg" fontWeight="bold">max_tokens</Text>
      <Text color={useColorModeValue("blue.500", "green.300")} fontSize="sm">integer (optional)</Text>
      <Text fontSize="sm">The maximum number of tokens to generate for the chat completion.</Text>
      <Divider my={4} />
      
      <Text fontSize="lg" fontWeight="bold">messages</Text>
      <Text color={useColorModeValue("blue.500", "green.300")} fontSize="sm">list of objects</Text>
      <List spacing={2} fontSize={'sm'}>
        <ListItem>A list of chat messages, where each message is an object with properties: role and content. <br></br> Supported roles are “system”, “assistant”, and “user”. </ListItem>
      </List>

      <Divider my={4} />

      <Text fontSize="lg" fontWeight="bold">temperature</Text>
      <Text color={useColorModeValue("blue.500", "green.300")} fontSize="sm">float (optional)</Text>
      <Text fontSize="sm">A value between 0.0 and 2.0 that controls the randomness of the model's output.</Text>
      <Divider my={4} />

      <Text fontSize="lg" fontWeight="bold">top_p</Text>
      <Text color={useColorModeValue("blue.500", "green.300")} fontSize="sm">float (optional)</Text>
      <Text fontSize="sm">A value between 0.0 and 1.0 that controls the probability of the model generating a particular token.</Text>
      <Divider my={4} />

      <Text fontSize="lg" fontWeight="bold">stop</Text>
      <Text color={useColorModeValue("blue.500", "green.300")} fontSize="sm">list of strings (optional)</Text>
      <Text fontSize="sm">A list of strings that the model will stop generating text if it encounters any of them.</Text>
      <Divider my={4} />

      <Text fontSize="lg" fontWeight="bold">frequency_penalty</Text>
      <Text color={useColorModeValue("blue.500", "green.300")} fontSize="sm">float (optional)</Text>
      <Text fontSize="sm">A value between 0.0 and 1.0 that controls how much the model penalizes generating repetitive responses.</Text>
      <Divider my={4} />

      <Text fontSize="lg" fontWeight="bold">presence_penalty</Text>
      <Text color={useColorModeValue("blue.500", "green.300")} fontSize="sm">float (optional)</Text>
      <Text fontSize="sm">A value between 0.0 and 1.0 that controls how much the model penalizes generating responses that contain certain words or phrases.</Text>
      <Divider my={4} />

      <Text fontSize="lg" fontWeight="bold">stream</Text>
      <Text color={useColorModeValue("blue.500", "green.300")} fontSize="sm">boolean (optional)</Text>
      <Text fontSize="sm">Indicates whether the response should be streamed.</Text>
    </Box>
    </Box>
  );
};

export default Component;
