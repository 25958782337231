import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  InputLeftAddon,
  InputGroup,
  Alert,
  AlertIcon,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  initiatePaymentRequestAsync,
  getPaymentStatusAsync,
} from "../../features/paymentv2/paymentv2Action";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";

const loadRazorpayScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

function BillingSettingsComponent() {
  const bgColor = useColorModeValue("white", "gray.700");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [creditAmount, setCreditAmount] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [balance, setBalance] = useState(0);
  const [alertStatus, setAlertStatus] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAmountValid, setIsAmountValid] = useState(true);
  const [name, setName] = useState(user?.firstname || "");
  const [email, setEmail] = useState(user?.email || "");
  const [contact, setContact] = useState("");
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState({
    isSuccess: false,
    message: "",
  });

  useEffect(() => {
    fetchWalletInfo();
  }, []);

  useEffect(() => {
    setName(user?.firstName || "");
    setEmail(user?.email || "");
  }, [user]);

  const fetchWalletInfo = async () => {
    try {
      const authToken = localStorage.getItem("access_token");
      await getWallet(authToken);
    } catch (error) {
      console.error("Error fetching wallet info:", error);
    }
  };

  const getWallet = async (authToken) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/payments/wallets`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const walletData = response.data.response;
      setBalance(walletData.balance);
    } catch (error) {
      console.log("Error getting wallet info:", error);
    }
  };

  const handleTransactionStatusModalClose = () => {
    setIsTransactionModalOpen(false);
  };

  const validateAndHandlePayment = () => {
    if (parseInt(creditAmount) < 1) {
      setIsAmountValid(false);
      return;
    }
    setIsAmountValid(true);
    handlePayment();
  };

  const onOpenModal = () => {
    onOpen();
    setAlertStatus(null);
    setAlertMessage("");
  };

  const handlePayment = async () => {
    const isRazorpayScriptLoaded = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!isRazorpayScriptLoaded) {
      alert("Failed to load Razorpay SDK. Please try again later.");
      return;
    }

    const paymentAmount = creditAmount * 1;
    const paymentData = {
      amount: paymentAmount,
      description: "Wallet recharge",
    };

    const initiateResponse = await dispatch(
      initiatePaymentRequestAsync(paymentData)
    ).unwrap();
    if (initiateResponse) {
      openRazorpayModal(initiateResponse.response.order, initiateResponse);
    } else {
      console.error("Failed to initiate payment");
    }
  };

  const pollingRef = useRef(null);

  const checkPaymentStatus = async (
    paymentResponse,
    maxDuration = 180000,
    interval = 10000
  ) => {
    const endTime = Date.now() + maxDuration;
    const transactionId = paymentResponse?.response?.transaction;
    if (!transactionId) {
      console.error("Transaction ID is undefined.");
      return;
    }

    const queryStatus = async () => {
      if (Date.now() >= endTime) {
        clearInterval(pollingRef.current);
        setTransactionStatus({
          isSuccess: false,
          message: "Timeout: Payment status could not be verified.",
        });
        setIsTransactionModalOpen(true);
        return;
      }

      try {
        const verificationResponse = await dispatch(
          getPaymentStatusAsync(transactionId)
        ).unwrap();

        if (verificationResponse.response.paymentStatus === "processing") {
        } else if (
          verificationResponse.response.paymentStatus === "succeeded"
        ) {
          clearInterval(pollingRef.current);
          setTransactionStatus({
            isSuccess: true,
            message: "YAY! Your transaction was successful!",
          });
          setIsTransactionModalOpen(true);
          fetchWalletInfo();
        } else {
          clearInterval(pollingRef.current);
          setTransactionStatus({
            isSuccess: false,
            message: "Please retry, your transaction has been failed!",
          });
          setIsTransactionModalOpen(true);
        }
      } catch (error) {
        console.error("Failed to fetch payment status", error);
        clearInterval(pollingRef.current);
      }
    };

    pollingRef.current = setInterval(queryStatus, interval);
    queryStatus();
  };

  const openRazorpayModal = (orderId, paymentResponse) => {
    onClose();
    setTimeout(() => {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        order_id: orderId,
        name: "NEXT AI",
        description: "Wallet Recharge",
        handler: async (response) => {
          setTimeout(() => {
            checkPaymentStatus(paymentResponse);
          }, 1000);
        },
        prefill: {
          name,
          email,
          contact,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert("Payment Failed: " + response.error.description);
      });

      rzp1.open();
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, []);

  return (
    <Box
      mb={10}
      bg={bgColor}
      p={5}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Stack spacing={4}>
        <Heading size="md">Overview</Heading>
        <Divider />
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text fontWeight="medium">Pay as you go</Text>
            <Text fontSize="2xl" fontWeight="semibold">
              ${parseFloat(balance).toFixed(3)}
            </Text>
            <Text fontWeight="medium" color="gray.500">
              Wallet Balance
            </Text>
          </Box>
          <Stack direction="row" spacing={2}>
            <Button onClick={onOpenModal} colorScheme="blue">
              Buy credits
            </Button>
          </Stack>
        </Flex>
        <Divider />
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text fontWeight="medium">Plan details</Text>
            <Text fontSize="2xl" fontWeight="semibold">
              {user?.currentPlan?.plan?.name || "Free"}
            </Text>
            <Text fontWeight="medium" color="gray.500">
              Expires at:{" "}
              {user?.currentPlan?.expiresAt
                ? new Date(user?.currentPlan?.expiresAt).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )
                : "Unlimited"}
            </Text>
          </Box>
          <Stack direction="row" spacing={2}>
            {/* <Button isActive={"false"} colorScheme="blue">
              Upgrade Plan
            </Button> */}
          </Stack>
        </Flex>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Buy Credits</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InputGroup mb={4}>
              <InputLeftAddon children="$" />
              <Input
                type="number"
                value={creditAmount}
                onChange={(e) => setCreditAmount(e.target.value)}
                placeholder="Enter amount"
                min={10}
                required
              />
            </InputGroup>
            {!isAmountValid && (
              <Text color="red.500" fontSize="xs" mt={-3}>
                Amount should be $10 or more.
              </Text>
            )}
            <HStack>
              <Input
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                mb={4}
                required
              />
              <Input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                mb={4}
                required
              />
            </HStack>
            <Input
              placeholder="Contact"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              required
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={validateAndHandlePayment}
            >
              Purchase
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isTransactionModalOpen}
        onClose={handleTransactionStatusModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {transactionStatus.isSuccess ? "Success" : "Failure"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign="center">
            {transactionStatus.isSuccess ? (
              <CheckCircleIcon w={8} h={8} color="green.500" />
            ) : (
              <WarningIcon w={8} h={8} color="red.500" />
            )}
            <Text mt={4}>{transactionStatus.message}</Text>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              colorScheme={transactionStatus.isSuccess ? "green" : "red"}
              mr={3}
              onClick={handleTransactionStatusModalClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {alertStatus && (
        <Alert status={alertStatus}>
          <AlertIcon />
          {alertMessage}
        </Alert>
      )}
    </Box>
  );
}

export default BillingSettingsComponent;
