import React from "react";
import { useSelector } from "react-redux";
import PlaygroundSidebar from "./PlaygroundSidebar";
import PlaygroundChatCompletion from "./PlaygroundChatCompletion";
import { Box, Flex } from "@chakra-ui/react";

const PlaygroundContainer = () => {
  const playgroundState = useSelector((state) => state.playground);

  return (
    <Box>
      <Flex gap={8}>
        <Box flex={1}>
          <PlaygroundSidebar />
        </Box>
        <Box flex={12} >
          <PlaygroundChatCompletion playgroundState={playgroundState} />
        </Box>
      </Flex>
    </Box>
  );
};

export default PlaygroundContainer;
