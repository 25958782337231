import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { Send } from "lucide-react";

const PlaygroundChatCompletion = () => {
  const [data, setData] = useState([
    {
      inputMessage: "",
      systemMessage: "",
      outputMessage:
        "Hello, I am your deployed model. Please enter the API Key above to begin our conversation!",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [systemMessage, setSystemMessage] = useState("");
  const [error, setError] = useState(null);
  const [token, setToken] = useState("");
  const messagesEndRef = useRef(null);

  const responseBgColor = useColorModeValue("gray.200", "gray.700");
  const inputBgColor = useColorModeValue("gray.100", "gray.800");
  const codeBlockBgColor = useColorModeValue("#f0f0f0", "gray.800"); 
  const codeBlockTextColor = useColorModeValue("#333333", "#F0F0F0"); 

  const { model, max_tokens, temperature, presence_penalty, top_p } =
    useSelector((state) => state.playground);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [data]);

  const requestBody = {
    model,
    max_tokens,
    temperature,
    presence_penalty,
    top_p,
    stream: true,
    messages: [
      {
        role: "system",
        content: systemMessage,
      },
      {
        role: "user",
        content: inputMessage,
      },
    ],
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    setData([
      ...data,
      {
        inputMessage,
        systemMessage,
        outputMessage: [],
      },
    ]);

    try {
      const response = await fetch(
        "https://flash.nextai.co.in/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 200) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        while (true) {
          const { value, done } = await reader.read();
          if (value) {
            const regexPattern = /"content":\s*"(.*?)"/g;
            const matches = Array.from(
              value.matchAll(regexPattern),
              (match) => match[1]
            );

            matches.forEach((element, index) => {
              const delay = ((index + 1) * 1000) / matches.length; // Adjust delay for smoother animation
              setTimeout(() => {
                setData((prevArray) => [
                  ...prevArray.slice(0, prevArray.length - 1),
                  {
                    ...prevArray[prevArray.length - 1],
                    outputMessage:
                      prevArray[prevArray.length - 1].outputMessage +
                      (element === "\\n" ? "<br/>" : element),
                  },
                ]);
              }, delay);
            });
          }
          if (done) break;
        }
      } else if (response.status === 403) {
        setError("Invalid Token");
      } else {
        setError("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      setError("An error occurred");
    } finally {
      setLoading(false);
      setInputMessage("");
      setSystemMessage("");
    }
  };

  return (
    <Box>
      <Box mb={4} alignItems={"center"}>
        <Text mb={2}>Enter Token</Text>
        <Input
          variant="filled"
          value={token}
          placeholder="Enter Token"
          onChange={(e) => setToken(e.target.value)}
          isDisabled={loading}
        />
      </Box>
      <Box mb={4} alignItems={"center"}>
        <Text mb={2}>System Message</Text>
        <Input
          variant="filled"
          value={systemMessage}
          placeholder="System Message"
          onChange={(e) => setSystemMessage(e.target.value)}
        />
      </Box>

      <VStack spacing={4} overflowY="auto" maxH="lg" p={4}>
        {data.map((item, index) => (
          <Flex key={index} direction="column" w="full">
            {item.inputMessage && (
              <Flex gap={2} alignItems="center" mb={2}>
                <Avatar
                  size="sm"
                  src="https://cdn.discordapp.com/attachments/1148930956158320730/1206586820540899368/Screenshot_2024-02-12_at_6.34.09_PM.png"
                />
                <Box flex="1">
                  {systemMessage !== "" && (
                    <Text bg={inputBgColor} p={3} borderRadius="md">
                      <b>System Message:</b> {item.systemMessage}
                    </Text>
                  )}
                  <Text bg={inputBgColor} p={3} borderRadius="md">
                    {item.inputMessage}
                  </Text>
                </Box>
              </Flex>
            )}

            <Flex gap={2} flexDirection="row-reverse">
              <Avatar
                size="sm"
                src="https://cdn-icons-png.flaticon.com/512/4829/4829581.png"
              />
              <Box
                bg={responseBgColor}
                p={3}
                borderRadius="md"
                maxW="70%"
                w="full"
                overflowX="auto"
                css={{
                  "& pre": {
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                    backgroundColor: codeBlockBgColor, 
                    color: codeBlockTextColor, 
                    padding: "15px",
                    borderRadius: "10px",
                    fontFamily: "monospace",
                    margin: "0",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    index === data.length - 1 && loading
                      ? item.outputMessage + "..."
                      : item.outputMessage.includes("```")
                      ? `<pre>${item.outputMessage.replace(/```/g, "")}</pre>`
                      : item.outputMessage,
                }}
              />
            </Flex>
          </Flex>
        ))}
        <div ref={messagesEndRef} />
      </VStack>

      <Flex gap={5} alignItems="center" mt={4}>
        <Input
          variant="filled"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && fetchData()}
          placeholder="Type your message..."
        />
        <Button
          onClick={fetchData}
          variant="outline"
          isDisabled={
            loading || token.trim() === "" || inputMessage.trim() === ""
          }
          isLoading={loading}
          loadingText="Wait..."
        >
          <Send />
        </Button>
      </Flex>
      {error && <Text color="red.500">{error}</Text>}
    </Box>
  );
};

export default PlaygroundChatCompletion;
