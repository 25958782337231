import { Box, Container, Heading } from "@chakra-ui/react";
import React from "react";
import CardContainer from "../../components/cardContainer/CardContainer";
import { FiSearch } from "react-icons/fi";
import { TbChartLine } from "react-icons/tb";
import { RiChatPrivateLine, RiCustomerService2Line } from "react-icons/ri";

const cardList = [
  {
    icon: <FiSearch />,
    title: "Search Mate",
    description:
      "Discover the perfect product with ease. Our Smart Search plugin, powered by cutting-edge AI, intuitively navigates customers to their desired items, enriching their shopping journey while boosting your sales and user satisfaction.",
    buttonText1: "Book a Demo",
    buttonText2: "",
    href1: "https://calendly.com/nextai_/30min?month=2023-11",
    href2: "/endpoints",
    openHref1InNewTab: true,
  },
  // {
  //   icon: <TbChartLine />,
  //   title: "Insight Sphere (Coming Soon)",
  //   description:
  //     "Unlock the power of data with our Data Analytics plugin. No more tedious data extraction get insights on demand with simple queries. Drive informed decisions swiftly, and stay a step ahead in your business game.",
  //   buttonText1: "",
  //   // buttonText2: "Demo",
  //   href1: "https://calendly.com/nextai_/30min?month=2023-11",
  //   // href2: "/endpoints",
  // },
  {
    icon: <RiChatPrivateLine />,
    title: "Private Text",
    description:
      "Engage with your sensitive data securely and personally using the PrivateText plugin. This tool integrates seamlessly with a variety of data sources, including GitHub, Notion, Confluence, Docs, and 15+ other platforms. It enhances productivity by fostering quick data discovery and acting as an efficient code co-pilot.",
    buttonText1: "Book a Demo",
    buttonText2: "",
    href1: "https://calendly.com/nextai_/30min?month=2023-11",
    href2: "/endpoints",
    openHref1InNewTab: true,
  },
  // {
  //   icon: <RiCustomerService2Line />,
  //   title: "Support Spark (Coming Soon)",
  //   description:
  //     "Transform your customer support with our AI-driven plugin. Let AI handle routine inquiries, providing instant, accurate responses, while freeing your human resources for more strategic, high-value interactions. Enhance customer satisfaction with prompt resolutions.",
  //   buttonText1: "",
  //   // buttonText2: "Demo",
  //   href1: "https://calendly.com/nextai_/30min?month=2023-11",
  //   // href2: "/endpoints",
  // },
];

function Plugins() {
  return (
    <Container maxW={"7xl"}>
      <Heading as="h1" size="xl" py={2}>
        Plug & Play tools
      </Heading>
      <Heading fontWeight={500} as="h2" size="md" py={0}>
        Discover Plug-and-Play AI Solutions Your Path to Smarter Business
      </Heading>
      <CardContainer cardList={cardList} />
    </Container>
  );
}

export default Plugins;
