import React from "react";
import SettingsForm from "../../components/settings/SettingsForm";
import { Container, Divider, Heading } from "@chakra-ui/react";
import ApiKeyManager from "../../components/settings/UserApiKeyManager";
import UserUsageMetrics from "../../components/UserUsageMetrics/UserUsageMetrics";

function Settings() {
  return (
    <Container maxW="7xl">
      <Heading as="h1" size="xl" py={1} pt={0}>
        Settings
      </Heading>
      <Heading fontWeight={400} as="h2" size="md" py={0} mb={12}>
        Change your account settings.
      </Heading>
      <SettingsForm />
      {/* <Divider mt={5}/>
      <Heading fontWeight={600} size="lg" mt={3} pt={0}>Generate Personal API Key</Heading>
      <Heading fontWeight={400} as="h2" size="sm" py={0} mb={0}>
        Your Personal API Keys for accessing public modals.
      </Heading>
      <ApiKeyManager/> */}
      {/* <UserUsageMetrics/> */}
    </Container>
  );
}

export default Settings;
