import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ModelCard from "../../components/model/ModelCard";
import {
  getModelsAsync,
  getSearchAsync,
} from "../../features/models/modelActions";
import {
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import Pagination from "../../components/Pagination/Pagination";

const Endpoints = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [size] = useState(9);
  const { models, loading, totalResults } = useSelector((state) => state.model);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showPagination, setShowPagination] = useState(true);
  const delayedSearch = useCallback(
    debounce((q) => dispatch(getSearchAsync(q)), 600),
    [dispatch]
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(totalResults / size);

  useEffect(() => {
    dispatch(getModelsAsync({ page: currentPage, size: size }));
  }, [dispatch, currentPage, size]);

  return (
    <Container maxW="7xl">
      <Heading as="h1" size="xl" py={2}>
        Flash Points
      </Heading>
      <Heading fontWeight={500} as="h2" size="md" pt={2}>
      Deploy fast, secure, and hardware-optimized open-source models at scale with ease.
      </Heading>
      <Input
        mt={3}
        type="text"
        variant='filled'
        placeholder="Search Models"
        value={searchKeyword}
        onChange={(e) => {
          if (e.target.value.trim().length <= 0) {
            dispatch(getModelsAsync({ page: currentPage, size: size }));
            setShowPagination(true);
          } else {
            setShowPagination(false);
            delayedSearch(e.target.value);
          }
          setSearchKeyword(e.target.value);
        }}
      />

      {loading ? (
        <Box textAlign="center" py={6}>
          <Spinner size="xl" />
        </Box>
      ) : (
        <Grid
          py={6}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
          ]}
          gap={6}
        >
          {models?.length > 0 ? (
            models.map((model,index) => (
              <GridItem key={index}>
                <ModelCard  model={model} />
              </GridItem>
            ))
          ) : (
            <Heading as="h2" fontWeight={400} size="md" mb={2} py={0}>
              No models found.
            </Heading>
          )}
        </Grid>
      )}

      {showPagination && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Container>
  );
};

export default Endpoints;
