import { createSlice } from "@reduxjs/toolkit";
import { createApiKeyAsync, getApiKeysAsync, revokeApiKeyAsync } from "./apiAction";

const apiSlice = createSlice({
  name: "api",
  initialState: {
    apiKeys: [],
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    resetApi: (state) => {
      state.apiKeys = [];
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createApiKeyAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createApiKeyAsync.fulfilled, (state, action) => {
        state.loading = false;
        const newApiKey = action.payload.response;
        state.apiKeys = [...state.apiKeys, newApiKey];
      })
      .addCase(createApiKeyAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getApiKeysAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApiKeysAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.apiKeys = action.payload;
      })
      .addCase(getApiKeysAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(revokeApiKeyAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(revokeApiKeyAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(revokeApiKeyAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetApi } = apiSlice.actions;
export default apiSlice.reducer;
