import React from 'react';
import {
  Box,
  Text,
  Badge,
  VStack,
  HStack,
  Avatar,
  useColorModeValue,
  SimpleGrid,
  Link,
  useColorMode,
  Card
} from '@chakra-ui/react';

const cardBackgrounds = [
  'linear(to-tr, teal.50, blue.100)',
  'linear(to-t, orange.50, pink.100)',
  'linear(to-b, green.50, teal.100)',
  'linear(to-br, teal.50, green.100)',
  'linear(to-br, teal.50, green.100)'
];

const cardBackgroundsDark = [
  'linear(to-tr, teal.500, blue.600)',
  'linear(to-t, orange.500, pink.600)',
  'linear(to-b, green.500, teal.600)',
  'linear(to-br, teal.500, green.600)',
  'linear(to-br, teal.500, green.600)'
];

const APICard = ({ title, author, authorImage, date, tags, href, index }) => {
  const { colorMode } = useColorMode();
  const bgGradient = colorMode === 'light'
    ? cardBackgrounds[index % cardBackgrounds.length]
    : cardBackgroundsDark[index % cardBackgroundsDark.length];
  
  return (
    <Link href={href} isExternal _hover={{ textDecoration: 'none' }}>
      <Card
        p={4}
        variant='outline'
        borderRadius='lg'
        bgGradient={bgGradient}
        border='1px solid'
        borderColor={useColorModeValue('gray.200', 'gray.600')}
        w="full"
        h="full"
      >
        <VStack align='start' spacing={3}>
          <Text fontWeight="bold" fontSize="md">{title}</Text>
          <HStack mt={2}>
            <Avatar name={author} src={authorImage} size='sm' />
            <VStack align='start' spacing='0px'>
              <Text fontSize="md">{author}</Text>
              <Text fontSize="xs" color={useColorModeValue('gray.600', 'gray.400')}>{date}</Text>
            </VStack>
          </HStack>
          <HStack mt={5} spacing={2}>
            {tags.map((tag, tagIndex) => (
              <Badge key={tagIndex} colorScheme='purple' px={2} borderRadius='full'>{tag}</Badge>
            ))}
          </HStack>
        </VStack>
      </Card>
    </Link>
  );
};

const APICardsGrid = ({ apis }) => {
  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
      {apis.map((api, index) => (
        <APICard
          key={index}
          index={index}
          title={api.title}
          author={api.author}
          authorImage={api.authorImage}
          date={api.date}
          tags={api.tags}
          href={api.link}
        />
      ))}
    </SimpleGrid>
  );
};

const Cookbook = () => {
    const apiData = [
        {
          title: 'Integrating NextAI with Langchain: Enhanced Document Processing',
          author: 'Tanmay Saxena',
          authorImage: '/path/to/kai-chen-avatar.jpg', 
          date: 'JAN 14, 2024',
          tags: ['COMPLETIONS', 'LANGCHAIN'],
          link: 'https://medium.com/@tanmay4803/integrating-nextai-with-langchain-enhanced-document-processing-49185f4655b0'
        },
        {
          title: 'Integrating NextAI with LlamaIndex: Enhanced Document Processing',
          author: 'Tanmay Saxena',
          authorImage: '/path/to/kai-chen-avatar.jpg', 
          date: 'JAN 16, 2024',
          tags: ['COMPLETIONS', 'LLAMAINDEX'],
          link: 'https://medium.com/@tanmay4803/integrating-nextai-with-llamaindex-enhanced-document-processing-adc6851e85fc'
        },
        {
          title: 'Playing with AutoGen with NextAI: Exploring the Conversational Frontier',
          author: 'Tanmay Saxena',
          authorImage: '/path/to/kai-chen-avatar.jpg', 
          date: 'JAN 12, 2024',
          tags: ['COMPLETIONS', 'AUTOGEN','BOT'],
          link: 'https://medium.com/@tanmay4803/playing-with-autogen-with-nextai-64ffb640e427'
        },
        {
          title: 'Unlocking the Power of Conversational AI: Talk to Websites with NextAI, LangChain, and ChromaDB',
          author: 'Tanmay Saxena',
          authorImage: '/path/to/kai-chen-avatar.jpg', 
          date: 'JAN 10, 2024',
          tags: ['COMPLETIONS', 'LANGCHAIN','BOT'],
          link: 'https://medium.com/@tanmay4803/unlocking-the-power-of-conversational-ai-talk-to-websites-with-nextai-langchain-and-chromadb-76ab31d3e91c'
        },
    ];

  return (
    <Box p={10}>
      <APICardsGrid apis={apiData} />
    </Box>
  );
};

export default Cookbook;
