import React, { useState, useEffect } from 'react';
import { Container, Heading, FormControl, FormLabel, Select, Box, Text, Divider } from '@chakra-ui/react';
import ApiKeyPage from '../../components/api/table';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClustersAsync } from '../../features/clusters/clusterAction';
import { useLocation } from 'react-router-dom';
import ApiKeyManager from '../../components/settings/UserApiKeyManager';

function Api() {
  const location = useLocation();
  const dispatch = useDispatch();
  const clusters = useSelector((state) => state.cluster.clusters);
  const [selectedCluster, setSelectedCluster] = useState('');

  useEffect(() => {
    dispatch(getAllClustersAsync());
  }, [dispatch]);

  useEffect(() => {
    if (location.state?.cluster) {
      setSelectedCluster(location.state.cluster.uuid);
    }
  }, [location.state?.cluster]);

  if (!clusters) {
    return <div>Loading...</div>; 
  }

  const excludedStatuses = ['TERMINATED', 'FAILED_T0_START', 'ERRORED'];
  const activeClusters = clusters.filter((cluster) => !excludedStatuses.includes(cluster.status));

  return (
    <Container maxW="7xl" mb={30}>
      <Heading as="h1" size="xl" mt={-6} py={2}>
        API Keys
      </Heading>
      <Heading fontWeight={400} as="h3" size="xs" mt={-2} py={0}>
      Please note that we do not
        display your secret API keys again after you generate them. Do not share
        your API key with others, or expose it in the browser or other
        client-side code. In order to protect the security of your account,
        NextAI may also automatically disable any API key that we've found has
        leaked publicly.
      </Heading>

      <Divider mt={5}/>
      <Heading fontWeight={600} size="lg" mt={3} pt={0}>Generate Personal API Key</Heading>
      <Heading fontWeight={400} as="h2" size="xs" py={0} mb={0}>
        Your Personal API Keys for accessing public models.
      </Heading>

      <ApiKeyManager/>

      <Divider mt={5}/>
      <Heading fontWeight={600} size="lg" mt={3} pt={0}>Cluster Specific API Key</Heading>
      <Heading fontWeight={400} as="h2" size="xs" py={0} mb={0}>
        Your Personal API Keys for accessing private/deployed models.
      </Heading>

      <FormControl mt={4} mb={4}>
        <FormLabel>Choose Cluster</FormLabel>
        <Select
          variant='filled'
          placeholder="Select Cluster"
          value={selectedCluster}
          onChange={(e) => setSelectedCluster(e.target.value)}
        >
          {activeClusters.map((cluster) => (
            <option key={cluster.uuid} value={cluster.uuid}>
              {cluster.name}
            </option>
          ))}
        </Select>
      </FormControl>

      {activeClusters.length === 0 ? (
        <Text color="red.500">No Cluster Available</Text>
      ) : !selectedCluster ? (
        <Text color="blue.500">Select Cluster to view its API Keys</Text>
      ) : (
        <Box mt={2} mb={4}>
          <ApiKeyPage selectedCluster={selectedCluster} />
        </Box>
      )}
    </Container>
  );
}

export default Api;
