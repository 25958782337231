import axios from "axios";
const url = process.env.REACT_APP_API_URL;


export const getModels = async (page = 1, size = 9, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(`${url}/models?page=${page}&size=${size}`, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const searchModels = async (keyword, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(`${url}/models/?keyword=${keyword}`, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

