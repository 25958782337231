import React from "react";
import { useSelector } from "react-redux";
import Loading from "../../pages/Loading/Loading";

function PublicLayout({ children }) {
  const {access_token} = useSelector((state) => state.auth);
  return access_token ? <Loading /> : <>{children}</>;
}

export default PublicLayout;
