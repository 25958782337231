import React, { useCallback, useEffect, useState } from "react";
import UsageMetrics from "../../components/UsageDetails/UsageMetrics";
import { useDispatch, useSelector } from "react-redux";
import { getAllClustersAsync } from "../../features/clusters/clusterAction";
import { Box, Spinner } from "@chakra-ui/react";
import ClustersMenu from "../../components/ClustersMenu/ClustersMenu";
import UserUsageMetrics from "../../components/UserUsageMetrics/UserUsageMetrics";
import { useLocation } from "react-router-dom";

function Usage() {
  const location = useLocation();
  const { clusters, loading } = useSelector((state) => state.cluster);
  const [selectedCluster, setSelectedCluster] = useState(
    location?.state?.cluster || null
  );
  const dispatch = useDispatch();

  const handleClusterSelect = useCallback((cluster) => {
    setSelectedCluster(cluster);
  }, []);

  useEffect(() => {
    dispatch(getAllClustersAsync());
  }, [dispatch]);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <Box>
          <UserUsageMetrics/>
          <ClustersMenu
            clusters={clusters}
            handleClusterSelect={handleClusterSelect}
            selectedCluster={selectedCluster}
          />
          <Box mt={4}>
            {selectedCluster && <UsageMetrics cluster={selectedCluster} />}
          </Box>
        </Box>
      )}
    </div>
  );
}

export default Usage;
