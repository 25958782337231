import React, { useState, useCallback } from "react";
import {
  Button,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  Box,
  Text,
  Input,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  Icon,
  useColorMode,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ColorModeValue,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Papa from "papaparse";
import { FaFileCsv, FaFile } from "react-icons/fa";
import { parseJsonl } from "./fileParse";
import { useDispatch } from "react-redux";
import { uploadDatasetAsync } from "../../features/dataset/datasetAction";

const ButtonUpload = () => {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataType, setDataType] = useState("");
  const [fileType, setFileType] = useState("csv");
  const [customFileName, setCustomFileName] = useState("");
  const [fileInfo, setFileInfo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [columns, setColumns] = useState([]);
  const [instructionColumnName, setInstructionColumnName] = useState("");
  const [inputColumnName, setInputColumnName] = useState("");
  const [outputColumnName, setOutputColumnName] = useState("");
  const [completionText, setCompletionText] = useState("");
  const [shareGPTColumnName, setShareGPTColumnName] = useState("");

  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        toast.error("No file selected.");
        return;
      }
  
      setSelectedFile(file);
  
      if (!file) {
        toast.error("No file selected.");
        return;
      }
  
      const fileExtension = file.name.split(".").pop();
      if (
        (fileType === "csv" && fileExtension !== "csv") ||
        (fileType === "jsonl" && fileExtension !== "jsonl")
      ) {
        toast.error("Invalid file type selected.");
        return;
      }
  
      setCustomFileName(file.name);
  
      // Process CSV file
      if (fileType === "csv") {
        Papa.parse(file, {
          complete: (result) => {
            const rows = result.data.length;
            const columns = rows > 0 ? result.data[0].length : 0;
            const shape = rows > 0 ? `${rows} rows x ${columns} columns` : 'Unknown';
            setFileInfo({
              name: customFileName || file.name,
              shape: shape,
              icon: FaFileCsv,
            });
            const columnsFromCsv = result.data[0];
            setColumns(columnsFromCsv);
            toast.success(`${file.name} uploaded and parsed successfully.`);
          },
        });
      } else if (fileType === "jsonl") {
        parseJsonl(
          file,
          (data) => {
            const shape = `Objects: ${data.count}`;
            setFileInfo({
              name: customFileName || file.name,
              shape: shape,
              icon: FaFile,
            });
            toast.success(`${file.name} uploaded and parsed successfully.`);
          },
          (error) => {
            console.error("JSONL parsing error:", error);
            toast.error("Failed to parse JSONL file.");
          }
        );
      }
    },
    [fileType, customFileName]
  );
  

  const { getInputProps, open } = useDropzone({
    onDrop,
    accept: fileType === "csv" ? ".csv" : ".jsonl",
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const handleFileTypeChange = (e) => {
    setFileType(e);

    setCustomFileName("");
    setSelectedFile(null);
    setFileInfo(null);
  };

  const renderExample = () => {
    if (!dataType) return null;

    let exampleContent;

    if (dataType === "alpaca") {
      exampleContent = (
        <Table
          variant="simple"
          size="sm"
          colorScheme={colorMode === "light" ? "gray" : "dark"}
        >
          <Thead>
            <Tr>
              <Th border="1px solid">Instruction</Th>
              <Th border="1px solid">Input</Th>
              <Th border="1px solid">Output</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td border="1px solid">Translate English to French</Td>
              <Td border="1px solid">Hello, world!</Td>
              <Td border="1px solid">Bonjour le monde!</Td>
            </Tr>
          </Tbody>
        </Table>
      );
    } else if (dataType === "completion") {
      exampleContent = (
        <Table
          variant="simple"
          size="sm"
          colorScheme={colorMode === "light" ? "gray" : "dark"}
        >
          <Thead>
            <Tr>
              <Th border="1px solid">Text</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td border="1px solid">Enter the necessary Text</Td>
            </Tr>
          </Tbody>
        </Table>
      );
    } else if (dataType === "sharegpt") {
      exampleContent = (
        <Table
          variant="simple"
          size="sm"
          colorScheme={colorMode === "light" ? "gray" : "dark"}
        >
          <Thead>
            <Tr>
              <Th border="1px solid">From</Th>
              <Th border="1px solid">Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td border="1px solid">human</Td>
              <Td border="1px solid">Hello, how can you assist me today?</Td>
            </Tr>
            <Tr>
              <Td border="1px solid">gpt</Td>
              <Td border="1px solid" style={{ whiteSpace: "pre-wrap" }}>
                "I can provide information, answer questions, and more. How may
                I help you?"
              </Td>
            </Tr>
          </Tbody>
        </Table>
      );
    }

    return (
      <Box
        mt={4}
        borderRadius="md"
        p={4}
        bg={colorMode === "light" ? "gray.100" : "gray.700"}
      >
        <Text color={colorMode === "light" ? "gray.800" : "white"} mb={2}>
          Example for {dataType}:
        </Text>
        {exampleContent}
      </Box>
    );
  };

  const showFinalUploadButton =
    (dataType === "alpaca" &&
      fileInfo &&
      instructionColumnName &&
      inputColumnName &&
      outputColumnName) ||
    (dataType === "completion" && fileInfo && completionText) ||
    (dataType === "sharegpt" && fileInfo && shareGPTColumnName);

  const handleFinalUpload = () => {
    if (!dataType || !selectedFile || !fileInfo) {
      toast.error("Please select file and data type.");
      return;
    }

    const uploadData = {
      file: selectedFile,
      name: customFileName,
      description: fileInfo.name,
      type: dataType,
    };

    dispatch(uploadDatasetAsync(uploadData))
      .then(() => {
        toast.success("Dataset uploaded successfully!");
        onClose();
      })
      .catch((error) => {
        console.error("Error uploading dataset:", error);
        toast.error("Failed to upload dataset.");
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        bg="#4E4FEB"
        color="white"
        _hover={{ bg: "brand.secondary" }}
      >
        Select Data Type
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent size="full">
          <ModalHeader>Upload Dataset</ModalHeader>
          <ModalCloseButton />
          <ModalBody bg={colorMode === "light" ? "white" : "gray.800"}>
            <FormControl>
              <FormLabel>Select Data Type</FormLabel>
              <Select
                placeholder="Select data type"
                onChange={(e) => setDataType(e.target.value)}
                value={dataType}
              >
                <option value="alpaca">Alpaca</option>
                <option value="sharegpt">ShareGPT</option>
                <option value="completion">Completion</option>
              </Select>
            </FormControl>

            {renderExample()}

            <RadioGroup onChange={handleFileTypeChange} value={fileType} mt={4}>
              <Stack direction="row">
                <Radio value="csv">CSV</Radio>
                <Radio value="jsonl">JSONL</Radio>
              </Stack>
            </RadioGroup>

            <HStack mt={4}>
              <Input
                placeholder="Enter custom file name (optional)"
                value={customFileName}
                onChange={(e) => setCustomFileName(e.target.value)}
              />
              <Button onClick={open} color="white" background={"brand.primary"}>
                Add File
              </Button>
            </HStack>

            <input {...getInputProps()} style={{ display: "none" }} />

            {fileInfo && (
              <Box mt={4} display="flex" alignItems="center">
                <Icon as={fileInfo.icon} mr={2} />
                <Text>
                  {fileInfo.name} {fileInfo.shape && `- ${fileInfo.shape}`}
                </Text>
              </Box>
            )}
            {dataType === "alpaca" && fileInfo && (
              <>
                <FormControl mt={4}>
                  <FormLabel fontWeight={"bold"}>Instruction Text</FormLabel>
                  <Input
                    placeholder="Enter instruction text"
                    value={instructionColumnName}
                    onChange={(e) => setInstructionColumnName(e.target.value)}
                  />
                </FormControl>

                <Text mt={4} mb={2} fontWeight="bold">
                  Map Your Data Columns:
                </Text>

                <Flex alignItems="center" mt={2}>
                  <FormLabel htmlFor="input-column" mb="0" w="40%">
                    Input Column
                  </FormLabel>
                  <Select
                    id="input-column"
                    placeholder="Select input column"
                    onChange={(e) => setInputColumnName(e.target.value)}
                    flexGrow={1}
                  >
                    {columns.map((col, index) => (
                      <option key={index} value={col}>
                        {col}
                      </option>
                    ))}
                  </Select>
                </Flex>

                <Flex alignItems="center" mt={4}>
                  <FormLabel htmlFor="output-column" mb="0" w="40%">
                    Output Column
                  </FormLabel>
                  <Select
                    id="output-column"
                    placeholder="Select output column"
                    onChange={(e) => setOutputColumnName(e.target.value)}
                    flexGrow={1}
                  >
                    {columns.map((col, index) => (
                      <option key={index} value={col}>
                        {col}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </>
            )}

            {dataType === "completion" && fileInfo && (
              <FormControl mt={4}>
                <FormLabel>Text</FormLabel>
                <Textarea
                  placeholder="Enter text"
                  value={completionText}
                  onChange={(e) => setCompletionText(e.target.value)}
                />
              </FormControl>
            )}
            {dataType === "sharegpt" && fileInfo && (
              <>
                <Text mt={4} fontSize="lg" fontWeight="bold">
                  Conversation
                </Text>
                <Flex alignItems="center" mt={4}>
                  <FormLabel htmlFor="sharegpt-column" mb="0" w="40%">
                    Select Column
                  </FormLabel>
                  <Select
                    id="sharegpt-column"
                    placeholder="Select column"
                    onChange={(e) => setShareGPTColumnName(e.target.value)}
                    flexGrow={1}
                  >
                    {columns.map((col, index) => (
                      <option key={index} value={col}>
                        {col}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </>
            )}

            <ModalFooter>
              <Flex width="full" justifyContent="flex-start">
                <Button
                  colorScheme="gray"
                  onClick={onClose}
                  style={{ marginRight: "16px" }}
                >
                  Close
                </Button>

                {showFinalUploadButton && (
                  <Button colorScheme="blue" onClick={handleFinalUpload}>
                    Final Upload
                  </Button>
                )}
              </Flex>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ButtonUpload;
