import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  useColorModeValue,
  Flex,
  VStack,
  Image,
  Badge,
  HStack,
  Divider,
  LinkBox,
  LinkOverlay,
  Card,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import HomeContainer from "../cardContainer/HomeContainer";
import { PiLightning } from "react-icons/pi";
import { BsPlug } from "react-icons/bs";
import {
  CloudLightning,
  Rocket,
  Package,
  Cpu,
  Computer,
  CircuitBoard,
  BrainCog,
  ExternalLink,
} from "lucide-react";
import { TbCube } from "react-icons/tb";
import GetStarted from "./getStarted";
import Image2 from "../../assets/images/grad1.jpg";
import Image1 from "../../assets/images/grad2.jpg";
import { ArrowForwardIcon } from "@chakra-ui/icons";

const cardList = [
  {
    icon: <PiLightning />,
    title: "Flash Points",
    description:
      "Efficiently deploy AI models in just minutes, tailored for the fastest inference and designed to scale automatically",
    buttonText1: "Get Started",
    href1: "/endpoints",
  },
  {
    icon: <BsPlug />,
    title: "Plug & Play Tools",
    description:
      "Deploy AI-powered information retrieval applications with high performance and precision effortlessly through our fully-managed service, accessible with just a few clicks",
    buttonText1: "Get Started",
    href1: "/plugins",
  },
  // {
  //   icon: <TbCube />,
  //   title: "Model Forge (Coming Soon)",
  //   description:
  //     "Streamline AI development with ease - fine-tune, quantize, and deploy high-performance models more efficiently",
  //   buttonText1: "Get Started",
  //   href1: "/finetune",
  // },
];

export default function HeroSection() {
  return (
    <Box>
      <Stack
        as={Box}
        textAlign="center"
        spacing={{ base: 8, md: 2 }}
        pt={{ base: 4, md: 1 }}
        pb={{ base: 4, md: 8 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
          lineHeight="100%"
        >
          Simplest Way to Access GenAI <br />
          <Text
            as="span"
            color={useColorModeValue("brand.primary", "brand.primaryDark")}
          >
            for Intelligent Apps
          </Text>
        </Heading>
        <Text pr={20} pl={20}>
        Explore the possibilities of NextAI. Try our ready-to-use model APIs or deploy models with our optimized compute to start building your AI app immediately
        </Text>
      </Stack>
      <Container maxW="7xl">
        {/* <GetStarted /> */}
        <HStack spacing={4} align="stretch">
          <LinkBox
            as={Box}
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            position="relative"
            _hover={{ shadow: "md" }}
            role="group"
          >
            <Image src={Image2} alt="NextAPI" height={"210px"} w={"100%"} objectFit={"cover"}/>
            <Box p="6">
              <Box d="flex" alignItems="baseline">
                <Badge borderRadius="full" px="2" colorScheme="teal">
                  Endpoints
                </Badge>
                <Box fontSize="xl" textTransform="uppercase">
                  <HStack>
                    <Rocket strokeWidth={3} />
                    <Text fontWeight={600}> NextAI API </Text>
                  </HStack>
                </Box>
              </Box>
              <Divider mt={2} mb={2} />

              <Box mt="1" fontWeight="semibold" as="h4" fontSize={14}>
                Integrate LLM’s into your application with ease and 10x faster
                with NextAI API and reduce your dependency on closed models.
              </Box>
              <Button
                rightIcon={<CircuitBoard size={16} />}
                colorScheme="blue"
                variant="outline"
                mt={4}
                size={"sm"}
              >
                Use API
              </Button>
            </Box>
            <LinkOverlay href="/playground" />
          </LinkBox>

          <LinkBox
            as={Box}
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            position="relative"
            _hover={{ shadow: "md" }}
            role="group"
          >
            <Image src={Image1} alt="Flash Points" height={"210px"} w={"100%"} objectFit={"cover"}/>
            <Box p="6">
              <Box d="flex" alignItems="baseline">
                <Badge borderRadius="full" px="2" colorScheme="teal">
                  Compute
                </Badge>
                <Box fontSize="xl" textTransform="uppercase">
                  <HStack>
                    <CloudLightning strokeWidth={3} />
                    <Text fontWeight={600}>Flash Points </Text>
                  </HStack>
                </Box>
              </Box>
              <Divider mt={2} mb={2} />

              <Box mt="1" fontWeight="semibold" as="h4" fontSize={14}>
                Efficiently deploy AI models in just minutes, tailored for the
                fastest inference and designed to scale automatically
              </Box>
              <Button
                rightIcon={<Computer size={16} />}
                colorScheme="blue"
                variant="outline"
                mt={4}
                size={"sm"}
              >
                Deploy Now
              </Button>
            </Box>
            <LinkOverlay href="/endpoints" />
          </LinkBox>
          {/* NextAPI Card */}

          {/* Compute Card */}
          <LinkBox
            as={Box}
            maxW="sm"
            minW={360}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            position="relative"
            _hover={{ shadow: "md" }}
            role="group"
          >
            <Image
              src="https://img.freepik.com/free-photo/3d-low-poly-landscape-background-with-connecting-lines-dots_1048-10643.jpg?size=626&ext=jpg&ga=GA1.1.1563851258.1694609348&semt=ais"
              alt="Finetune"
              height="210px"
              w={"100%"}
              objectFit={"cover"}
            />
            <Box p="6">
              <Box d="flex" alignItems="baseline">
                <Badge borderRadius="full" px="2" colorScheme="teal">
                  Pro
                </Badge>
                <Box fontSize="xl" textTransform="uppercase">
                  <HStack>
                    <Package strokeWidth={3} />
                    <Text fontWeight={600}> Finetune </Text>
                  </HStack>
                </Box>
              </Box>
              <Divider mt={2} mb={2} />

              <Box mt="1" fontWeight="semibold" as="h4" fontSize={14}>
                Easily manage your model's fine-tuning to triple its
                performance, ensuring faster and more reliable results.
              </Box>
              <Button
                rightIcon={<BrainCog size={16} />}
                colorScheme="blue"
                variant="outline"
                mt={4}
                size={"sm"}
              >
                Finetune Model
              </Button>
            </Box>
            <LinkOverlay href="/finetune" />
          </LinkBox>
        </HStack>
        <Box mt={5} mb={5}>
          <Card
            data-type="Card"
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            bg={useColorModeValue("brand.textPrimaryDark", "gray.800")}
          >
            <Stack data-type="Stack">
              <CardBody data-type="CardBody" bg={useColorModeValue("brand.textPrimaryDark", "gray.800")}>
                <Heading data-type="Heading" size="sm">
                  🚀 Ready to use NextAI?
                </Heading>
                <HStack>
                  <Button
                    mt={2}
                    data-type="Button"
                    variant="outline"
                    colorScheme="black"
                    as="a"
                    href="/cookbook"
                    size={"sm"}
                  >
                    Explore Examples Apps
                  </Button>

                  <Text data-type="Text" mt={3} fontSize={12}>
                    or
                  </Text>
                  <a
                    href="https://www.youtube.com/watch?v=FG9_0AiGt0E"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Text
                      data-type="Text"
                      ml={-1}
                      mt={3}
                      fontWeight={600}
                      fontSize={14}
                    >
                      Learn how to deploy a custom model
                    </Text>
                  </a>
                  <Text
                    data-type="Text"
                    ml={-1}
                    mt={3}
                    fontWeight={600}
                    fontSize={8}
                  >
                    <ExternalLink size={16} />
                  </Text>
                </HStack>
              </CardBody>
            </Stack>
          </Card>
        </Box>
        {/* <HomeContainer cardList={cardList} /> */}
      </Container>
    </Box>
  );
}
