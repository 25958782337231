import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export const createFineTuningJobApi = async (jobData, thunkAPI) => {
    try {
        const response = await axios.post(`${BASE_URL}/fine-tune/jobs`, jobData, {
            headers: {
                authorization: `Bearer ${thunkAPI.getState().auth.access_token}`,
            },
        });
        return response.data.response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
    }
};

export const fetchAllFineTuningJobsApi = async (thunkAPI) => {
    try {
        const response = await axios.get(`${BASE_URL}/fine-tune/jobs`, {
            headers: {
                authorization: `Bearer ${thunkAPI.getState().auth.access_token}`,
            },
        });
        return response.data.response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
    }
};

export const fetchFineTuningJobApi = async (jobId, thunkAPI) => {
    try {
        const response = await axios.get(`${BASE_URL}/fine-tune/jobs/${jobId}`, {
            headers: {
                authorization: `Bearer ${thunkAPI.getState().auth.access_token}`,
            },
        });
        return response.data.response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
    }
};
