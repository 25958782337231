import { Container, Heading , Flex } from "@chakra-ui/react";
import React from "react";
import PaymentMethodModal from "../../components/AddPayment/AddCard";
import PaymentMethodsComponent from "../../components/AddPayment/PaymentMethod";

function AddPayment() {
  return (
    <Container maxW="7xl">
      <Heading as="h1" size="xl" py={0}>
       Add Your Payment Method
      </Heading>
      <Heading fontWeight={500} as="h2" size="md" py={0}>
       To complete your billing and deploy models, you need to add a payment method.
      </Heading>
      <Flex mt={3} direction="column" align="start">
        <PaymentMethodModal/>
        <PaymentMethodsComponent/>
      </Flex>
    </Container>
  );
}

export default AddPayment;