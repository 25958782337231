import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Chart } from "../../components/chart/Chart";
import { MdPayments } from "react-icons/md";
import { SlidersHorizontal, FileClock } from "lucide-react";
import BillingSettingsComponent from "../../components/billingSetting/billingSetting";
import TransactionActivity from "../Transactions/TransactionActivity";
import TransactionsMetricsChart from "../../components/UsageDetails/TransactionChat";

function UsageAndBillings() {
  const navigate = useNavigate();
  const handlePaymentGatewayClick = () => {
    navigate("/payment-methods");
  };

  const gridItemStyle = {
    p: 6,
    border: "1px solid",
    borderColor: useColorModeValue("gray.300", "gray.700"),
    rounded: "md",
    bg: useColorModeValue("white", "gray.800"),
    _hover: {
      color: useColorModeValue("brand.primary", "brand.primary"),
      cursor: "pointer",
      transform: "scale(1.05)",
      boxShadow: "lg",
    },
    transition: "all 0.3s ease-in-out",
  };

  return (
    <Container maxW="7xl">
      <Heading as="h1" size="xl" py={2}>
        Billings
      </Heading>
      <Heading fontWeight={500} as="h2" size="md" py={0}>
        Below you'll find a summary of wallet balance, purchase credits and payment methods of your organization. 
      </Heading>
      <Box mt={5}>
        <BillingSettingsComponent />
      </Box>
      {/* <Box m={10}>
        <Chart />
      </Box> */}
      <Box m={0}>
        <TransactionActivity/>
        <TransactionsMetricsChart/>
      </Box>
      {/* <Box maxW="7xl" py={6}>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <GridItem {...gridItemStyle} onClick={handlePaymentGatewayClick}>
            <Flex gap={6} align="center">
              <Icon as={MdPayments} w={10} h={10} />
              <Box>
                <Heading as="h3" size="md" py={2}>
                  Payment Gateway
                </Heading>
                <Heading as="h4" size="sm" py={2}>
                  Add or change your payment method
                </Heading>
              </Box>
            </Flex>
          </GridItem>
          <GridItem {...gridItemStyle}>
            <Flex gap={6} align="center">
            <FileClock strokeWidth={3} />
              <Box>
                <Heading as="h3" size="md" py={2}>
                  Billing History
                </Heading>
                <Heading as="h4" size="sm" py={2}>
                  View past and current invoices
                </Heading>
              </Box>
            </Flex>
          </GridItem>
          <GridItem {...gridItemStyle}>
            <Flex gap={6} align="center">
            <SlidersHorizontal strokeWidth={3} />
              <Box>
                <Heading as="h3" size="md" py={2}>
                  Usage Limits
                </Heading>
                <Heading as="h4" size="sm" py={2}>
                  Set monthly limits on your API usage
                </Heading>
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      </Box> */}
    </Container>
  );
}

export default UsageAndBillings;
