import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Text,
  Input,
  Button,
  Code,
  useColorModeValue,
  Select,
  VStack,
  Flex,
  Slider,
  SliderTrack,
  SliderThumb,
  Heading,
  SliderFilledTrack,
  Textarea,
  Checkbox,
  Card,
  CardBody,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  CardHeader,
  CardFooter,
  Switch,
  Stack,
  Divider,
} from "@chakra-ui/react";
import GetStartedCode from "../deploy/codes/getstarted";
import Component from "./listitem";
import Cookbook from "./cookbook";
import PlaygroundContainer from "./PlaygroundContainer";
import Neptune from "../finetune/neptune";

function Playground() {
  const [text, setText] = useState("");
  const [responseChunks, setResponseChunks] = useState([]);
  const [model, setModel] = useState("Llama");
  const [temperature, setTemperature] = useState(0);
  const [maxTokens, setMaxTokens] = useState(1000);
  const [showSystemPrompt, setShowSystemPrompt] = useState(false);
  const [streamingResponse, setStreamingResponse] = useState("");
  const [loading, setLoading] = useState("");

  const handleModelChange = (e) => {
    setModel(e.target.value);
  };

  const handleSubmit = () => {
    const apiEndpoint =
      model === "Llama"
        ? "https://llama.nextai.co.in/v1/chat/completions"
        : "https://mistral.nextai.co.in/v1/chat/completions";

    const requestData = {
      model: `${model}`,
      messages: [{ role: "user", content: text }],
      stream: "true",
    };

    let reader;

    fetch(apiEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        reader = response.body.getReader();
        return reader.read();
      })
      .then(function readChunk(result) {
        if (result.done) {
          console.log("Complete Response:", responseChunks.join(""));

          responseChunks.forEach((chunk) => {
            try {
              const parsedMessage = JSON.parse(chunk);
              const content =
                parsedMessage.choices[0]?.delta?.content[0] || "No content";
              setStreamingResponse((prevResponse) => prevResponse + content);
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
          });
          setResponseChunks([]);
        } else {
          const decoder = new TextDecoder("utf-8");
          const chunk = decoder.decode(result.value, { stream: true });
          setResponseChunks((prevChunks) => [...prevChunks, chunk]);

          return reader.read().then(readChunk);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <Flex h="100vh">
      <Box ml={-10} w="300px" borderRight="1px" borderColor="gray.200" p={4}>
        <VStack align="stretch" spacing={4}>
          <Card>
            <CardHeader mb={-5}>
              <Heading mb={0} size="md">
                Auto Model
              </Heading>
            </CardHeader>
            <CardBody>
              <Stack direction="row">
                <Switch color="blue.600" size="lg" mt={-3} mb={2} />
              </Stack>
              <Stat>
                <StatLabel>Auto Model Selection</StatLabel>
              </Stat>
            </CardBody>
          </Card>
          <Card>
            <CardHeader mb={0}>
              <Heading mb={0} size="md">
                Model settings
              </Heading>
            </CardHeader>
            <CardBody>
              <Select mt={-8} mb={2} value={model} onChange={handleModelChange}>
                <option value="Llama">Llama</option>
                <option value="Mistral-7B-Instruct-v0.2">Mistral</option>
              </Select>
              <Stat>
                <StatLabel>Temperature</StatLabel>
                <StatNumber>{temperature.toFixed(1)}</StatNumber>
                <Slider
                  aria-label="temperature-slider"
                  value={temperature}
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={(val) => setTemperature(val)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Stat>

              <Stat>
                <StatLabel>Max tokens</StatLabel>
                <StatNumber>{maxTokens}</StatNumber>
                <Slider
                  aria-label="max-tokens-slider"
                  value={maxTokens}
                  min={1}
                  max={4000}
                  step={1}
                  onChange={(val) => setMaxTokens(val)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Stat>
            </CardBody>
          </Card>
        </VStack>
      </Box>

      <Flex flex="1" flexDirection="column" p={4}>
        <Box flex="1">
          <VStack spacing={4} align="stretch">
            <Checkbox
              isChecked={showSystemPrompt}
              onChange={(e) => setShowSystemPrompt(e.target.checked)}
            >
              Provide System Prompt
            </Checkbox>

            {showSystemPrompt && (
              <Card>
                <CardHeader>
                  <Heading size="xs">System Prompt</Heading>
                </CardHeader>
                <CardBody>
                  <Textarea
                    placeholder="Enter System Prompt Instructions for your model (Optional)"
                    mb={0}
                    mt={-8}
                  />
                </CardBody>
              </Card>
            )}

            <Card>
              <CardHeader>
                <Heading size="xs">Your Prompt</Heading>
              </CardHeader>
              <CardBody>
                <Textarea
                  placeholder="Enter instructions or prompt"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  mb={-8}
                  mt={-8}
                />
              </CardBody>
              <CardFooter>
                <Button
                  onClick={handleSubmit}
                  bg="brand.primary"
                  color="brand.textPrimaryDark"
                  _hover={{
                    bg: "brand.secondary",
                  }}
                >
                  {loading ? <Spinner /> : "Run"}
                </Button>
              </CardFooter>
            </Card>

            <Card>
              <CardBody
                bg={useColorModeValue("gray.100", "gray.800")}
                p={4}
                borderRadius="md"
              >
                <Text>Response: {streamingResponse}</Text>
              </CardBody>
            </Card>
          </VStack>
        </Box>
      </Flex>
    </Flex>
  );
}

function Docs() {
  return (
    <Box p={0}>
      <GetStartedCode />
      <Divider mt={0} mb={5} />
      <Component />
    </Box>
  );
}

export default function App() {
  const selectedTabStyle = {
    _selected: {
      bg: "brand.primary",
      color: "white",
    },
  };
  return (
    <Tabs variant="soft-rounded">
      <TabList>
        <Tab sx={selectedTabStyle}>API</Tab>
        <Tab sx={selectedTabStyle}>Finetune Neptune</Tab>
        <Tab sx={selectedTabStyle}>Playground</Tab>
        <Tab sx={selectedTabStyle}>Cookbook</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Docs />
        </TabPanel>
        <TabPanel>
          <Neptune />
        </TabPanel>
        <TabPanel>
          <PlaygroundContainer />
        </TabPanel>
        <TabPanel>
          <Cookbook />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
