import React from 'react';
import { Box, IconButton, Flex, useClipboard } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';
import { CopyBlock, dracula } from 'react-code-blocks';

const CodeWithCopyButton = ({ code }) => {
  const { hasCopied, onCopy } = useClipboard(code);

  return (
    <Flex direction="column" position="relative" alignItems="left">
      <CopyBlock
        text={code}
        language="bash"
        showLineNumbers={false}
        theme={dracula}
        wrapLines
        codeBlock
      />
      
    </Flex>
  );
};

const PromptTemp = ({ data }) => {
  const promptTemplate = data?.model?.prompt_template;

  if (!promptTemplate) {
    return null;
  }

  return (
    <Box p={4}>
      <CodeWithCopyButton code={promptTemplate} data={data} />
    </Box>
  );
};

export default PromptTemp;
