import { Button, Flex, Text } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Loading from "../Loading/Loading";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";

export default function AccountVerification() {
  const location = useLocation();
  const tokenParam = new URLSearchParams(location.search).get("token");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    const verifyAccount = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/accounts/verification/validate`,
          { token: tokenParam }
        );
        setMessage(response.data.message);
        setSuccess(true);
      } catch (error) {
        setMessage(error.response.data.message);
        setError(true);
      }
      setLoading(false);
    };
    verifyAccount();
  }, [location]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          h="100vh"
        >
          {success && <Navigate to="/login" />}
          {error && (
            <Flex gap={2} alignItems={"center"} flexDirection="column">
              <Text>{message}</Text>
              <ChakraLink as={ReactRouterLink} to="/login">
                <Button>Login</Button>
              </ChakraLink>
            </Flex>
          )}
        </Flex>
      )}
    </div>
  );
}
