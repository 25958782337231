import axios from 'axios';

const url = process.env.REACT_APP_API_URL;

export const uploadDatasetApi = async (formData, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.post(`${url}/datasets/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const getAllDatasetsApi = async (thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(`${url}/datasets/`, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const getDatasetApi = async (datasetId, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(`${url}/datasets/${datasetId}`, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const deleteDatasetApi = async (datasetId, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.delete(`${url}/datasets/${datasetId}`, {
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};
