import axios from "axios";
const url = process.env.REACT_APP_API_URL;

export const getAllTiers = async (cloud, size, quantized, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.get(`${url}/tiers/`, {
      params: {
        cloud,
        size,
        quantized: quantized.toString(),
      },
      headers: {
        authorization: `Bearer ${state.auth.access_token}`,
      },
    });
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};
