import React, { useEffect, useState } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import {
  Box,
  VStack,
  Text,
  useColorMode,
  Badge,
  Spinner,
  Center,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
} from '@chakra-ui/react';

const urlz = process.env.REACT_APP_API_URL;

const RunPodLogs = ({ clusterId, onContainerSelect, status }) => {
  const [logMessages, setLogMessages] = useState(() => {
    const storedLogs = localStorage.getItem('logMessages');
    return storedLogs ? JSON.parse(storedLogs) : [];
  });
  const [isLoading, setIsLoading] = useState(true);
  const { colorMode } = useColorMode();

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const logsUrl = `${urlz}/clusters/v1/${clusterId}/logs/stream`;
  
    const logsEventSource = new EventSourcePolyfill(logsUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  
    logsEventSource.onmessage = (event) => {
      const logEntry = parseLogEntry(event.data);
      if (logEntry) {
        const newLogMessages = [logEntry, ...logMessages.slice(0, 9)]; 
        setLogMessages(newLogMessages); 
        localStorage.setItem('logMessages', JSON.stringify(newLogMessages));
      }
      setIsLoading(false);
    };
  
    logsEventSource.onerror = (err) => {
      console.error('Logs EventSource error:', err);
      setIsLoading(false);
    };
  
    return () => {
      logsEventSource.close();
    };
  }, [clusterId]);

  const parseLogEntry = (logString) => {
    const parts = logString.match(/(\w+) (\d\d-\d\d-\d\d\d\d \d\d:\d\d:\d\d) (.*?\.py:\d+)\] (.*)/);
    if (parts && parts.length >= 5) {
      return {
        level: parts[1],
        timestamp: parts[2],
        module: parts[3],
        message: parts[4],
      };
    } else {
      return null;
    }
  };

  const renderLogs = () => (
    <Container maxW="container.xl" overflowY="auto" maxHeight="400px">
      <Table variant="striped" colorScheme={colorMode === 'dark' ? 'teal' : 'gray'}>
        <Thead>
          <Tr>
            <Th>Timestamp</Th>
            <Th>Module</Th>
            <Th>Message</Th>
          </Tr>
        </Thead>
        <Tbody>
          {logMessages.map((log, index) => (
            <Tr key={index}>
              <Td>{log.timestamp}</Td>
              <Td>{log.module}</Td>
              <Td>{log.message}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Container>
  );

  const renderNoLogsMessage = () => (
    <Center height="100%" width="100%">
      <Box
        p={4}
        mt={20}
        minW="400px"
        borderWidth="1px"
        borderRadius="sm"
        bg={colorMode === 'dark' ? 'gray.700' : 'gray.100'}
        textAlign="center"
      >
        <Text fontSize="sm" color={colorMode === 'dark' ? 'green.300' : 'green.500'}>
          Model Status
        </Text>
        <Badge size={'xs'}>{status}</Badge>
        <Text fontSize="sm" color={colorMode === 'dark' ? 'red.300' : 'red.500'}>
          {status === 'SUCCESS' || status === 'RUNNING' ? 'Model - Health Status Error' : 'No Logs Found'}
        </Text>
      </Box>
    </Center>
  );

  return (
    <VStack spacing={4}>
      {isLoading ? (
        <Center w="100%" p={4}>
          <Spinner size="lg" />
        </Center>
      ) : logMessages.length > 0 ? renderLogs() : renderNoLogsMessage()}
    </VStack>
  );
};

export default RunPodLogs;
