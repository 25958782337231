import {
  Container,
  Heading,
  Flex,
  Box,
  Spacer,
  ButtonGroup,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import ServiceAccount from "../../components/services/services";
import { HamburgerIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { BookText } from "lucide-react";

function Services() {
  const openLinkGCP = () => {
    const url = "https://www.notion.so/nxtai/GPC-configuration-9b70d9b2f03c45f9a604e5e8f44387e4?pvs=4";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const openLinkAWS = () => {
    const url = "https://docs.nextai.co.in/NextAI%20Compute/AWS%20Configurations";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const openLinkQuota = () => {
    const url = "https://docs.nextai.co.in/NextAI%20Compute/Requesting%20Quota%20Increase";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Container maxW="7xl">
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <Box p="0">
          <Heading as="h1" size="xl" py={2}>
            Add & Manage your service accounts.
          </Heading>
        </Box>
        <Spacer />
        <Text fontSize={16}>Reference documentation</Text>
        <ButtonGroup gap="2">
          <Menu>
            <MenuButton
              color={'white'}
              bg={'brand.primary'}
              as={IconButton}
              _hover={{bg:'brand.secondary'}}
              aria-label="Options"
              icon={<BookText size={18}/>}
              variant="outline"
              size={'sm'}
            />
            <MenuList>
              <MenuItem onClick={openLinkGCP} icon={<ExternalLinkIcon/>} >
              GCP configuration
              </MenuItem>
              <MenuItem onClick={openLinkAWS} icon={<ExternalLinkIcon />} >
              AWS configuration
              </MenuItem>
              <MenuItem onClick={openLinkQuota} icon={<ExternalLinkIcon />}>
                Quota Increase 
              </MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>

      </Flex>
      <Heading fontWeight={500} as="h2" size="md" py={0}>
        View and manage your cloud service accounts here.
      </Heading>
      <Flex mt={8} direction="column" align="start">
        <ServiceAccount />
      </Flex>
    </Container>
  );
}

export default Services;
