import { React, useState } from "react";
import {
  Box,
  Code,
  IconButton,
  useClipboard,
  Flex,
  VStack,
  Slider,
  Card,
  CardHeader,
  Heading,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Stat,
  StatLabel,
  StatNumber,
  CardBody,
  Select,
  Text,
  Stack,
  Switch,
  Spacer,
  HStack,
} from "@chakra-ui/react";
import {
  CopyBlock,
  irBlack,
  a11yDark,
  github,
  dracula,
  googlecode,
  hybrid,
} from "react-code-blocks";
import { BookAIcon, ExternalLink, KeyRound } from "lucide-react";

const CodeWithCopyButton = ({ code, data }) => {
  const { hasCopied, onCopy } = useClipboard(code);

  return (
    <Flex direction="column" position="relative" alignItems="left" maxW="850px">
      <CopyBlock
        text={code}
        language="jsx"
        showLineNumbers
        wrapLines
        codeBlock
        theme={irBlack}
      />
    </Flex>
  );
};

const GetStartedCode = ({ data }) => {
  const [model, setModel] = useState("nextai-team/neptune-inst-v1");
  const [temperature, setTemperature] = useState(0);
  const [maxTokens, setMaxTokens] = useState(1000);
  const [topP, setTopP] = useState(0.2);
  const [codeLanguage, setCodeLanguage] = useState("curl");
  const [cost, setCost] = useState();

  const modelOptions = {
    CodeLlama: {
      modelName: "Llama-2-7b-chat-hf",
      cost: { input: 0.0002, output: 0.0005 },
      endpoint: "https://llama.nextai.co.in/v1",
      desc: "An open source model from Meta, fine-tuned for chat use cases.",
    },
    "nextai-team/neptune-inst-v1": {
      modelName: "nextai-team/neptune-inst-v1",
      cost: { input: 0.0003, output: 0.0005 },
      endpoint: "https://flash.nextai.co.in/v1",
      desc: "An nextai model developed and fine tuned for various natural language processing demonstrating unparalleled state-of-the-art performance",
    },
  };

  const handleModelChange = (e) => {
    setModel(e.target.value);
    setCost(modelOptions[e.target.value].cost);
  };

  const generateCodeSnippet = (language) => {
    const endpoint = modelOptions[model].endpoint;
    const modelName = modelOptions[model].modelName;
    switch (language) {
      case "node.js":
        return `import { NextAI } from "@nextai/client";

        const client = new Client(NextAI_TOKEN);
        const completion = await client.chat.completions.create( {
          "messages": [
            {
              "role": "system",
              "content": "You are a helpful assistant. Keep your responses limited to one short paragraph if possible."
            },
            {
              "role": "user",
              "content": "Hello world"
            }
          ],
          "model": "${modelName}", 
          "max_tokens": ${maxTokens}, 
          "temperature": ${temperature.toFixed(1)}, 
          "presence_penalty": 0,
          "top_p": ${topP} 
        });`;
      case "python":
        return `from openai import OpenAI

        api_key = os.getenv('NEXTAI_API_KEY')
        client = OpenAI(base_url="https://flash.nextai.co.in/v1", api_key=api_key)

        response = client.chat.completions.create(
          model="${modelName}",
          messages=[
            {"role": "system", "content": "You are a helpful assistant."},
            {"role": "user", "content": "Who won the world series in 2020?"},
            {"role": "assistant", "content": "The Los Angeles Dodgers won the World Series in 2020."},
            {"role": "user", "content": "Where was it played?"}
          ],
            max_tokens=${maxTokens}, 
            presence_penalty=0,
            temperature=${temperature.toFixed(1)}, 
            top_p=${topP} 
        )`;
      case "curl":
      default:
        return `curl --location '${endpoint}/chat/completions'\n \
        --header 'Content-Type: application/json'\n \
        --header 'Authorization: Bearer $NEXT_AI' \n \
        --data '{
              "messages": [
                  {
                      "role": "system",
                      "content": "You are a helpful assistant."
                  },
                  {
                      "role": "user",
                      "content": "Hello world"
                  }
              ],
   "model": "${modelName}", 
   "max_tokens": ${maxTokens}, 
   "temperature": ${temperature.toFixed(1)}, 
   "presence_penalty": 0,
   "top_p": ${topP} 
  }'`;
    }
  };

  const codeSnippet = generateCodeSnippet(codeLanguage);

  return (
    <Flex >
      <Box ml={-10} w="300px" borderRight="1px" borderColor="gray.200" p={4}>
        <VStack align="stretch" spacing={4}>
          <Card>
            <CardHeader mb={0}>
              <Heading mb={0} size="md">
                Model settings
              </Heading>
            </CardHeader>
            <CardBody>
              <Select
                variant="filled"
                mt={-8}
                mb={2}
                value={model}
                onChange={handleModelChange}
              >
                {/* <option value="CodeLlama">Llama</option> */}
                <option value="nextai-team/neptune-inst-v1">
                  NextAI Neptune-Inst
                </option>
              </Select>
              <Stat>
                <StatLabel>Temperature</StatLabel>
                <StatNumber>{temperature.toFixed(1)}</StatNumber>
                <Slider
                  aria-label="temperature-slider"
                  value={temperature}
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={(val) => setTemperature(val)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Stat>

              <Stat>
                <StatLabel>Max tokens</StatLabel>
                <StatNumber>{maxTokens}</StatNumber>
                <Slider
                  aria-label="max-tokens-slider"
                  value={maxTokens}
                  min={1}
                  max={4000}
                  step={1}
                  onChange={(val) => setMaxTokens(val)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Stat>

              <Stat>
                <StatLabel>Top P</StatLabel>
                <StatNumber>{topP}</StatNumber>
                <Slider
                  aria-label="max-tokens-slider"
                  value={topP}
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={(val) => setTopP(val)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Stat>
            </CardBody>
          </Card>
          <Card>
            <CardHeader mb={0}>
              <Heading mb={-20} size="md">
                Cost
              </Heading>
            </CardHeader>
            <CardBody>
              <Stat mb={2}>
                <StatLabel>Price per 1000 input tokens</StatLabel>
                <StatNumber>${modelOptions[model].cost.input}</StatNumber>
              </Stat>

              <Stat>
                <StatLabel>Price per 1000 output tokens</StatLabel>
                <StatNumber>${modelOptions[model].cost.output}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Max token length</StatLabel>
                <StatNumber>4,096</StatNumber>
              </Stat>
            </CardBody>
          </Card>
        </VStack>
      </Box>

      <Flex flex="1" flexDirection="column" p={4}>
        <Box flex="1">
          <VStack spacing={3} align="stretch">
            <Card variant="outline">
              <CardHeader>
                <HStack>
                  <VStack align={"flex-start"}>
                    <HStack>
                      <Heading size="md">{model}</Heading>
                      <IconButton
                        bg={"brand.primary"}
                        color={"white"}
                        aria-label="documentation"
                        icon={<ExternalLink size={14} />}
                        size={"xs"}
                        as="a"
                        href="https://nxtai.notion.site/NextAI-Model-Endpoint-34859ffaf16d47c4a17f7a8840f4721e"
                        target="_blank"
                        rel="noopener noreferrer"
                        _hover={{ bg: "brand.secondary" }}
                      />
                    </HStack>
                    <Text maxW={550} fontSize={12}>{modelOptions[model].desc}</Text>
                  </VStack>
                  <Spacer />
                  <VStack>
                    <Text mb={-1} fontSize={12}>
                      Generate API Key
                    </Text>
                    <IconButton
                      bg={"brand.primary"}
                      color={"white"}
                      aria-label="API Key"
                      icon={<KeyRound size={16} />}
                      size={"sm"}
                      as="a"
                      href="/api"
                      target="_blank"
                      rel="noopener noreferrer"
                      _hover={{ bg: "brand.secondary" }}
                    />
                  </VStack>
                </HStack>
              </CardHeader>
            </Card>
            <Box p={0}>
              <Select
                variant="filled"
                size="sm"
                value={codeLanguage}
                onChange={(e) => setCodeLanguage(e.target.value)}
              >
                <option value="curl">cURL</option>
                <option value="node.js">Node.js</option>
                <option value="python">Python</option>
              </Select>
              <CodeWithCopyButton code={codeSnippet} />
              <Text mt={1} mb={2} fontSize={12}>
                An API token will be required for authentication.
              </Text>
            </Box>
          </VStack>
        </Box>
      </Flex>
    </Flex>
  );
};

export default GetStartedCode;
