import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Progress,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

function LoadingModal({ data, isOpen, onClose }) {
  let progress = 0;
  let statusText = "";

  switch (data?.stage) {
    case 1:
      progress = 25;
      statusText = "Starting";
      break;
    case 2:
      progress = 50;
      statusText = "Initiated";
      break;
    case 3:
      progress = 75;
      statusText = "Deploying";
      break;
    case 4:
      progress = 100;
      statusText = "Success";
      break;
    default:
      progress = 100;
      statusText = "Failed";
      break;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent pb={4} >
        <ModalHeader>
            {data?.status}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>{statusText}</Text>
          <Progress value={progress} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default LoadingModal;
