import React from 'react';
import { Box, IconButton, Flex, useClipboard } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';
import { CopyBlock, dracula , irBlack } from 'react-code-blocks';

const CodeWithCopyButton = ({ code }) => {
  const { hasCopied, onCopy } = useClipboard(code);

  return (
    <Flex direction="column" position="relative" alignItems="center">
      <CopyBlock
        text={code}
        language="python"
        showLineNumbers
        theme={dracula}
        wrapLines
        codeBlock
      />
    </Flex>
  );
};

const App = ({ data }) => {
  const codeSnippet = `import openai

openai.api_key = "EMPTY"
openai.api_base = "http://${data?.endpoint}/v1"

model = "${data?.model?.name}"
prompt = "Once upon a time"

completion = openai.Completion.create(model=model, prompt=prompt, max_tokens=64)
print(prompt + completion.choices[0].text)

completion = openai.ChatCompletion.create(
  model=model,
  messages=[{"role": "user", "content": "Hello! What is your name?"}]
)

print(completion.choices[0].message.content)
`;

  return (
    <Box p={4}>
      <CodeWithCopyButton code={codeSnippet} />
    </Box>
  );
};

export default App;
