import { createSlice } from "@reduxjs/toolkit";
import {
  getAllPaymentMethodsAsync,
  getSpecificPaymentMethodAsync,
  createPaymentSetupTokenAsync,
  createPaymentMethodAsync,
  completePaymentSetupAsync,
} from "./paymentAction";

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    paymentMethods: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPaymentMethodsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(completePaymentSetupAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(completePaymentSetupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentMethods = [...state.paymentMethods, action.payload];
      })
      .addCase(completePaymentSetupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllPaymentMethodsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentMethods = action.payload.response;
      })      
      .addCase(getAllPaymentMethodsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSpecificPaymentMethodAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSpecificPaymentMethodAsync.fulfilled, (state, action) => {
        state.loading = false;
        // Handle specific payment method
      })
      .addCase(getSpecificPaymentMethodAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPaymentSetupTokenAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPaymentSetupTokenAsync.fulfilled, (state, action) => {
        state.loading = false;
        // Handle payment setup token
      })
      .addCase(createPaymentSetupTokenAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPaymentMethodAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPaymentMethodAsync.fulfilled, (state, action) => {
        state.loading = false;
        // Handle new payment method
      })
      .addCase(createPaymentMethodAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default paymentSlice.reducer;
