import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Spinner,
  IconButton,
  Tooltip,
  Card,
  useToast,
  AlertDialog,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  createApiKeyAsync,
  getApiKeysAsync,
  revokeApiKeyAsync,
} from "../../features/api/apiAction";
import { resetApi } from "../../features/api/apiSlice";
import { DeleteIcon, CopyIcon } from "@chakra-ui/icons";
// import GeneratedApiKeySection from "./generatedApi";

const ApiKeyPage = ({ selectedCluster }) => {
  const dispatch = useDispatch();
  const { apiKeys, loading, error } = useSelector((state) => state.api);
  const [apiKeyName, setApiKeyName] = useState("");
  const [selectedApiKey, setSelectedApiKey] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const [generatedKey, setGeneratedKey] = useState(null);
  const toast = useToast();

  useEffect(() => {
    if (selectedCluster) {
      dispatch(getApiKeysAsync(selectedCluster));
    }
    return () => {
      dispatch(resetApi());
    };
  }, [dispatch, selectedCluster]);

  const handleGenerateApiKey = async () => {
    const response = await dispatch(
      createApiKeyAsync({ clusterId: selectedCluster, apiKeyName })
    );

    const apiKey = response.response;

    setGeneratedKey(apiKey);

    toast({
      title: "API Key Generated",
    });

    setApiKeyName("");
  };

  const handleRevokeApiKey = async () => {
    if (selectedApiKey && selectedApiKey.uuid && selectedCluster) {
      try {
        await dispatch(
          revokeApiKeyAsync({
            clusterId: selectedCluster,
            apiKeyId: selectedApiKey.uuid,
          })
        );
        toast({
          title: "API Key Revoked",
          description: "API key has been successfully revoked.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        dispatch(getApiKeysAsync(selectedCluster));
        setSelectedApiKey(null);
        setIsOpen(false);
      } catch (error) {
        console.error("Error revoking API key:", error);
      }
    } else {
      console.error("Selected cluster or API key UUID is undefined");
    }
  };

  const handleOpenRevokeDialog = (apiKey) => {
    setSelectedApiKey(apiKey);
    setIsOpen(true);
  };

  const handleCloseRevokeDialog = () => {
    setSelectedApiKey(null);
    setIsOpen(false);
  };

  return (
    <Box p={0}>
      <Divider mb={4} mt={2} />

      {/* <FormControl mb={4}>
        <FormLabel>API Key Name</FormLabel>
        <Input
          type="text"
          placeholder="Enter API Key Name"
          value={apiKeyName}
          onChange={(e) => setApiKeyName(e.target.value)}
        />
      </FormControl>

      <Button
        onClick={handleGenerateApiKey}
        isLoading={loading}
        bg="brand.primary"
        color="white"
        mr={2}
        _hover={{ bg: "brand.secondary" }}
      >
        Generate API Key
      </Button> */}

      {error && <Box color="red">{error.message}</Box>}

      {loading && <Spinner size="lg" mt={4} />}

      <Divider mt={4} mb={2} />

      {/* <GeneratedApiKeySection apiKeys={apiKeys} /> */}

      {apiKeys && Array.isArray(apiKeys) && (
      <Card mt={4} borderRadius="md" overflow="hidden"
        direction={{ base: 'column', sm: 'row' }}
        variant='outline'
      >
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>API Key</Th>
              <Th>Status</Th>
              <Th>Created At</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {apiKeys.length > 0 ? (
              apiKeys.map((apiKey, index) => (
                <Tr key={apiKey?._id || index}>
                  <Td>{apiKey?.name || "N/A"}</Td>
                  <Td>{apiKey?.masked || "N/A"}</Td>
                  <Td>{apiKey?.status || "N/A"}</Td>
                  <Td>{new Date(apiKey.createdAt).toLocaleString() || 'N/A'}</Td>
                  <Td>
                    <Tooltip label="Revoke API Key">
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => handleOpenRevokeDialog(apiKey)}
                        size="sm"
                        colorScheme="red"
                        _hover={{ color: "red.600" }}
                      />
                    </Tooltip>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={5} textAlign="center">
                  No API Key Created
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={handleCloseRevokeDialog}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Revoke API Key
                </AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure you want to revoke this API key? This action
                  cannot be undone.
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={handleCloseRevokeDialog}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handleRevokeApiKey} ml={3}>
                    Revoke
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Card>
      )}
    </Box>
  );
};

export default ApiKeyPage;
