import React, { useEffect, useState } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import {
  Box,
  VStack,
  Text,
  useColorMode,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Center,
} from "@chakra-ui/react";

const urlz = process.env.REACT_APP_API_URL;

const ContainerStreamLogs = ({ clusterId, containerId, status }) => {
  const [streamLogs, setStreamLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { colorMode } = useColorMode();

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const url = `${urlz}/clusters/v1/${clusterId}/logs/${containerId}/stream`;
    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    eventSource.onmessage = (e) => {
      const logEntry = JSON.parse(e.data);
      setStreamLogs((currentLogs) => [...currentLogs, logEntry]);
      setIsLoading(false);
    };

    eventSource.onerror = (err) => {
      setIsLoading(false);
    };

    return () => {
      eventSource.close();
    };
  }, [clusterId, containerId]);

  const logLevelColor = {
    'info': 'green',
    'warning': 'orange'
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  const LoadingOrEmptyState = () => {
    if (isLoading) {
      return <Spinner size="xl" />;
    }
    if (!isLoading && streamLogs.length === 0) {
      return <Text>No Logs Found</Text>;
    }
    return null;
  };

  return (
    <VStack spacing={4}>
      <Box
        w="900px"
        maxH="300px"
        overflowY="auto"
        bg={colorMode === "dark" ? "gray.700" : "gray.50"}
        p={4}
        borderRadius="md"
      >
        <Text
          fontSize="xl"
          fontWeight="bold"
          mb={4}
          color={colorMode === "dark" ? "green.300" : "black"}
        >
          Container Stream Logs:
        </Text>
        {isLoading || streamLogs.length === 0 ? (
          <Center mt="20">
            <LoadingOrEmptyState />
          </Center>
        ) : (
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th>Timestamp</Th>
                <Th>Message</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {streamLogs
                .slice()
                .reverse()
                .map((log, index) => (
                  <Tr key={index}>
                    <Td>{formatTimestamp(log.ts)}</Td>
                    <Td>{log.m}</Td>
                    <Td>
                      <Badge
                        colorScheme={
                          logLevelColor[log.l?.toLowerCase() || "default"] ||
                          "gray"
                        }
                      >
                        {log.l?.toUpperCase() || "UNKNOWN"}
                      </Badge>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </VStack>
  );
};

export default ContainerStreamLogs;
