import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllServiceAccountsAsync,
  fetchServiceAccountAsync,
  createServiceAccountClusterAsync,
  validateServiceAccountAsync,
  deleteServiceAccountAsync,
} from "./serviceAccountAction";

const serviceAccountSlice = createSlice({
  name: "serviceAccount",
  initialState: {
    serviceAccounts: [], 
    currentAccount: null,
    loading: false,
    error: null,
    validationStatus: null,
  },
  reducers: {
    resetServiceAccount: (state) => {
      state.serviceAccounts = [];
      state.currentAccount = null;
      state.loading = false;
      state.error = null;
      state.validationStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllServiceAccountsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllServiceAccountsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceAccounts = action.payload.response;
      })
      .addCase(fetchAllServiceAccountsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchServiceAccountAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchServiceAccountAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.currentAccount = action.payload;
      })
      .addCase(fetchServiceAccountAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createServiceAccountClusterAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createServiceAccountClusterAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceAccounts = [...state.serviceAccounts, action.payload];
      })
      .addCase(createServiceAccountClusterAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(validateServiceAccountAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(validateServiceAccountAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.validationStatus = action.payload;
      })
      .addCase(validateServiceAccountAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteServiceAccountAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteServiceAccountAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceAccounts = state.serviceAccounts.filter(
          account => account.uuid !== action.meta.arg // assuming 'uuid' is the identifier
        );
      })
      .addCase(deleteServiceAccountAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetServiceAccount } = serviceAccountSlice.actions;
export default serviceAccountSlice.reducer;