import { Container, Heading, Flex } from "@chakra-ui/react";
import React from "react";
import Cookboo from "../../components/playground/cookbook";

function Cookbook() {
  return (
    <Container maxW="7xl">
      <Heading as="h1" size="xl" py={0}>
       Cookbook
      </Heading>
      <Heading fontWeight={500} as="h2" size="md" py={0}>
       Browse a collection of snippets, advanced techniques and walkthroughs.
      </Heading>
      <Cookboo/>
    </Container>
  );
}

export default Cookbook;