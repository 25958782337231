import axios from "axios";

export const deployApi = async (cluster, token, data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/clusters/v1/${cluster}/deployments/create/`,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};
