import React, { useEffect } from "react";
import { Box, Flex, Grid, GridItem, Spinner, Text } from "@chakra-ui/react";
import ModelsCard from "../ModelsCard/ModelsCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllClustersAsync } from "../../features/clusters/clusterAction";
import DeplyoModel from "../model/DeplyoModel";
import { setIn } from "formik";

function Clusters() {
  const { clusters, loading, error } = useSelector((state) => state.cluster);
  const [showSpinner, setShowSpinner] = React.useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getAllClustersAsync());
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      if (!loading) {
        fetchData();
      }
    }, 20000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      setShowSpinner(false);
    }
  }, [loading]);

  if (clusters?.length === 0) {
    return (
      <Box>
        <Text>No Models Deployed yet.</Text>
        <DeplyoModel />
      </Box>
    );
  }

  return (
    <Box>
      <Flex
        justifyContent={"start"}
        alignItems={"center"}
        direction={"row"}
        wrap={"wrap"}
        gap={4}
      >
        {showSpinner ? (
          <Spinner />
        ) : (
          clusters?.map((cluster, index) => (
            <ModelsCard key={index} data={cluster} />
          ))
        )}
      </Flex>
    </Box>
  );
}

export default Clusters;
