import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Heading,
  Divider,
  Text, // Import the Text component from Chakra UI
} from "@chakra-ui/react";
import { ChevronDownIcon } from "lucide-react";

function ClustersMenu({ clusters, handleClusterSelect, selectedCluster }) {
  return (
    <div>
      <Divider mb={3}/>
      <Heading size="lg" mb={5}>
       Deployed Models Metrics
      </Heading>
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          {selectedCluster ? selectedCluster.name : "Select Cluster"}
        </MenuButton>
        <MenuList>
          {clusters?.map((cluster) => (
            <MenuItem
              key={cluster.uuid}
              onClick={() => handleClusterSelect(cluster)}
            >
              {cluster.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      {!selectedCluster && (
        <Text mt={4}>Please select the cluster to see the usage</Text>
      )}
    </div>
  );
}

export default ClustersMenu;
