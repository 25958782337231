import React from 'react';
import { HStack, Image, Td, Text } from '@chakra-ui/react';
import awsLogo from '../../assets/images/AWS.png'; 
import gcpLogo from '../../assets/images/gcp.png';

const CloudProviderCell = ({ cloudProvider }) => {
  let content;

  switch (cloudProvider) {
    case 'google':
      content = (
        <React.Fragment>
        <HStack>
          <Image src={gcpLogo} alt="GCP Logo" boxSize="20px" mr={0} />
          <Text fontWeight={500} size={14} as="span">GCP</Text>
          </HStack>
        </React.Fragment>
      );
      break;
    case 'amazon':
      content = (
        <React.Fragment>
            <HStack>
          <Image src={awsLogo} alt="AWS Logo" boxSize="20px" mr={0} />
          <Text size={14} fontWeight={500} as="span">AWS</Text>
          </HStack>
        </React.Fragment>
      );
      break;
    default:
      content = <Text as="span">{cloudProvider}</Text>;
  }

  return <Td>{content}</Td>;
};

export default CloudProviderCell;