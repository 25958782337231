import { createSlice } from "@reduxjs/toolkit";
import { getModelsAsync, getSearchAsync } from "./modelActions";

const modelSlice = createSlice({
  name: "model",
  initialState: {
    models: [],
    loading: false,
    error: null,
    success: null,
    totalResults: null,
  },
  reducers: {
    resetModel: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
      state.models = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getModelsAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getModelsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.totalResults = action.payload.metadata.total;
      state.models = action.payload.response
    });
    builder.addCase(getModelsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSearchAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSearchAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.models = action.payload;
    });
    builder.addCase(getSearchAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetModel } = modelSlice.actions;
export default modelSlice.reducer;
