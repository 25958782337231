import axios from "axios";
const url = process.env.REACT_APP_API_URL;

// Initiating payment request
export const initiatePaymentRequestApi = async (paymentData, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/v2/payments/intents/initiate`, paymentData, {
      headers: {
        authorization: `Bearer ${thunkAPI.getState().auth.access_token}`,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
};

// Getting payment status
export const getPaymentStatusApi = async (transactionId, thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v2/payments/intents/${transactionId}/status`, {
      headers: {
        authorization: `Bearer ${thunkAPI.getState().auth.access_token}`,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
};

// Validating payment request
export const validatePaymentRequestApi = async (verificationData, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/public/intents/verify`, verificationData, {
      headers: {
        authorization: `Bearer ${thunkAPI.getState().auth.access_token}`,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
};
