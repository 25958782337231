import React from "react";
import { Text } from "@chakra-ui/react";
import Play from "../../components/playground/play";

function Dashboard() {
  return <Play/> ;
}

export default Dashboard;

