import { createAsyncThunk } from "@reduxjs/toolkit";
import { createApiKeyApi, getApiKeysApi, revokeApiKeyApi } from "../../api/apiKeyApi";

export const createApiKeyAsync = createAsyncThunk(
  "api/createApiKey",
  async ({ clusterId, apiKeyName }, thunkAPI) => {
    const response = await createApiKeyApi(clusterId, apiKeyName, thunkAPI);
    return response;
  }
);

export const getApiKeysAsync = createAsyncThunk(
  "api/getApiKeys",
  async (clusterId, thunkAPI) => {
    const response = await getApiKeysApi(clusterId, thunkAPI);
    return response;
  }
);

export const revokeApiKeyAsync = createAsyncThunk(
  "api/revokeApiKey",
  async ({ clusterId, apiKeyId }, thunkAPI) => {
    const response = await revokeApiKeyApi(clusterId, apiKeyId, thunkAPI);
    return response;
  }
);