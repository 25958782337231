import React from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  GridItem,
  Heading,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa"; // Importing the plus icon

function DeployModel() {
  return (
    <Box mt={8}>
      <Grid
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(4, 1fr)",
          "repeat(5, 1fr)",
        ]}
        gap={6}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <GridItem
          shadow={"md"}
          rounded={"md"}
          bg={useColorModeValue("brand.textPrimaryDark", "gray.800")}
        >
          <Card>
            <CardHeader>
              <Heading
                size="md"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={4}
              >
                <Icon as={FaPlus} fontSize={20} /> 
                <Text>Deploy New Model</Text>
              </Heading>
            </CardHeader>
            <CardFooter>
              <ChakraLink
                as={ReactRouterLink}
                to={"/endpoints"}
                textDecoration={"none"}
                _hover={{ textDecoration: "none" }}
                w={"full"}
              >
                <Button
                  w={"full"}
                  mt={0}
                  bg={useColorModeValue("#4E4FEB", "#4E4FEB")}
                  color={"white"}
                  rounded={"md"}
                  href={"/endpoints"}
                  _hover={{
                    boxShadow: "lg",
                    bg: "brand.secondary",
                  }}
                >
                  Start
                </Button>
              </ChakraLink>
            </CardFooter>
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default DeployModel;
