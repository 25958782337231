import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadDatasetApi, getAllDatasetsApi, getDatasetApi, deleteDatasetApi } from "../../api/datasetApi";

export const uploadDatasetAsync = createAsyncThunk(
  "datasets/uploadDataset",
  async (formData, thunkAPI) => {
    const response = await uploadDatasetApi(formData, thunkAPI);
    return response;
  }
);

export const getAllDatasetsAsync = createAsyncThunk(
  "datasets/getAllDatasets",
  async (_, thunkAPI) => {
    const response = await getAllDatasetsApi(thunkAPI);
    return response;
  }
);

export const getDatasetAsync = createAsyncThunk(
  "datasets/getDataset",
  async (datasetId, thunkAPI) => {
    const response = await getDatasetApi(datasetId, thunkAPI);
    return response;
  }
);

export const deleteDatasetAsync = createAsyncThunk(
  "datasets/deleteDataset",
  async (datasetId, thunkAPI) => {
    const response = await deleteDatasetApi(datasetId, thunkAPI);
    return response;
  }
);
