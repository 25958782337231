import { createSlice } from "@reduxjs/toolkit";
import { 
  getAllClustersAsync, 
  terminateClusterAsync, 
  pauseClusterAsync, 
  resumeClusterAsync,
  fetchClusterLogsAsync,
  archiveClusterAsync,
  restartServicesAsync,
} from "./clusterAction";

const clusterSlice = createSlice({
  name: "cluster",
  initialState: {
    clusters: null,
    loading: false,
    error: null,
    success: null,
    currentCluster: null,
    logs: null,
  },
  reducers: {
    setCurrentCluster: (state, action) => {
      state.currentCluster = action.payload;
    },
    resetCurrentCluster: (state) => {
      state.currentCluster = null;
    },
    resetCluster: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
      state.clusters = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllClustersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllClustersAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.clusters = action.payload;
      })
      .addCase(getAllClustersAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(terminateClusterAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.clusters = state.clusters.filter(cluster => cluster.uuid !== action.meta.arg);
      })
      .addCase(pauseClusterAsync.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.clusters.findIndex(cluster => cluster.uuid === action.meta.arg);
        if (index !== -1) {
          state.clusters[index].status = 'PAUSED';
        }
      })
      .addCase(resumeClusterAsync.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.clusters.findIndex(cluster => cluster.uuid === action.meta.arg);
        if (index !== -1) {
          state.clusters[index].status = 'STARTING';
        }
      })
      .addCase(restartServicesAsync.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.clusters.findIndex(cluster => cluster.uuid === action.meta.arg);
        if (index !== -1) {
          state.clusters[index].status = 'RESTARTING';
        }
      })
      .addCase(fetchClusterLogsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchClusterLogsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.logs = action.payload;
      })
      .addCase(fetchClusterLogsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(archiveClusterAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(archiveClusterAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.clusters = state.clusters.filter(cluster => cluster.uuid !== action.meta.arg);
      })
      .addCase(archiveClusterAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {setCurrentCluster, resetCluster } = clusterSlice.actions;
export default clusterSlice.reducer;