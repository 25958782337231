import React, { useState, useEffect } from "react";
import {
  Box,
  Progress,
  Text,
  Flex,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { FaWallet } from "react-icons/fa6";

function WalletUsageBar() {
  const [balance, setBalance] = useState(0);
  const [threshold, setThreshold] = useState(1); 
  useEffect(() => {
    const fetchWalletInfo = async () => {
      try {
        const authToken = localStorage.getItem("access_token");
        await getCustomer(authToken);
        await getWallet(authToken);
      } catch (error) {
        console.error("Error fetching wallet info:", error);
      }
    };

    fetchWalletInfo();
  }, []);

  const getWallet = async (authToken) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/payments/wallets`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const walletData = response.data.response;
      setBalance(walletData.balance);
      setThreshold(walletData.threshold);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        await createWallet(authToken);
      } else {
        throw error;
      }
    }
  };

  const getCustomer = async (authToken) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/payments/customers`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

    } catch (error) {
      if (error.response && error.response.status === 404) {
        await createCustomer(authToken);
      } else {
        throw error;
      }
    }
  };

  const createCustomer = async (authToken) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/payments/customers`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
    } catch (error) {
      console.error("Error creating wallet:", error);
      throw error;
    }
  };

  const createWallet = async (authToken) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/payments/wallets`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      await getWallet(authToken);
    } catch (error) {
      console.error("Error creating wallet:", error);
      throw error;
    }
  };

  const walletUsagePercentage = (balance / threshold) * 100;

  return (
    <Flex align="center" p={0} borderRadius="lg">
      <Box width="120px" mr={0}>
        <Tooltip
          label={`Wallet balance $${balance.toFixed(2)} `}
          aria-label="Atooltip"
          fontSize={"xs"}
        >
          <Flex alignItems="center">
            <FaWallet size={14} />
            <Text
              ml={1}
              fontSize={12}
              fontWeight="medium"
              color={useColorModeValue("gray.700", "gray.200")}
            >
              {/* ${balance.toFixed(2)} / ${threshold.toFixed(2)} */}
              ${balance.toFixed(2)}
            </Text>
          </Flex>
        </Tooltip>
        <Progress
          width={"100%"}
          size="sm"
          value={walletUsagePercentage}
          borderRadius="md"
          sx={{
            "& > div": {
              backgroundColor:
                walletUsagePercentage > 100 ? "red.600" : "blue.600",
            },
          }}
          bg="gray.300"
        />
      </Box>
    </Flex>
  );
}

export default WalletUsageBar;
